import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetDialogPayload } from "./types";

const generalSlice = createSlice({
    name: "general",
    initialState: {
        dialog: {
            open: false as boolean,
            options: null,
        } as { open: boolean; options: any },
        loading_text: "" as string,
        loading_status: false as boolean,
    },
    reducers: {
        setDialogTo(state, action: PayloadAction<SetDialogPayload>) {
            if (action.payload === false) {
                state.dialog.open = false;
            } else {
                state.dialog.open = true;
                state.dialog.options = {
                    title: action.payload.title,
                    description: action.payload.description,
                    content: action.payload.content,
                    buttons: action.payload.buttons,
                    width: action.payload.width,
                    height: action.payload.height,
                };
            }
        },

        setPageLoading(state, action: PayloadAction<boolean | string>) {

            if(typeof action.payload === "string") {
                state.loading_text = action.payload
                state.loading_status = true;
            } else {
                state.loading_status = action.payload;
            }
        },
    },
});

export const { setDialogTo, setPageLoading } = generalSlice.actions;

export default generalSlice;
