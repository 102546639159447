import { Box, Grid, Typography } from '@mui/material'
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid'
import PanelDataGrid, { ChangeDataGrid, DataGridParam } from 'components/PanelDataGrid'
import { BlackSection } from 'containers/dashboard'
import { numberFormat, toJalaliDate } from 'helpers/general'
import withRouter, { Router } from 'helpers/withRouter'
import { CardTick1, MenuBoard } from 'iconsax-react'
import moment from 'jalali-moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AxiosError, AxiosResponse } from 'utils/axios'
import { getSettlementsAsync } from './slice'
import { GetSettlementsData, Settlement, SettlementsResponseData } from './types'
import SettlementsWorkSpace from './workspace'
moment.locale('fa');

interface IState {
    page: number,
    limit: number,
    selectedRow: Settlement | null,
    createMode: boolean
}

class Settlements extends Component<SettlementsInterface, IState> {

    state: IState = {
        page: 1,
        limit: 10,
        selectedRow: null,
        createMode: false
    }

    private columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'شناسه',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 80,
            minWidth: 80
        },
        {
            field: 'seller.full_name',
            headerName: 'فروشنده',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 140,
            renderCell: (event: GridRenderCellParams<any, Settlement>) => {
                return <Typography>{event.row.seller.full_name}</Typography>
            }
        },
        {
            field: 'amount',
            headerName: 'مبلغ',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 140,
            flex: 1,
            renderCell: (event: GridRenderCellParams<any, Settlement>) => {
                return <Typography>{numberFormat(event.row.amount)} ریالءء</Typography>
            }
        },
        // {
        //     field: 'type',
        //     headerName: 'وضعیت',
        //     sortable: false,
        //     disableColumnMenu: true,
        //     maxWidth: 80,
        //     minWidth: 80,
        //     renderCell: (event: GridRenderCellParams<any, Settlement>) => {
        //         return <Typography>{event.row.type}</Typography>
        //     }
        // },
        {
            field: 'orders_count',
            headerName: 'تعداد',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 80,
            minWidth: 80,
            valueGetter: event => event.row.orders.length
        },
        {
            field: 'created_at',
            headerName: 'تاریخ تسویه',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 160,
            minWidth: 160,
            renderCell: (event: GridRenderCellParams<any, Settlement>) => {
                return <Typography>{toJalaliDate(event.row.created_at, 'HH:mm YYYY-MM-DD')}</Typography>
            }
        },
    ];

    async componentDidMount() {
        this.onChangeDataGrip()
    }

    UNSAFE_componentWillMount(): void {

        if (this.props.seller.type === "seller") {
            this.columns = this.columns.filter((column: GridColDef) => {
                return column.field !== "seller.full_name"
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<SettlementsInterface>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.router.params.settlement_id !== this.props.router.params.settlement_id) {
            this.onChangeDataGrip()
        }
    }
    selectRow = (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        const id = selectionModel[0];
        const item = this.props.settlements?.data.find(i => i.id === id)
        if (!item) return null;

        if (this.state.selectedRow?.id !== item?.id) {
            this.setState({ ...this.state, selectedRow: null, createMode: false }, () => {
                this.setState({ ...this.state, selectedRow: item, createMode: false })
            })
        }
    }

    unselectRow = () => {
        this.setState({ ...this.state, selectedRow: null, createMode: false })
    }

    onChangeDataGrip = (data: ChangeDataGrid = { page: 1, limit: 10, query: null }) => {
        this.setState({ ...this.state, selectedRow: null, createMode: false })

        return new Promise((resolve, reject) => {
            this.props.getSettlements({
                page: data.page,
                limit: data.limit,
                query: data.query,
                id: this.props.router.params.settlement_id || null
            }).then((response: AxiosResponse<SettlementsResponseData>) => {
                if (this.props.router.params.settlement_id) {
                    // this.setState({ ...this.state, selectedRow: response.data.results.data[0] })
                } else {
                    // this.setState({ ...this.state, selectedRow: null })
                }
                resolve(response)
            }).catch((error: AxiosError) => {
                reject(error)
            })
        })
    }

    getParams = () => {
        let params: DataGridParam[] = [];
        if (this.props.router.params.settlement_id) {
            params.push({
                label: `سفارش انتخاب شده: #${this.props.router.params.settlement_id}`,
                onDelete: async () => {
                    await this.props.router.navigate('/settlements')

                    this.onChangeDataGrip()
                }
            })
        }
        return params;
    }

    render() {

        return (
            <Grid container sx={{ height: { lg: '100%', xs: 'calc(100% - 48px)' } }}>
                <Grid item lg={6} xs={12}>
                    <BlackSection sx={{
                        display: { xs: this.state.createMode || this.state.selectedRow ? 'block' : 'none', lg: 'block' },
                        position: { xs: 'absolute', lg: 'relative' },
                        right: 0, left: 0, bottom: 0, top: 0, zIndex: 100
                    }}>
                        {this.state.selectedRow ? (
                            <SettlementsWorkSpace refresh={this.onChangeDataGrip} data={this.state.selectedRow} unselectRow={this.unselectRow} />
                        ) : (
                            <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <MenuBoard size={140} color="#fff" fontWeight={200} />
                                <Typography dir="rtl" mt={3}>هیچ رکوردی انتخاب نشده !</Typography>
                            </Box>
                        )}
                    </BlackSection>
                </Grid>
                <Grid item lg={6} xs={12} sx={{ backgroundColor: '#f7f7f8', height: '100%' }}>
                    <PanelDataGrid
                        sx={{ padding: { xs: '4px', lg: '16px' }, paddingTop: '16px !important' }}
                        label="تسویه ها"
                        subtitle=''
                        icon={CardTick1}
                        columns={this.columns}
                        rows={this.props.settlements?.data}
                        loading={this.props.get_process}
                        last_page={this.props.settlements?.last_page}
                        total_rows={this.props.settlements?.total}
                        onChange={this.onChangeDataGrip}
                        onSelectRow={this.selectRow}
                        params={this.getParams()}
                        searchable
                        dateGridProps={{
                            // checkboxSelection: true,
                            disableSelectionOnClick: true
                        }}
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    />
                </Grid>
            </Grid>
        )
    }
}

interface SettlementsInterface extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
    router: Router<{ settlement_id?: number }>
}

const mapDispatchToProps = (dispatch: any) => ({
    getSettlements: (data?: GetSettlementsData) => dispatch(getSettlementsAsync(data))
})

const mapStateToProps = (state: AppState) => ({
    settlements: state.settlements.results,
    get_process: state.settlements.get_process,
    seller: state.signin.seller
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Settlements))