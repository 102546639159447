import { Box1, CardTick1, Category2, Cloud, Icon, MoneyRecive, Profile2User, Shop, TransmitSquare } from "iconsax-react";

export type Menu = {
    route: string;
    label: string;
    icon: Icon;
};

export default {
    super: [
        {
            route: "/dashboard",
            label: "داشبورد",
            icon: Category2,
        },
        {
            route: "/servers",
            label: "سرور ها",
            icon: Cloud,
        },
        {
            route: "/sellers",
            label: "فروشندگان",
            icon: Shop,
        },
        {
            route: "/plans",
            label: "پلن ها",
            icon: Box1,
        },
        {
            route: "/packages",
            label: "بسته های افزایشی",
            icon: TransmitSquare,
        },

        {
            route: "/users",
            label: "کاربران",
            icon: Profile2User,
        },
        {
            route: "/bill",
            label: "صورتحساب",
            icon: MoneyRecive,
        },
        {
            route: "/settlements",
            label: "تسویه ها",
            icon: CardTick1,
        },
    ],
    manager: [
        {
            route: "/dashboard",
            label: "داشبورد",
            icon: Category2,
        },
        // {
        //     route: "/servers",
        //     label: "سرور ها",
        //     icon: Cloud,
        // },
        {
            route: "/sellers",
            label: "فروشندگان",
            icon: Shop,
        },
        {
            route: "/plans",
            label: "پلن ها",
            icon: Box1,
        },
        {
            route: "/packages",
            label: "بسته های افزایشی",
            icon: TransmitSquare,
        },

        {
            route: "/users",
            label: "کاربران",
            icon: Profile2User,
        },
        {
            route: "/bill",
            label: "صورتحساب",
            icon: MoneyRecive,
        },
        {
            route: "/settlements",
            label: "تسویه ها",
            icon: CardTick1,
        },
        // {
        //     route: "/ticketing",
        //     label: "پشتیبانی",
        //     icon: Headphone,
        // },
        // {
        //     route: "/settings",
        //     label: "تنظیمات",
        //     icon: Setting3,
        // },
    ],
    seller: [
        {
            route: "/dashboard",
            label: "داشبورد",
            icon: Category2,
        },
        {
            route: "/plans",
            label: "پلن ها",
            icon: Box1,
        },
        {
            route: "/packages",
            label: "بسته های افزایشی",
            icon: TransmitSquare,
        },
        {
            route: "/users",
            label: "کاربران",
            icon: Profile2User,
        },
        {
            route: "/bill",
            label: "صورتحساب",
            icon: MoneyRecive,
        },
        {
            route: "/settlements",
            label: "تسویه ها",
            icon: CardTick1,
        },
    ],
} as { [key in string]: Menu[] };
