import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import api from "api";
import { ChartData } from "chart.js";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";

const defaultChartData: ChartData<"bar", number[], string> = {
    labels: [],
    datasets: [],
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        data: {
            daily_amount_chart: defaultChartData,
            monthly_amount_chart: defaultChartData,
            daily_count_chart: defaultChartData,
            monthly_count_chart: defaultChartData,
        },
        v2Data: {
            daily: [] as any[],
            monthly: [] as any[],
            by_plan: [] as any[]
        },
    },
    reducers: {
        getDashboardDataSuccess(state, action: PayloadAction<AxiosResponse>) {
            state.data = action.payload.data.results;
        },
        getDashboardDataV2Success(state, action: PayloadAction<AxiosResponse>) {
            state.v2Data = action.payload.data.results;
        },
    },
});

// export const { } = dashboardSlice.actions;

export const getDashboardDataAsync = () => (dispatch: AppDispatch) => {
    let url = api.dashboard;
    return new Promise((resolve, reject) => {
        axios
            .get(url)
            .then((response: AxiosResponse) => {
                dispatch(dashboardSlice.actions.getDashboardDataSuccess(response));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export const getDashboardDataV2Async = () => (dispatch: AppDispatch) => {
    let url = api.dashboardV2;
    return new Promise((resolve, reject) => {
        axios
            .get(url)
            .then((response: AxiosResponse) => {
                dispatch(dashboardSlice.actions.getDashboardDataV2Success(response));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export default dashboardSlice;
