import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";
import { CreatePackageData, GetPackagesData, Package, PackagesResponseData, UpdatePackageData } from "./types";

const packageSlice = createSlice({
    name: "package",
    initialState: {
        get_process: false,
        results: null as PackagesResponseData | null,
        create_process: false,
        update_process: false,
        delete_process: false,
    },
    reducers: {
        getPackagesStarted(state) {
            state.get_process = true;
            if (state.results) state.results.data = [];
        },
        getPackagesSuccess(state, action: PayloadAction<PackagesResponseData>) {
            state.results = action.payload;
            state.get_process = false;
        },
        getPackagesFailed(state, action: PayloadAction<any>) {
            state.get_process = false;
            state.results = null;
        },

        createPackagesStarted(state) {
            state.create_process = true;
        },
        createPackagesSuccess(state, action: PayloadAction<PackagesResponseData>) {
            state.create_process = false;
        },
        createPackagesFailed(state, action: PayloadAction<any>) {
            state.create_process = false;
        },

        updatePackagesStarted(state) {
            state.update_process = true;
        },
        updatePackagesSuccess(state, action: PayloadAction<Package>) {
            state.update_process = false;
            if (state.results) {
                state.results.data = state.results.data.map((item) => {
                    if (item.id === action.payload.id) {
                        item = action.payload;
                    }
                    return item;
                });
            }
        },
        updatePackagesFailed(state, action: PayloadAction<any>) {
            state.update_process = false;
        },

        deletePackagesStarted(state) {
            state.delete_process = true;
        },
        deletePackagesSuccess(state, action: PayloadAction<PackagesResponseData>) {
            state.delete_process = false;
        },
        deletePackagesFailed(state, action: PayloadAction<any>) {
            state.delete_process = false;
        },
    },
});

// export const {} = packageSlice.actions;

export const getPackagesAsync =
    (data?: GetPackagesData, redux: boolean = true) =>
    (dispatch: AppDispatch) => {
        if (redux) dispatch(packageSlice.actions.getPackagesStarted());
        return new Promise((resolve, reject) => {
            let search = `?type=extra&limit=${data?.limit || 10}&page=${data?.page || 1}`;
            if (data?.query) search += `&query=${data?.query}`;
            if (data?.id) search += `&id=${data?.id}`;
            if (data?.sort) search += `&sort=${data?.sort}`;
            if (data?.is_seller) search += `&is_seller=${data?.is_seller}`;
            axios
                .get(api.package + search)
                .then((response: AxiosResponse<PackagesResponseData>) => {
                    if (redux) dispatch(packageSlice.actions.getPackagesSuccess(response.data.results));
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    if (redux) dispatch(packageSlice.actions.getPackagesFailed(error.response?.data));
                    reject(error);
                });
        });
    };

export const createPackagesAsync = (data?: CreatePackageData) => (dispatch: AppDispatch) => {
    dispatch(packageSlice.actions.createPackagesStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.package, data)
            .then((response: AxiosResponse) => {
                dispatch(packageSlice.actions.createPackagesSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(packageSlice.actions.createPackagesFailed(error.response?.data));
                reject(error);
            });
    });
};

export const updatePackagesAsync = (id: number, data?: UpdatePackageData) => (dispatch: AppDispatch) => {
    dispatch(packageSlice.actions.updatePackagesStarted());
    return new Promise((resolve, reject) => {
        axios
            .put(api.package + "/" + id, data)
            .then((response: AxiosResponse<Package>) => {
                dispatch(packageSlice.actions.updatePackagesSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(packageSlice.actions.updatePackagesFailed(error.response?.data));
                reject(error);
            });
    });
};

export const deletePackagesAsync = (package_id: number) => (dispatch: AppDispatch) => {
    dispatch(packageSlice.actions.deletePackagesStarted());
    return new Promise((resolve, reject) => {
        axios
            .delete(api.package + "/" + package_id)
            .then((response: AxiosResponse) => {
                dispatch(packageSlice.actions.deletePackagesSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(packageSlice.actions.deletePackagesFailed(error.response?.data));
                reject(error);
            });
    });
};

export default packageSlice;
