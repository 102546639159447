import React, { Component } from "react";
import { RouterProvider } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { AppState } from "redux/store";
import Router from "constants/router";


interface IProps extends ReturnType<typeof mapStateToProps> { }

class AppBaseRouter extends Component<IProps> {

    render() {
        return <RouterProvider fallbackElement={<CircularProgress />} router={Router} />;
    }
}

const mapStateToProps = (state: AppState) => ({
    seller: state.signin.seller
})

export default connect(mapStateToProps)(AppBaseRouter);
