import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import React from 'react';


function DarkMode(props: any) {
    const parentTheme = useTheme()
    const theme = createTheme({ ...parentTheme, palette: { mode: 'dark', primary: { main: '#fff', }, } });

    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
}

export default DarkMode