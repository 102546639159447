import { Box, Button, CircularProgress, DialogContent, DialogTitle, IconButton, Dialog as MuiDialog, Typography } from '@mui/material';
import CenterBox from 'components/CenterBox';
import { e2p, mask, p2e, pHumanFileSize, toJalaliDate } from 'helpers/general';
import { ArrowRight, Information } from 'iconsax-react';
import moment from 'jalali-moment';
import { FormBuilder } from 'material-form-builder';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setDialogTo } from 'redux/slice';
import { AppState } from 'redux/store';
import { SetDialogPayload } from 'redux/types';
import { ObjectLiteral, Ref } from 'types/general';
import { AxiosError, AxiosResponse } from 'utils/axios';
import { getTimelineByMobileAsync } from './slice';


interface TimelineProps extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
    mobile?: string
}

interface IState {
    data: any,
    showDetails: boolean,
    details: ObjectLiteral | null,
    searching: boolean
}
class Timeline extends Component<TimelineProps> {
    timelineBuilderRef: Ref<FormBuilder>

    state: IState = {
        data: null,
        showDetails: false,
        details: null,
        searching: false
    }

    onSearch = () => {
        const values = this.timelineBuilderRef?.getValues();
        if (!values) return null;
        const mobile: string = values?.data?.mobile || "";

        if (mobile.length < 11) {
            return toast.warn('شماره موبایل باید ۱۱ رقم باشد')
        }
        
        this.searchByMobile(mobile)
    }

    searchByMobile = (mobile: string) => {
        this.setState({ ...this.state, searching: true })

        this.props.getTimelineByMobile(mobile).then((res: AxiosResponse) => {
            this.setState({ ...this.state, data: res.data.results, searching: false })
        }).catch((err: AxiosError) => {
            toast.error('خطایی رخ داده است')
            this.setState({ ...this.state, searching: false })
        })
    }

    showDetails = (data: any) => () => {
        const item = { ...data }
        item.extra = data.extra ? JSON.parse(data.extra) : null;
        this.setState({ ...this.state, showDetails: true, details: item })
    }

    componentDidMount(): void {
        if (this.props.mobile && this.props.mobile.length === 11) {
            this.searchByMobile(this.props.mobile)
        }
    }

    getTypeTitle = (type: string) => {
        switch (type) {
            case "create": return "ایجاد";
            case "reset_traffic": return "بازنشانی حجم";
            case "renewal": return "تمدید";
            case "extra_package": return "بسته افزایشی";
            case "delete": return "حذف";
            case "xray": return "تغییر حجم";
            default: return type;
        }
    }

    handleClose = () => {
        this.setState({ ...this.state, showDetails: false })
    }

    close = () => {
        if (this.props.mobile) {
            this.props.setDialogTo(false)
        } else {
            this.setState({ ...this.state, data: null })
        }
    }

    render() {
        return (
            <Box>
                <MuiDialog open={this.state.showDetails} onClose={this.handleClose} PaperProps={{ sx: { width: { xs: '98%', lg: 500 }, height: 'auto', maxWidth: 'unset', margin: { xs: '0px !important', lg: '32px' }, direction: "ltr" } }}>
                    {this.state.details ? (
                        <Fragment>
                            <DialogTitle>{this.getTypeTitle(this.state.details.type)}</DialogTitle>
                            <DialogContent sx={{ padding: { xs: '8px', lg: '20px 24px' } }}>
                                <Box>
                                    <CenterBox sx={{ justifyContent: 'space-between' }}>
                                        <Typography>تاریخ</Typography>
                                        <Typography>{toJalaliDate(this.state.details.datetime, 'HH:mm - DD MMMM YYYY')}</Typography>
                                    </CenterBox>
                                    {this.state.details.extra.down ? (
                                        <CenterBox sx={{ justifyContent: 'space-between' }}>
                                            <Typography>دانلود</Typography>
                                            <Typography>{pHumanFileSize(this.state.details.extra.down)}</Typography>
                                        </CenterBox>
                                    ) : null}
                                    {this.state.details.extra.up ? (
                                        <CenterBox sx={{ justifyContent: 'space-between' }}>
                                            <Typography>اپلود</Typography>
                                            <Typography>{pHumanFileSize(this.state.details.extra.up)}</Typography>
                                        </CenterBox>
                                    ) : null}
                                    {this.state.details.extra.total ? (
                                        <CenterBox sx={{ justifyContent: 'space-between' }}>
                                            <Typography>سقف مصرف</Typography>
                                            <Typography>{pHumanFileSize(this.state.details.extra.total)}</Typography>
                                        </CenterBox>
                                    ) : null}
                                    {this.state.details.extra.expiryTime ? (

                                        <CenterBox sx={{ justifyContent: 'space-between' }}>
                                            <Typography>تاریخ انقضا</Typography>
                                            <Typography>{this.state.details.extra.expiryTime > 0 ? e2p(moment(this.state.details.extra.expiryTime).format('DD MMMM YYYY')) : 'بدون انقضا'}</Typography>
                                        </CenterBox>
                                    ) : null}

                                    {this.state.details.type === "xray" ? (
                                        <Fragment>
                                            <CenterBox sx={{ justifyContent: 'space-between' }}>
                                                <Typography>اپلود</Typography>
                                                <CenterBox>
                                                    <Typography>{pHumanFileSize(this.state.details.extra.to_up)}</Typography>
                                                    <ArrowRight style={{ marginRight: 4, marginLeft: 4 }} size="18" color="#55a630" />
                                                    <Typography>{pHumanFileSize(this.state.details.extra.from_up)}</Typography>
                                                </CenterBox>
                                            </CenterBox>

                                            <CenterBox sx={{ justifyContent: 'space-between' }}>
                                                <Typography>دانلود</Typography>
                                                <CenterBox>
                                                    <Typography>{pHumanFileSize(this.state.details.extra.to_down)}</Typography>
                                                    <ArrowRight style={{ marginRight: 4, marginLeft: 4 }} size="18" color="#55a630" />
                                                    <Typography>{pHumanFileSize(this.state.details.extra.from_down)}</Typography>
                                                </CenterBox>
                                            </CenterBox>

                                            <CenterBox sx={{ justifyContent: 'space-between' }}>
                                                <Typography>سقف مصرف</Typography>
                                                <CenterBox>
                                                    <Typography>{pHumanFileSize(this.state.details.extra.to_total)}</Typography>
                                                    <ArrowRight style={{ marginRight: 4, marginLeft: 4 }} size="18" color="#55a630" />
                                                    <Typography>{pHumanFileSize(this.state.details.extra.from_total)}</Typography>
                                                </CenterBox>
                                            </CenterBox>
                                            <CenterBox sx={{ justifyContent: 'space-between' }}>
                                                <Typography>تاریخ انقضا</Typography>
                                                <CenterBox>
                                                    <Typography>{this.state.details.extra.to_expiryTime > 0 ? e2p(moment(this.state.details.extra.to_expiryTime).format('DD MMMM YYYY')) : 'بدون انقضا'}</Typography>
                                                    <ArrowRight style={{ marginRight: 4, marginLeft: 4 }} size="18" color="#55a630" />
                                                    <Typography>{this.state.details.extra.from_expiryTime > 0 ? e2p(moment(this.state.details.extra.from_expiryTime).format('DD MMMM YYYY')) : 'بدون انقضا'}</Typography>
                                                </CenterBox>
                                            </CenterBox>

                                        </Fragment>
                                    ) : null}

                                </Box>
                            </DialogContent>
                        </Fragment>
                    ) : null}
                </MuiDialog>

                {this.state.data === null ? (
                    <Box>
                        <FormBuilder inputs={[
                            {
                                selector: 'mobile',
                                label: 'شماره تلفن همراه',
                                placeholder: '(0912) xxx xxxx',
                                type: 'mask',
                                pattern: '(....) ... ....',
                                inputProps: { inputMode: 'numeric', dir: 'ltr' },
                                getMutator: value => value ? p2e(value).replace(/[^0-9]/g, "") : null,
                                required: true,
                                fullWidth: true,
                                error: true,
                                autoFocus: true,
                                defaultValue: this.props.mobile ? mask(this.props.mobile, '(....) ... ....') : "",
                                InputProps: {
                                },
                            },
                        ]} ref={el => this.timelineBuilderRef = el} />
                        <Button sx={{ mt: 2, height: 48 }} disabled={this.state.searching} disableElevation fullWidth variant="contained" onClick={this.onSearch}>
                            {this.state.searching ? <CircularProgress size={22} color='inherit' /> : "جستجو و نمایش تاریخچه"}
                        </Button>
                    </Box>
                ) : (
                    <Box>
                        <Box>
                            {this.state.data ? this.state.data.map((item: any, index: number) => {
                                return (
                                    <Box key={index} sx={{ mt: 1 }}>
                                        <CenterBox sx={{ width: '100%', justifyContent: 'space-between', backgroundColor: '#edf2f4', p: 1, borderRadius: '5px 5px 0px 0px' }}>
                                            <Typography>{item.user.full_name}</Typography>
                                            <Typography>{item.user.id}</Typography>
                                        </CenterBox>
                                        <Box sx={{ border: '1px solid #edf2f4', p: 1, pb: 0, borderRadius: '0px 0px 5px 5px' }}>
                                            {item.events ? item.events.map((event: any, index: number) => (
                                                <CenterBox key={index} sx={{ justifyContent: 'space-between', pb: 1 }}>
                                                    <CenterBox>
                                                        <Typography>{this.getTypeTitle(event.type)}</Typography>
                                                        {event.type !== 'create' ? (
                                                            <IconButton onClick={this.showDetails(event)} size="small"><Information color="#0077b6" size={24} /></IconButton>
                                                        ) : null}
                                                    </CenterBox>

                                                    <Typography>{toJalaliDate(event.datetime, 'HH:mm - DD MMMM YYYY')}</Typography>
                                                </CenterBox>
                                            )) : null}
                                        </Box>
                                    </Box>
                                )
                            }) : null}
                            {this.state.data && this.state.data.length < 1 ? (
                                <Typography sx={{ textAlign: 'center', fontSize: 14, mb: 2 }}>اطلاعاتی برای نمایش وجود ندارد </Typography>
                            ) : null}
                        </Box>
                        <Button
                            onClick={this.close}
                            sx={{ mt: 1 }}
                            variant='outlined'
                            disableElevation
                            color="error"
                            fullWidth>بستن</Button>
                    </Box>

                )}
            </Box>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getTimelineByMobile: (mobile: string) => dispatch(getTimelineByMobileAsync(mobile)),
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),

})

const mapStateToProps = (state: AppState) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Timeline)
