import CenterBox from 'components/CenterBox'
import { FitBox } from 'components/Helper'
import withRouter, { Router } from 'helpers/withRouter'
import React, { Component, Fragment } from 'react'
import { Navigate } from 'react-router-dom'

interface InitializeProps {
    router: Router
}

class Initialize extends Component<InitializeProps> {
    render() {
        return (
            <Fragment>
                {this.props.router.location.pathname === "/" ? (
                    <Navigate to={'/users'} replace={true} />
                ) : null}
                <FitBox>
                    <CenterBox>
                        <h1>درحال بارگیری ...</h1>
                    </CenterBox>
                </FitBox>
            </Fragment>
        )
    }
}

export default withRouter(Initialize)