import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";
import { GetSettlementsData, SettlementsResponseData } from "./types";

const settlementsSlice = createSlice({
    name: "settlements",
    initialState: {
        get_process: false,
        results: null as SettlementsResponseData | null,
        settlement_process: false,
        reverse_process: false,

        delete_process: false,
    },
    reducers: {
        getSettlementsStarted(state) {
            state.get_process = true;
            if (state.results) state.results.data = [];
        },
        getSettlementsSuccess(state, action: PayloadAction<SettlementsResponseData>) {
            state.results = action.payload;
            state.get_process = false;
        },
        getSettlementsFailed(state, action: PayloadAction<any>) {
            state.get_process = false;
            state.results = null;
        },

        reverseSettlementsStarted(state) {
            state.reverse_process = true;
        },
        reverseSettlementsSuccess(state, action: PayloadAction<SettlementsResponseData>) {
            state.reverse_process = false;
        },
        reverseSettlementsFailed(state, action: PayloadAction<any>) {
            state.reverse_process = false;
        },
    },
});

// export const {} = settlementsSlice.actions;

export const getSettlementsAsync = (data?: GetSettlementsData) => (dispatch: AppDispatch) => {
    dispatch(settlementsSlice.actions.getSettlementsStarted());
    return new Promise((resolve, reject) => {
        let search = `?limit=${data?.limit || 10}&page=${data?.page || 1}`;
        if (data?.query) search += `&query=${data?.query}`;
        if (data?.id) search += `&id=${data?.id}`;
        axios
            .get(api.settlements + search)
            .then((response: AxiosResponse<SettlementsResponseData>) => {
                dispatch(settlementsSlice.actions.getSettlementsSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(settlementsSlice.actions.getSettlementsFailed(error.response?.data));
                reject(error);
            });
    });
};

export const reverseSettlementsAsync = (id: number) => (dispatch: AppDispatch) => {
    dispatch(settlementsSlice.actions.reverseSettlementsStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.settlements + "/reverse", {
                settlement_id: id,
            })
            .then((response: AxiosResponse<SettlementsResponseData>) => {
                dispatch(settlementsSlice.actions.reverseSettlementsSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(settlementsSlice.actions.reverseSettlementsFailed(error.response?.data));
                reject(error);
            });
    });
};

export default settlementsSlice;
