import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import Sidebar from 'components/Sidebar'
import { checkAuthAsync } from 'containers/signin/slice'
import withRouter, { Router } from 'helpers/withRouter'
import { Danger } from 'iconsax-react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

interface PanelLayoutProps {
    checkAuth: () => Promise<any>,
    router: Router

}

interface IState {
    loaded: boolean,
    error: boolean
}

class PanelLayout extends Component<PanelLayoutProps, IState> {
    state: IState = {
        loaded: false,
        error: false
    }

    loadData = async () => {
        this.setState({ ...this.state, loaded: false, error: false }, async () => {
            try {
                await this.props.checkAuth();
                this.setState({ ...this.state, loaded: true })
            } catch (error) {
                this.setState({ ...this.state, error: true })
            }
        })
    }

    checkAuthInterval: any;
    async componentDidMount() {
        await this.loadData()
        // clearInterval(this.checkAuthInterval)
        // this.checkAuthInterval = setInterval(() => {
        //     this.props.checkAuth();
        // }, 30000)
    }

    componentWillUnmount(): void {
        // clearInterval(this.checkAuthInterval)
    }

    logout = (e: any) => {
        localStorage.removeItem('accessToken')
        this.props.router.navigate('/auth/signin')
    }

    render() {
        if (this.state.error) return (
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Danger size="56" color="#FF8A65" />
                <Typography sx={{ mt: 2, direction: 'rtl' }}>دریافت اطلاعات با خطا مواجه شد !</Typography>
                <Button onClick={this.loadData}>تلاش مجدد</Button>
                <Button onClick={this.logout} sx={{ mt: 1 }} color="error">خروج</Button>
            </Box>
        )

        if (!this.state.loaded) return (
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <CircularProgress color="inherit" />
                <Typography sx={{ my: 4 }}>! درحال تایید دسترسی</Typography>
            </Box>
        )

        return (
            <Box sx={{ height: { lg: '100%', xs: 'calc(100% - 48px)' }, overflow: { lg: 'hidden' } }}>
                <Grid container sx={{ height: '100%' }} >
                    <Grid item lg={10.2} xs={12} order={{ lg: 1, xs: 2 }} sx={{ height: '100%', backgroundColor: '#f7f7f8' }}>
                        <Outlet />
                    </Grid>
                    <Grid item lg={1.8} xs={12} order={{ lg: 2, xs: 1 }} sx={{ height: { lg: '100%', xs: 48 } }}>
                        <Sidebar />
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    checkAuth: () => dispatch(checkAuthAsync())
})

export default connect(null, mapDispatchToProps)(withRouter(PanelLayout))

