import { Box, Button, Fade, Grid, Grow, InputAdornment, Tooltip, Typography } from '@mui/material'
import { GridCallbackDetails, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid'
import CenterBox from 'components/CenterBox'
import { FitBox } from 'components/Helper'
import PanelDataGrid, { ChangeDataGrid, DataGridParam } from 'components/PanelDataGrid'
import SuspenseLoading from 'components/SuspenseLoading'
import { BlackSection } from 'containers/dashboard'
import { getPackagesAsync } from 'containers/packages/slice'
import { GetPackagesData, Package } from 'containers/packages/types'
import { getPlansAsync } from 'containers/plans/slice'
import { GetPlansData, Plan } from 'containers/plans/types'
import { getServersAsync } from 'containers/servers/slice'
import { GetServersData, Server } from 'containers/servers/types'
import { clone, numberFormat, p2e, setPageLoadingTo } from 'helpers/general'
import withRouter, { Router } from 'helpers/withRouter'
import { Add, Box1, Shop, TickSquare } from 'iconsax-react'
import { FormBuilder } from 'material-form-builder'
import React, { Component, Fragment, Suspense } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setDialogTo } from 'redux/slice'
import { AppState } from 'redux/store'
import { SetDialogPayload } from 'redux/types'
import { Ref } from 'types/general'
import { AxiosError, AxiosResponse } from 'utils/axios'
import { getSellersAsync, settlementSellersAsync } from './slice'
import { GetSellersData, Seller, SellersResponseData, SettlementWithSellerData } from './types'

interface IState {
    page: number,
    limit: number,
    selectedRow: Seller | null,
    createMode: boolean,

    requirements_loading: boolean,
    plans: Plan[],
    packages: Package[],
    servers: Server[],
    sellers: Seller[],
}
interface SellersInterface extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
    router: Router<{ seller_id?: number }>
}

class Sellers extends Component<SellersInterface, IState> {

    state: IState = {
        page: 1,
        limit: 10,
        selectedRow: null,
        createMode: false,
        requirements_loading: false,
        plans: [],
        packages: [],
        servers: [],
        sellers: []
    }

    settlementBuilder: Ref<FormBuilder>

    private columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'شناسه',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 80,
            minWidth: 80,
        },
        {
            field: 'full_name',
            headerName: 'نام',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 140,
            valueGetter: event => {
                return event.row.id === this.props.seller.id ? event.row.full_name + " (خودم)" : event.row.full_name;
            }
        },
        {
            field: 'debt',
            headerName: 'بدهی',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 170,
            maxWidth: 170,
            headerAlign: 'center',
            align: 'center',
            renderCell: event => {
                if (event.row.type === "manager" && this.props.seller.type === "super") return <Button color='warning' disabled={(event.row.debt?.amount || 0) < 100000} onClick={this.settlementWithManagerDialog(event.row)}>{numberFormat(event.row.debt?.amount || 0)} ریالءء</Button>
                if (event.row.type === "manager") return <Typography sx={{ fontSize: 14, color: '#ed6c02' }}>{numberFormat(event.row.unpaid_orders_amount || 0)} ریالءء</Typography>
                if (event.row.type === "seller") return (
                    <Box>
                        {event.row.unpaid_orders_amount > 0 ? (
                            <Typography sx={{ fontSize: 14, color: '#3F4F64' }}>{numberFormat(event.row.unpaid_orders_amount || 0)} ریالءء</Typography>
                        ) : (
                            <Typography sx={{ fontSize: 14, color: '#4f772d' }}>بدون بدهی</Typography>
                        )}
                    </Box>);
                return <Fragment> - </Fragment>;
            }
        },
        {
            field: 'type',
            headerName: 'دسترسی',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            maxWidth: 100,
            valueGetter: event => this.getType(event.row.type)
        },
        {
            field: 'mobile',
            headerName: 'شماره موبایل',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 120,
            maxWidth: 120,
        },
        {
            field: 'accounts_count',
            headerName: 'تعداد کاربران',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            maxWidth: 100,
            align: 'center',
            renderCell: event => {
                return (
                    <Box>
                        <Tooltip title="نمایش کاربران" arrow placement="right">
                            <NavLink style={{
                                // textDecoration: 'none',
                                color: '#023e8a'
                            }} to={`/users/${event.row.id}`}>{event.row.accounts_count || 0}</NavLink>
                        </Tooltip>
                    </Box>);
            }
        },
        {
            field: 'orders_count',
            headerName: 'فاکتور ها',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 80,
            maxWidth: 80,
            align: 'center',
            renderCell: event => {
                return (
                    <Box>
                        <Tooltip title="نمایش فاکتور ها" arrow placement="right">
                            <NavLink style={{
                                // textDecoration: 'none',
                                color: '#023e8a'
                            }} to={`/bill/${event.row.id}`}>{event.row.orders_count || 0}</NavLink>
                        </Tooltip>
                    </Box>);
            }
        },
    ];

    settlementWithManagerDialog = (seller: Seller) => () => {
        if (!seller.debt) return null;
        const debt_amount = seller.debt.amount;
        this.props.setDialogTo({
            title: 'تسویه حساب با ' + seller.full_name,
            content: () => {
                return <Fragment>
                    <Box>
                        <FormBuilder inputs={[
                            {
                                selector: 'amount',
                                label: 'مبلغ تسویه',
                                type: 'text',
                                defaultValue: numberFormat(debt_amount),
                                autoFocus: true,
                                formatter: (value: any) => {
                                    if (p2e(value) > debt_amount) value = debt_amount;
                                    return value ? numberFormat(p2e(value).replace(/^0|[^0-9]/g, '')) : value
                                },
                                getMutator: (value: any) => value ? parseInt(value.replace(/[^0-9]/g, '')) : value,
                                inputProps: { style: { direction: 'ltr' }, inputMode: 'numeric' },
                                InputProps: { endAdornment: (<InputAdornment position="end">ریالءء</InputAdornment>) },
                                required: true,
                                fullWidth: true,
                            },

                        ]} ref={el => this.settlementBuilder = el} />
                    </Box>

                    <Typography sx={{ mt: 2, color: '#707070', fontSize: 14 }}>پس از ثبت تسویه، مبلغ مورد نظر بدهی ایشان کسر خواهد شد</Typography>
                </Fragment>
            },
            buttons: [
                {
                    title: 'تایید / تسویه مبلغ',
                    variant: 'contained',
                    disableElevation: true,
                    fullWidth: true,
                    sx: { height: 48 },
                    endIcon: <TickSquare size={22} />,
                    onClick: () => {
                        const values = this.settlementBuilder?.getValues();
                        const data = values?.data;
                        if (!data) return null;
                        if (!values?.validation.status) return toast.warning('لطفا مبلغ تسویه را وارد نمایید')
                        if (data.amount < 100000) return toast.warning(<Box>
                            <Typography>مبلغ بیشتری انتخاب کنید</Typography>
                            <Typography sx={{ fontSize: 14 }}>حداقل مبلغ ۱۰۰،۰۰۰ ریالءء</Typography>
                        </Box>)

                        this.props.setDialogTo(false)

                        setPageLoadingTo('در حال تسویه')
                        this.props.settlementSellers({
                            seller_id: seller.id,
                            amount: data.amount
                        }).then(() => {
                            toast.success('تسویه با موفقیت انجام شد')
                            setPageLoadingTo(false)
                        }).catch((e: AxiosError) => {
                            toast.error('خطا در تسویه / مجدد تلاش کنید')
                            setPageLoadingTo(false)
                        })
                    }
                }
            ]
        })
    }


    async componentDidMount() {
        this.onChangeDataGrip()
    }

    loadRequirement = async () => {
        const plans_response = await this.props.getPlans({ limit: 1000, sort: 'view_priority,desc' })
        const packages_response = await this.props.getPackages({ limit: 1000, sort: 'view_priority,desc' })
        const servers_response = await this.props.getServers({ limit: 1000 })
        const sellers_response = await this.props.getAllSellers({ limit: 1000 })

        this.setState({
            ...this.state,
            plans: clone(plans_response.data.results.data),
            packages: clone(packages_response.data.results.data),
            servers: clone(servers_response.data.results.data),
            sellers: clone(sellers_response.data.results.data).filter((i: Seller) => i.type !== 'seller'),
            requirements_loading: false
        })
    }

    componentDidUpdate(prevProps: Readonly<SellersInterface>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.router.params.seller_id !== this.props.router.params.seller_id) {
            this.onChangeDataGrip()
        }
    }

    getType = (type: string): string => {
        switch (type) {
            case "super": return "ارائه دهنده";
            case "manager": return "منیجر";
            case "seller": return "فروشنده";
            default: return "ناشناس";
        }
    }

    selectRow = (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        const id = selectionModel[0];
        if (id === this.props.seller.id) {
            this.setState({ ...this.state, selectedRow: null, createMode: false });
            return null;
        }

        const item = this.props.sellers?.data.find(i => i.id === id)
        if (!item) return null;

        if (this.state.selectedRow?.id !== item?.id) {
            this.setState({ ...this.state, selectedRow: null, createMode: false }, () => {
                this.setState({ ...this.state, selectedRow: item })
            })
        }
    }

    unselectRow = () => {
        this.setState({ ...this.state, selectedRow: null, createMode: false })
    }

    onChangeDataGrip = (data: ChangeDataGrid = { page: 1, limit: 10, query: null }) => {
        this.loadRequirement();
        this.setState({ ...this.state, selectedRow: null, createMode: false })
        return new Promise((resolve, reject) => {
            this.props.getSellers({
                page: data.page,
                limit: data.limit,
                query: data.query,
                id: this.props.router.params.seller_id || null
            }).then((response: AxiosResponse<SellersResponseData>) => {
                if (this.props.router.params.seller_id) {
                    this.setState({ ...this.state, selectedRow: response.data.results.data[0] })
                } else {
                    this.setState({ ...this.state, selectedRow: null })
                }
                resolve(response)
            }).catch((error: AxiosError) => {
                reject(error)
            })
        })
    }

    getParams = () => {
        let params: DataGridParam[] = [];
        if (this.props.router.params.seller_id) {
            params.push({
                label: `فروشنده انتخاب شده: #${this.props.router.params.seller_id}`,
                onDelete: async () => {
                    await this.props.router.navigate('/sellers')
                    this.onChangeDataGrip()
                }
            })
        }
        params.push({
            label: <CenterBox> <Add size="18" color={this.state.createMode ? '#fff' : '#28a745'} /> ایجاد فروشنده جدید</CenterBox>,
            activeColor: '#28a745',
            isActive: this.state.createMode,
            onClick: async () => {
                this.setState({ ...this.state, createMode: !this.state.createMode, selectedRow: null })
            }
        })
        return params;
    }

    render() {
        const CreateWorkspace = React.lazy(() => import('./createWorkspace'))
        const Workspace = React.lazy(() => import('./workspace'))
        return (
            <Grid container sx={{ height: { lg: '100%', xs: 'calc(100% - 48px)' } }}>
                <Grid item lg={3.5} xs={12}>
                    <BlackSection sx={{
                        display: { xs: this.state.createMode || this.state.selectedRow ? 'block' : 'none', lg: 'block' },
                        position: { xs: 'absolute', lg: 'relative' },
                        right: 0, left: 0, bottom: 0, top: 0, zIndex: 100
                    }}>
                        {this.state.createMode ? (
                            <Suspense fallback={<SuspenseLoading />}>
                                <Fade in={true} timeout={500}>
                                    <FitBox>
                                        <CreateWorkspace
                                            refresh={this.onChangeDataGrip}
                                            close={() => this.setState({ ...this.state, createMode: false })}
                                            plans={this.state.plans}
                                            servers={this.state.servers}
                                            sellers={this.state.sellers}
                                            packages={this.state.packages}
                                        />
                                    </FitBox>
                                </Fade>
                            </Suspense>
                        ) : this.state.selectedRow ? (
                            <Suspense fallback={<SuspenseLoading />}>
                                <Fade in={true} timeout={500}>
                                    <FitBox>
                                        <Workspace
                                            refresh={this.onChangeDataGrip}
                                            data={this.state.selectedRow}
                                            unselectRow={this.unselectRow}
                                            plans={this.state.plans} servers={this.state.servers} sellers={this.state.sellers}
                                            packages={this.state.packages}
                                        />
                                    </FitBox>
                                </Fade>
                            </Suspense>
                        ) : (
                            <Grow in={true} timeout={800}>
                                <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Box1 size={140} color="#fff" fontWeight={200} />
                                    <Typography dir="rtl" mt={3}>میز کار خالی است !</Typography>
                                </Box>
                            </Grow>
                        )}
                    </BlackSection>
                </Grid>
                <Grid item lg={8.5} xs={12} sx={{ backgroundColor: '#f7f7f8', height: '100%' }}>
                    <PanelDataGrid
                        sx={{ padding: { xs: '4px', lg: '16px' }, paddingTop: '16px !important' }}
                        label="فروشندگان"
                        subtitle=''
                        icon={Shop}
                        columns={this.props.seller.type === "seller" ? this.columns.filter(i => i.field !== 'debt') : this.columns}
                        rows={this.props.sellers?.data}
                        loading={this.props.get_process}
                        last_page={this.props.sellers?.last_page}
                        total_rows={this.props.sellers?.total}
                        onChange={this.onChangeDataGrip}
                        onSelectRow={this.selectRow}
                        params={this.getParams()}
                        searchable
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getSellers: (data?: GetSellersData) => dispatch(getSellersAsync(data)),
    getPlans: (data?: GetPlansData) => dispatch(getPlansAsync(data, false)),
    getPackages: (data?: GetPackagesData) => dispatch(getPackagesAsync(data, false)),
    getServers: (data?: GetServersData) => dispatch(getServersAsync(data, false)),
    getAllSellers: (data?: GetSellersData) => dispatch(getSellersAsync(data, false)),
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),
    settlementSellers: (data?: SettlementWithSellerData) => dispatch(settlementSellersAsync(data)),
})

const mapStateToProps = (state: AppState) => ({
    sellers: state.sellers.results,
    seller: state.signin.seller,
    get_process: state.sellers.get_process
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sellers))