import moment from "jalali-moment";
import { setPageLoading } from "redux/slice";
import store from "redux/store";

export const toJalaliDate = (datetime?: string | null, format = "HH:mm:ss YYYY-MM-DD") => {
    if (!datetime) return null;
    return moment.from(datetime, "en", "YYYY/MM/DD HH:mm:ss").locale("fa").format(format);
};

export const clone = (input: object) => {
    return JSON.parse(JSON.stringify(input));
};

export const getSize = (bytes: number, decimals: number = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const setPageLoadingTo = (status: boolean | string) => {
    store.dispatch(setPageLoading(status))
}

export const humanFileSize = (size: number) => {
    if (size < 1024) return size + ' B'
    let i = Math.floor(Math.log(size) / Math.log(1024))
    let num: any = (size / Math.pow(1024, i))
    let round = Math.round(num)
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
    return `${num} ${'KMGTPEZY'[i - 1]}B`
}

export const pHumanFileSize = (size: number) => {
    if (size < 1024) return size + ' بایت'
    let i = Math.floor(Math.log(size) / Math.log(1024))
    let num: any = (size / Math.pow(1024, i))
    let round = Math.round(num)
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
    let unit = [
        'کیلو',
        'مگا',
        'گیگا',
        'ترا',
        'پتا',
    ]
    return `${num} ${unit[i - 1]}بایت`
}


export const e2p = (s: any) => s.toString().replace(/\d/g, (d: any) => '۰۱۲۳۴۵۶۷۸۹'[d]);
export const p2e = (s: any) => s.toString().replace(/[۰-۹]/g, (d: any) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));

export const randomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const numberFormat = (value: string | number, count: number = 3, separator: string = ',') => {
    if (typeof value === "number") value = String(value);
    let objRegex = new RegExp('(-?[0-9]+)([0-9]{' + count + '})')
    let val = value.replace(/[^0-9-]/gi, '')
    while (objRegex.test(val)) {
        val = val.replace(objRegex, '$1' + separator + '$2')
    }
    return val;
}

export const sum = (array: any[], selector: string) => {
    if (typeof selector === 'function') {
        return array.reduce(selector, 0)
    } else {
        switch (typeof array[0]) {
            case 'object':
                return (
                    array.map((item) => {
                        let splitSelector = selector.split('.')
                        if (splitSelector.length > 1) {
                            let output = item
                            try {
                                splitSelector.forEach((key, index) => {
                                    if (Array.isArray(output)) {
                                        let nSelector = splitSelector.slice(index).join('.')
                                        // output = output.sum(nSelector)
                                        output = sum(output, nSelector)
                                        throw new Error()
                                    } else {
                                        output = output[key]
                                    }
                                })
                            } catch (error) {
                                //
                            }
                            return typeof output === 'number' ? output : 0
                        } else {
                            return item[selector] || 0
                        }
                    }).reduce((a, b) => a + b, 0) || 0
                )
            case 'number':
                return array.reduce((a, b) => a + b, 0)
            default:
                return 0
        }
    }
}

export const mask = (value: string, pattern: string, placeholder = '') => {
    let i = 0,
        v = value.toString()
    return pattern.replace(/\./g, (_) => v[i++] || placeholder)
}

export const mobileFormatter = (value: string | null) => {
    let output;
    output = value?.replace(/[^0-9]/g, "");
    output = p2e(output)
    output = output.replace(/^\+98|^98|^098|^0098/, '')

    if (!output.startsWith('0')) {
        output = output ? '0' + output : output
    }

    if (!output.startsWith('9', 1) && output.length > 1) {
        output = output.replace(/^0/, '')
        output = output ? '09' + output : output;
    }

    return output
}
