import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";
import { ChangeSellerPasswordData, CreateSellerData, GetSellersData, Seller, SellersResponseData, SettlementWithSellerData, UpdateSellerData } from "./types";

const sellersSlice = createSlice({
    name: "sellers",
    initialState: {
        get_process: false,
        results: null as SellersResponseData | null,
        create_process: false,
        update_process: false,
        delete_process: false,
        settlement_process: false,
    },
    reducers: {
        getSellersStarted(state) {
            state.get_process = true;
            if (state.results) state.results.data = [];
        },
        getSellersSuccess(state, action: PayloadAction<SellersResponseData>) {
            state.results = action.payload;
            state.get_process = false;
        },
        getSellersFailed(state, action: PayloadAction<any>) {
            state.get_process = false;
            state.results = null;
        },

        createSellersStarted(state) {
            state.create_process = true;
        },
        createSellersSuccess(state, action: PayloadAction<SellersResponseData>) {
            state.create_process = false;
        },
        createSellersFailed(state, action: PayloadAction<any>) {
            state.create_process = false;
        },

        updateSellersStarted(state) {
            state.update_process = true;
        },
        updateSellersSuccess(state, action: PayloadAction<Seller>) {
            state.update_process = false;
            if (state.results) {
                state.results.data = state.results.data.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                });
            }
        },
        updateSellersFailed(state, action: PayloadAction<any>) {
            state.update_process = false;
        },

        deleteSellersStarted(state) {
            state.delete_process = true;
        },
        deleteSellersSuccess(state, action: PayloadAction<SellersResponseData>) {
            state.delete_process = false;
        },
        deleteSellersFailed(state, action: PayloadAction<any>) {
            state.delete_process = false;
        },

        settlementSellersStarted(state) {
            state.settlement_process = true;
        },
        settlementSellersSuccess(state, action: PayloadAction<Seller>) {
            state.settlement_process = false;
            if (state.results) {
                state.results.data = state.results.data.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }
                    return item;
                });
            }
        },
        settlementSellersFailed(state, action: PayloadAction<any>) {
            state.settlement_process = false;
        },
    },
});

// export const {} = sellersSlice.actions;

export const getSellersAsync =
    (data?: GetSellersData, redux: boolean = true) =>
    (dispatch: AppDispatch) => {
        if (redux) {
            dispatch(sellersSlice.actions.getSellersStarted());
        }
        return new Promise((resolve, reject) => {
            let search = `?limit=${data?.limit || 10}&page=${data?.page || 1}`;
            if (data?.query) search += `&query=${data?.query}`;
            if (data?.id) search += `&id=${data?.id}`;
            axios
                .get(api.sellers + search)
                .then((response: AxiosResponse<SellersResponseData>) => {
                    if (redux) {
                        dispatch(sellersSlice.actions.getSellersSuccess(response.data.results));
                    }
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    if (redux) {
                        dispatch(sellersSlice.actions.getSellersFailed(error.response?.data));
                    }
                    reject(error);
                });
        });
    };

export const createSellersAsync = (data?: CreateSellerData) => (dispatch: AppDispatch) => {
    dispatch(sellersSlice.actions.createSellersStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.sellers, data)
            .then((response: AxiosResponse) => {
                dispatch(sellersSlice.actions.createSellersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(sellersSlice.actions.createSellersFailed(error.response?.data));
                reject(error);
            });
    });
};

export const updateSellersAsync = (id: number, data: UpdateSellerData) => (dispatch: AppDispatch) => {
    dispatch(sellersSlice.actions.updateSellersStarted());
    return new Promise((resolve, reject) => {
        axios
            .put(api.sellers + "/" + id, data)
            .then((response: AxiosResponse) => {
                dispatch(sellersSlice.actions.updateSellersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(sellersSlice.actions.updateSellersFailed(error.response?.data));
                reject(error);
            });
    });
};

export const changeSellerPasswordAsync = (seller_id: number, data?: ChangeSellerPasswordData) => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .put(api.sellers + "/" + seller_id + "/change_password", data)
            .then((response: AxiosResponse) => {
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export const deleteSellersAsync = (seller_id: number) => (dispatch: AppDispatch) => {
    dispatch(sellersSlice.actions.deleteSellersStarted());
    return new Promise((resolve, reject) => {
        axios
            .delete(api.sellers + "/" + seller_id)
            .then((response: AxiosResponse) => {
                dispatch(sellersSlice.actions.deleteSellersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(sellersSlice.actions.deleteSellersFailed(error.response?.data));
                reject(error);
            });
    });
};

export const settlementSellersAsync = (data?: SettlementWithSellerData) => (dispatch: AppDispatch) => {
    dispatch(sellersSlice.actions.settlementSellersStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.sellers + "/settlement", data)
            .then((response: AxiosResponse) => {
                dispatch(sellersSlice.actions.settlementSellersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(sellersSlice.actions.settlementSellersFailed(error.response?.data));
                reject(error);
            });
    });
};

export default sellersSlice;
