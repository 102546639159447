import { Box, Grid } from '@mui/material';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import React, { Component } from 'react';

import { setPageLoadingTo } from 'helpers/general';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { getDashboardDataAsync } from './slice';
import { styled } from "@mui/system"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
ChartJS.defaults.font.family = "IRANYekanX";

interface DashboardProps extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> { }

class Dashboard extends Component<DashboardProps> {

    createOption = (label: string) => {
        const options: ChartOptions<'bar'> = {
            responsive: true,
            interaction: {
                intersect: false,
                mode: 'index',
            },
            font: {
                family: 'IRANYekanX',
            },
            plugins: {
                legend: {
                    position: 'top' as const,
                },
                title: {
                    display: true,
                    text: label,
                },
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    display: false
                },
            }
        };
        return options;
    }

    componentDidMount() {
        setPageLoadingTo('درحال پردازش اطلاعات')
        this.props.getDashboardData()
            .then(() => {
                setPageLoadingTo(false)
            })
            .catch(() => {
                setPageLoadingTo(false)
            })
    }

    render() {
        return (
            <Grid container spacing={{ xs: 1, md: 2 }} padding={{ md: 2, xs: 0.8 }} sx={{ backgroundColor: '#f7f7f8' }}>

                <Grid item xs={12} lg={6}>
                    <DataWrapper>
                        <Bar options={this.createOption('مبلغ فروش ۷ روز اخیر (تومانءءء)')} data={this.props.dashboard.data.daily_amount_chart} />
                    </DataWrapper>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <DataWrapper>
                        <Bar options={this.createOption('مبلغ فروش ۷ ماه اخیر (تومانءءء)')} data={this.props.dashboard.data.monthly_amount_chart} />
                    </DataWrapper>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <DataWrapper>
                        <Bar options={this.createOption('تعداد فروش ۷ روز اخیر')} data={this.props.dashboard.data.daily_count_chart} />
                    </DataWrapper>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <DataWrapper>
                        <Bar options={this.createOption('تعداد فروش ۷ ماه اخیر')} data={this.props.dashboard.data.monthly_count_chart} />
                    </DataWrapper>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    dashboard: state.dashboard
})

const mapDispatchToProps = (dispatch: any) => ({
    getDashboardData: () => dispatch(getDashboardDataAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export const BlackSection = styled(Box)({
    backgroundColor: '#21201F',
    height: '100%',
    color: '#fff',
})

const DataWrapper = styled(Box)({
    backgroundColor: '#fff',
    borderRadius: 5,
    overflow: 'hidden',
    width: '100%',
})
