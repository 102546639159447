import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
// import { CacheProvider } from '@emotion/react';
import { Box, BoxProps } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import React from "react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";

// Create rtl cache
const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, stylisRTLPlugin],
});

function Rtl(props: BoxProps) {
    const parentTheme = useTheme();
    const theme = createTheme({ ...parentTheme, direction: "rtl" });

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <Box dir="rtl" {...props}>
                    {props.children}
                </Box>
            </ThemeProvider>
        </CacheProvider>
    );
}

export default Rtl;
