import { Box, Fade, Grid, Grow, Tooltip, Typography } from '@mui/material'
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid'
import CenterBox from 'components/CenterBox'
import { FitBox } from 'components/Helper'
import PanelDataGrid, { ChangeDataGrid, DataGridParam } from 'components/PanelDataGrid'
import SuspenseLoading from 'components/SuspenseLoading'
import { BlackSection } from 'containers/dashboard'
import { numberFormat } from 'helpers/general'
import withRouter, { Router } from 'helpers/withRouter'
import { Add, Box1 } from 'iconsax-react'
import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AxiosError, AxiosResponse } from 'utils/axios'
import { getPlansAsync } from './slice'
import { GetPlansData, Plan, PlansResponseData } from './types'
import { NavLink } from 'react-router-dom'

interface IState {
    page: number,
    limit: number,
    selectedRow: Plan | null,
    createMode: boolean
}

interface PlansInterface extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
    router: Router<{ plan_id?: number }>
}

class Plans extends Component<PlansInterface, IState> {

    state: IState = {
        page: 1,
        limit: 10,
        selectedRow: null,
        createMode: false
    }

    private columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'شناسه',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 80,
            minWidth: 80,
        },
        {
            field: 'title',
            headerName: 'عنوان',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'expire',
            headerName: 'اعتبار / روز',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 90,
            maxWidth: 90,
        },
        {
            field: 'traffic',
            headerName: 'ترافیک / گیگ',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            field: 'net_price',
            headerName: 'مبلغ تمام شده',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 130,
            maxWidth: 130,
            renderCell: (event: GridRenderCellParams<any, Plan>) => {
                return event.row.net_price > 0 ? <Typography>{numberFormat(event.row.net_price)} ریالءء</Typography> : <Typography>رایگان</Typography>

            }
        },
        {
            field: 'selling_price',
            headerName: 'قیمت',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 130,
            maxWidth: 130,
            renderCell: (event: GridRenderCellParams<any, Plan>) => {
                return event.row.selling_price > 0 ? <Typography>{numberFormat(event.row.selling_price)} ریالءء</Typography> : <Typography>رایگان</Typography>

            }
        },
        {
            field: 'accounts_count',
            headerName: 'تعداد کاربران',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 95,
            maxWidth: 95,
            align: 'center',
            renderCell: event => {
                return (
                    <Box>
                        <Tooltip title="نمایش کاربران" arrow placement="right">
                                <NavLink style={{
                                    // textDecoration: 'none',
                                    color: '#023e8a'
                                }} to={`/users/plan/${event.row.id}`}>{event.row.accounts_count || 0}</NavLink>
                            </Tooltip>
                    </Box>);
            }
        },
        {
            field: 'orders_count',
            headerName: 'فاکتور ها',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 95,
            maxWidth: 95,
            align: 'center',
            renderCell: event => {
                return (
                    <Box>
                        <Tooltip title="نمایش فاکتور ها" arrow placement="right">
                                <NavLink style={{
                                    // textDecoration: 'none',
                                    color: '#023e8a'
                                }} to={`/bill/plan/${event.row.id}`}>{event.row.orders_count || 0}</NavLink>
                            </Tooltip>
                    </Box>);
            }
        },
    ];

    async componentDidMount() {
        this.onChangeDataGrip()
    }

    componentDidUpdate(prevProps: Readonly<PlansInterface>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.router.params.plan_id !== this.props.router.params.plan_id) {
            this.onChangeDataGrip()
        }
    }

    selectRow = (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        const id = selectionModel[0];
        const item = this.props.plans?.data.find(i => i.id === id)
        this.setState({ ...this.state, selectedRow: item || null, createMode: false })
    }

    unselectRow = () => {
        this.setState({ ...this.state, selectedRow: null, createMode: false })
    }

    onChangeDataGrip = (data: ChangeDataGrid = { page: 1, limit: 10, query: null }) => {
        return new Promise((resolve, reject) => {
            this.props.getPlans({
                page: data.page,
                limit: data.limit,
                query: data.query,
                id: this.props.router.params.plan_id || null
            }).then((response: AxiosResponse<PlansResponseData>) => {
                if (this.props.router.params.plan_id) {
                    this.setState({ ...this.state, selectedRow: response.data.results.data[0] })
                } else {
                    this.setState({ ...this.state, selectedRow: null })
                }
                resolve(response)
            }).catch((error: AxiosError) => {
                reject(error)
            })
        })
    }

    getParams = () => {
        let params: DataGridParam[] = [];
        if (this.props.router.params.plan_id) {
            params.push({
                label: `پلن انتخاب شده: #${this.props.router.params.plan_id}`,
                onDelete: async () => {
                    await this.props.router.navigate('/plans')

                    this.onChangeDataGrip()
                }
            })
        }
        if (this.props.seller.type === "super") {
            params.push({
                label: <CenterBox> <Add size="18" color={this.state.createMode ? '#fff' : '#28a745'} /> ایجاد پلن جدید</CenterBox>,
                activeColor: '#28a745',
                isActive: this.state.createMode,
                onClick: async () => {
                    this.setState({ ...this.state, createMode: !this.state.createMode, selectedRow: null })
                }
            })
        }
        return params;
    }

    render() {

        const CreateWorkspace = React.lazy(() => import('./createWorkspace'))
        const Workspace = React.lazy(() => import('./workspace'))

        return (
            <Grid container sx={{ height: { lg: '100%', xs: 'calc(100% - 48px)' } }}>
                <Grid item lg={3.5} xs={12}>
                    <BlackSection sx={{
                        display: { xs: this.state.createMode || this.state.selectedRow ? 'block' : 'none', lg: 'block' },
                        position: { xs: 'absolute', lg: 'relative' },
                        right: 0, left: 0, bottom: 0, top: 0, zIndex: 100
                    }}>
                        {this.state.createMode ? (
                            <Suspense fallback={<SuspenseLoading />}>
                                <Fade in={true} timeout={500}>
                                    <FitBox>
                                        <CreateWorkspace refresh={this.onChangeDataGrip} close={() => this.setState({ ...this.state, createMode: false })} />
                                    </FitBox>
                                </Fade>
                            </Suspense>
                        ) : this.state.selectedRow ? (
                            <Suspense fallback={<SuspenseLoading />}>
                                <Fade in={true} timeout={500}>
                                    <FitBox>
                                        <Workspace refresh={this.onChangeDataGrip} data={this.state.selectedRow} unselectRow={this.unselectRow} />
                                    </FitBox>
                                </Fade>
                            </Suspense>
                        ) : (
                            <Grow in={true} timeout={800}>
                                <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Box1 size={140} color="#fff" fontWeight={200} />
                                    <Typography dir="rtl" mt={3}>میز کار خالی است !</Typography>
                                </Box>
                            </Grow>
                        )}
                    </BlackSection>
                </Grid>
                <Grid item lg={8.5} xs={12} sx={{ backgroundColor: '#f7f7f8', height: '100%' }}>
                    <PanelDataGrid
                        sx={{ padding: { xs: '4px', lg: '16px' }, paddingTop: '16px !important' }}
                        label="پلن ها"
                        subtitle=''
                        icon={Box1}
                        columns={this.props.seller.type === "seller" ? this.columns.filter(i => i.field !== "net_price") : this.columns}
                        rows={this.props.plans?.data}
                        loading={this.props.get_process}
                        last_page={this.props.plans?.last_page}
                        total_rows={this.props.plans?.total}
                        onChange={this.onChangeDataGrip}
                        onSelectRow={this.selectRow}
                        params={this.getParams()}
                        searchable
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getPlans: (data?: GetPlansData) => dispatch(getPlansAsync(data))
})

const mapStateToProps = (state: AppState) => ({
    plans: state.plans.results,
    get_process: state.plans.get_process,
    seller: state.signin.seller
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Plans))