// import { createTheme } from "@material-ui/core";
// import { ThemeProvider } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles"
import AppBaseRouter from "components/AppBaseRouter";
import Dialog from "components/Dialog";
import PageLoading from "components/PageLoading";
import React, { Component } from "react";
import { ToastContainer } from "react-toastify";

const theme = createTheme({
    typography: {
        fontFamily: ['"IRANYekanX"', "sans-serif"].join(","),
    },
});

interface AppProps { }

export default class App extends Component<AppProps> {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <PageLoading />
                <Dialog />
                <ToastContainer position="top-center" autoClose={6000} rtl />
                <AppBaseRouter />
            </ThemeProvider>
        );
    }
}
