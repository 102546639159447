const api = {
    signin: "/api/v1/auth/login",
    logout: "/api/v1/auth/logout",
    panel_signin: "/api/v1/auth/panel/login",
    check: "/api/v1/auth/check",
    dashboard: "/api/administrator/v1/dashboard/",
    dashboardV2: "/api/administrator/v2/dashboard/",
    users: "/api/administrator/v1/users",
    servers: "/api/administrator/v1/servers",
    settings: "/api/administrator/v1/settings",
    estates: "/api/administrator/v1/estates",
    estate_types: "/api/administrator/v1/estate_types",
    ticket_departments: "/api/administrator/v1/ticket_departments",
    plates: "/api/administrator/v1/plates",
    tickets: "/api/administrator/v1/tickets",
    documents: "/api/administrator/v1/documents",
    plans: "/api/administrator/v1/plans",
    package: "/api/administrator/v1/plans",
    sellers: "/api/administrator/v1/sellers",
    orders: "/api/administrator/v1/orders",
    settlements: "/api/administrator/v1/settlements",
};


export default api as typeof api;