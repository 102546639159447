import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { SxProps, styled } from "@mui/system"
import Rtl from 'components/Rtl'
import withRouter, { Router } from 'helpers/withRouter'
import { FormBuilder } from "material-form-builder"
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { AppState, TypedDispatch } from 'redux/store'
import { AxiosError, AxiosResponse } from 'utils/axios'
import { signinAsync } from './slice'
import { SigninRequestData, SigninResponseData } from './types'

interface SigninProps {
    signin: (data: SigninRequestData) => Promise<any>,
    router: Router<{ a: string }>
}
interface SigninState {
    signin_will_process: boolean
}
class Signin extends Component<SigninProps, SigninState> {
    private builderRef: FormBuilder | undefined | null;

    state: SigninState = {
        signin_will_process: false
    }

    signin = () => {
        const values = this.builderRef?.getValues();
        if (!values?.validation.status) return toast.warning('وارد کردن اطلاعات ورود اجباری می باشد');
        this.setState({ ...this.state, signin_will_process: true })

        this.props.signin(values?.data as SigninRequestData)
            .then((response: AxiosResponse<SigninResponseData>) => {
                toast.success(response.data.message)
                localStorage.setItem('accessToken', response.data.results.access_token)

                setTimeout(() => {
                    this.props.router.navigate('/users')
                    this.setState({ ...this.state, signin_will_process: false })
                }, 1000)
            })
            .catch((error: AxiosError) => {
                toast.error(error.response?.data.message || "عدم اتصال به اینترنت / سرور")
                this.setState({ ...this.state, signin_will_process: false })
            })
    }

    render() {
        return (
            <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 4, paddingTop: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box>
                        <Typography sx={{ fontSize: 30, fontWeight: '600', textAlign: 'center', marginBottom: '16px', }}>ورود به حساب کاربری</Typography>
                        <Typography sx={{ fontSize: 18, fontWeight: '300', textAlign: 'center', marginBottom: '16px' }}>لطفا شناسه ورود و کلمه عبور را وارد نمایید</Typography>
                        <Rtl>
                            <FormBuilder inputs={[
                                {
                                    selector: 'identity',
                                    type: 'text',
                                    label: 'شناسه ورود',
                                    placeholder: 'Username',
                                    fullWidth: true,
                                    variant: 'outlined',
                                    sx: inputStyles,
                                    required: true,
                                    inputProps: { inputMode: 'numeric', dir: 'ltr' },
                                    getMutator: (value) => {
                                        return value && value.replace(/^.*-/, '0')
                                    },
                                },
                                {
                                    selector: 'password',
                                    type: 'password',
                                    label: 'کلمه عبور',
                                    placeholder: 'Password',
                                    fullWidth: true,
                                    required: true,
                                    variant: 'outlined',
                                    sx: inputStyles,
                                    inputProps: { dir: 'ltr' },
                                    onKeyUp: e => {
                                        if (e.key === "Enter") this.signin()
                                    }
                                },
                            ]} ref={el => this.builderRef = el} />
                        </Rtl>

                        <SigninButton disabled={this.state.signin_will_process} fullWidth variant='contained' onClick={this.signin}>
                            {this.state.signin_will_process ? (
                                <CircularProgress size={28} color="inherit" />
                            ) : 'ورود'}
                        </SigninButton>
                    </Box>
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = (state: AppState) => ({})
const mapDispatchToProps = (dispatch: TypedDispatch) => ({
    signin: (data: SigninRequestData) => dispatch(signinAsync(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signin))

// const LogoWrapper = styled(Box)({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//     height: '150px'
// })

// const LogoInner = styled(Box)({
//     backgroundColor: '#21201F',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     width: '340px',
//     height: '150px'
// })

const SigninButton = styled(Button)({
    borderRadius: 0,
    backgroundColor: '#21201F',
    color: '#fff',
    height: '64px',
    "&:hover": {
        backgroundColor: '#32302e',
    }
})

const inputStyles: SxProps = {
    paddingBottom: '32px', '& .MuiOutlinedInput-root': {
        borderRadius: 0, '&.Mui-focused': {
            '& fieldset': { borderColor: '#21201F' }
        }
    }, '& label.Mui-focused': { color: '#21201F' }
}


