import { Box, Chip, Divider, Fade, Grid, IconButton, IconButtonProps, NativeSelect, Pagination, Slide, SxProps, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { CloseCircle, Icon, Refresh, SearchNormal } from "iconsax-react";
import React, { ChangeEvent, Component, Fragment } from "react";
import Rtl from "./Rtl";

export type ChangeDataGrid = {
    page: number;
    limit: number;
    query: string | null;
    filter?: string | null
};

export type DataGridParam = {
    label: string | JSX.Element;
    onDelete?: (event: any) => void;
    onClick?: (event: any) => void;
    isActive?: boolean;
    backgroundColor?: string;
    activeColor?: string;
    disabled?: boolean
};

interface ExtraButton extends IconButtonProps {
    icon?: any
}

interface InternalDataGridProps {
    label: string;
    subtitle: string;
    loading: boolean;
    rows: any;
    columns: GridColDef[];
    last_page?: number;
    total_rows?: number;
    icon: Icon;
    iconColor?: string;
    iconBackgroundColor?: string;
    onChange: (data: ChangeDataGrid) => any;
    onSelectRow?: (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => any;
    params?: DataGridParam[];
    searchable?: boolean,
    sx?: SxProps,
    dateGridProps?: any,
    extraButtons?: ExtraButton[],
    hasAllLimit?: boolean
}

interface IState {
    limit: number;
    page: number;
    query: string | null;
    real_query: string | null;
}

export default class PanelDataGrid extends Component<InternalDataGridProps, IState> {
    state: IState = {
        limit: 10,
        page: 1,
        query: null,
        real_query: null,
    };

    onChangeLimit = async (event: ChangeEvent<HTMLSelectElement>) => {
        const limit = parseInt(event.target.value);
        this.setState({ ...this.state, limit, page: 1 }, () => {
            this.props.onChange({
                limit: this.state.limit,
                page: this.state.page,
                query: this.state.query,
            });
        });

    };

    onChangePage = async (_: any, page: number) => {
        if (this.state.page === page) return null;
        this.setState({ ...this.state, page }, () => {
            this.props.onChange({
                limit: this.state.limit,
                page: this.state.page,
                query: this.state.query,
            });
        });

    };

    onChangeQuery = (e: any) => {
        this.setState({ ...this.state, query: e.target.value || null });
    };

    release = () => {
        if (this.props.onChange) this.props.onChange({
            limit: this.state.limit,
            page: this.state.page,
            query: this.state.query,
        }).catch((err: any) => { });
    };

    clearSearch = () => {
        this.setState({ ...this.state, query: null, page: 1, real_query: null }, () => {
            this.release()
        });
    }

    onKeyupQueryInput = (event: any) => {
        if (event.key === "Enter") {
            this.search()
        }
    }

    search = async () => {
        this.setState({ ...this.state, page: 1, real_query: this.state.query }, () => {
            this.release()
        });
    }

    render() {
        return (
            <Box sx={{ padding: "32px", height: "100%", ...(this.props.sx || {}) }}>
                <Box sx={{ backgroundColor: "#fff", borderRadius: 2, height: "70px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: 2 }}>
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', mr: 1, overflow: 'auto' }}>
                        {this.props.params?.map((param: DataGridParam, key) => (
                            <Slide direction="down" in={true} key={key} timeout={500} style={{ transitionDelay: `${key * 100}ms` }}>
                                <Chip sx={{
                                    mr: 1,
                                    backgroundColor: param.isActive ? param.activeColor : 'transparent',
                                    borderColor: param.isActive ? 'transparent' : 'silver',
                                    color: param.isActive ? '#fff' : '#303030',
                                    '&:hover': {
                                        backgroundColor: param.isActive ? param.activeColor + " !important" : 'transparent',
                                    }
                                }} label={param.label} deleteIcon={<CloseCircle
                                    size="23"
                                    color="#ff8a65"
                                />} variant="outlined" onDelete={param.onDelete} onClick={param.onClick || undefined} disabled={param.disabled} />
                            </Slide>
                        ))}

                        {/* <Tooltip title="فیلتر" arrow placement='top'>
                            <IconButton onClick={() => { }}>
                                <DocumentFilter size="28" color="#21201F" />
                            </IconButton>
                        </Tooltip> */}
                    </Box>
                    <Typography sx={{ fontWeight: "600" }}>{this.props.label}</Typography>
                </Box>
                <Box
                    sx={{
                        padding: 2,
                        marginTop: 2,
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        overflow: 'hidden',
                        height: 64,
                        backgroundColor: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ backgroundColor: "#f7f7f8", borderRadius: '6px', overflow: 'hidden', display: "flex", alignItems: "center", justifyContent: "center", height: 36 }}>
                            {this.props.searchable ? (
                                <Fragment>
                                    <Box sx={{ width: 36 * 2, overflow: 'hidden', display: 'flex' }}>
                                        <Slide direction="right" in={this.state.query == null && this.state.real_query == null} unmountOnExit>
                                            <Tooltip enterDelay={700} enterNextDelay={2000} placement="left" title="بار‌گذاری مجدد" arrow>
                                                <IconButton onClick={this.clearSearch} sx={{ height: 36, width: 36 }}>
                                                    <Refresh color="#402b3a" />
                                                </IconButton>
                                            </Tooltip>
                                        </Slide>
                                        <Slide direction="left" in={this.state.query != null || this.state.real_query != null} unmountOnExit>
                                            <Tooltip enterDelay={700} enterNextDelay={2000} placement="left" title="جستجو" arrow>
                                                <IconButton onClick={this.search} sx={{ height: 36, width: 36, marginLeft: 0 }}>
                                                    <SearchNormal color="#402b3a" />
                                                </IconButton>
                                            </Tooltip>
                                        </Slide>

                                        <Fade in={this.state.real_query != null}>
                                            <Tooltip enterDelay={700} enterNextDelay={2000} placement="top" title="پاک کردن" arrow>
                                                <IconButton onClick={this.clearSearch} sx={{ height: 36, width: 36 }}>
                                                    <CloseCircle color="#402b3a" />
                                                </IconButton>
                                            </Tooltip>
                                        </Fade>

                                    </Box>
                                    <input
                                        placeholder="جستجو"
                                        onChange={this.onChangeQuery}
                                        autoComplete="new-password"
                                        value={this.state.query || ""}
                                        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => e.currentTarget.select()}
                                        onKeyUp={this.onKeyupQueryInput}
                                        style={{ backgroundColor: "#f7f7f8", fontFamily: 'IRANYekanX', boxSizing: "border-box", border: "unset", outline: "unset", padding: 8, paddingRight: 8, fontSize: 14, width: 180 }}
                                        dir="rtl"
                                    />
                                </Fragment>
                            ) : null}
                        </Box>
                        {this.props.extraButtons ? this.props.extraButtons.map((btn, key) => (
                            <IconButton key={key} {...btn}>{btn.icon}</IconButton>
                        )) : null}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ marginRight: 2 }}>{this.props.subtitle}</Typography>
                        <Box sx={{ backgroundColor: this.props.iconBackgroundColor || "#fff", height: 38, width: 38, borderRadius: '50%', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                            <this.props.icon size={26} color={this.props.iconColor || "#33212e"} />
                        </Box>
                    </Box>
                </Box>
                <Rtl sx={{ height: "calc(100% - 214px)", borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', overflow: 'hidden' }}>
                    <Box sx={{ height: "100%" }}>
                        <DataGrid
                            sx={{
                                "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                                    outline: "none"
                                },
                                height: '100%',
                                borderRadius: 0, backgroundColor: "#fff", border: 'unset', borderTop: "1px solid #f7f7f8",
                                "& .MuiDataGrid-cell, & .MuiDataGrid-withBorder, & .MuiDataGrid-columnHeaders": { borderColor: "#f7f7f8" }
                            }}
                            rows={this.props.rows || []}
                            loading={this.props.loading}
                            columns={this.props.columns}
                            hideFooter={true}
                            showCellRightBorder
                            columnVisibilityModel={Divider}
                            showColumnRightBorder
                            onRowSelectionModelChange={this.props.onSelectRow}
                            localeText={{ noRowsLabel: "گشتم نبود، نگرد نیست !" }}
                            {...this.props.dateGridProps}
                        />
                    </Box>
                </Rtl>
                <Rtl
                    sx={{
                        backgroundColor: "#fff",
                        padding: '6px',
                        borderRadius: 2,
                        height: { lg: 48, xs: 96 },
                        marginTop: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Grid container sx={{ userSelect: 'none' }}>
                        <Grid item lg={3} xs={6} sx={{ display: "flex", alignItems: "center", justifyContent: 'flex-start' }} order={{ lg: 1, xs: 1 }}>

                            <Box sx={{ display: "flex", alignItems: "center", borderRadius: 2, }}>
                                <Typography>در صفحه</Typography>
                                <NativeSelect
                                    disabled={this.props.loading}
                                    defaultValue={10}
                                    onChange={this.onChangeLimit}
                                    sx={{
                                        backgroundColor: "#f7f7f8",
                                        marginLeft: 1,
                                        borderRadius: 2,
                                        fontSize: 15, fontWeight: 500,
                                        height: 38,
                                        paddingTop: '3px',
                                        "& select": { paddingLeft: "4px" },
                                        "&::before": { content: "unset" },
                                        "&::after": { content: "unset" },
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    {this.props.hasAllLimit ? (
                                        <option value={999999}>همه</option>
                                    ) : null}
                                </NativeSelect>
                            </Box>
                        </Grid>
                        <Grid item lg={6} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: { xs: 1, lg: 0 } }} order={{ lg: 2, xs: 3 }}>

                            <Pagination
                                disabled={this.props.loading}
                                onChange={this.onChangePage}
                                page={this.state.page}
                                sx={{
                                    "& button": {
                                        borderRadius: 2,
                                        paddingTop: '5px',
                                        color: '#343434',

                                    },
                                    "& button:hover": {
                                        backgroundColor: '#ff8a6580 !important',
                                    },
                                    "& li:first-child button": {
                                        paddingTop: 0,
                                    },
                                    "& li:last-child button": {
                                        paddingTop: 0,
                                    },
                                    '& button.Mui-selected': {
                                        backgroundColor: '#ff8a65 !important',
                                        fontWeight: 'bold'
                                    }
                                }}
                                count={this.props.last_page || 1}
                            />
                        </Grid>
                        <Grid item lg={3} xs={6} sx={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }} order={{ lg: 3, xs: 2 }}>

                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography>تعداد کل</Typography>
                                <Typography sx={{ backgroundColor: "#f7f7f8", padding: 1, paddingTop: '10px', marginLeft: 1, borderRadius: 2, fontSize: 15, fontWeight: 500, width: 38, height: 38, textAlign: 'center' }}>{this.props.total_rows || 0}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Rtl>
            </Box>
        );
    }
}
