import { AnyAction, configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "containers/dashboard/slice";
import ordersSlice from "containers/orders/slice";
import packageSlice from "containers/packages/slice";
import plansSlice from "containers/plans/slice";
import sellersSlice from "containers/sellers/slice";
import serversSlice from "containers/servers/slice";
import settlementsSlice from "containers/settlements/slice";
import signinSlice from "containers/signin/slice";
import usersSlice from "containers/users/slice";
import logger from "redux-logger";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import generalSlice from "./slice";

let middleware: any = [thunk];
if (process.env.REACT_APP_REDUX_LOGGER === "true" || process.env.REACT_APP_REDUX_LOGGER === "1") middleware.push(logger);

const store = configureStore({
    reducer: {
        general: generalSlice.reducer,
        signin: signinSlice.reducer,
        users: usersSlice.reducer,
        servers: serversSlice.reducer,
        plans: plansSlice.reducer,
        packages: packageSlice.reducer,
        sellers: sellersSlice.reducer,
        orders: ordersSlice.reducer,
        dashboard: dashboardSlice.reducer,
        settlements: settlementsSlice.reducer,
    },
    middleware,
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type TypedDispatch = ThunkDispatch<AppState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AnyAction>;

export default store;
