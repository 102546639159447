import { Box, CircularProgress, Fade, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { Component } from "react";
import { connect } from "react-redux";
import { AppState } from "redux/store";
import CenterBox from "./CenterBox";

const LoadingWrapper = styled(Box)({
    position: "fixed",
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: 9999,
    backgroundColor: "rgba(0,0,0,0.5)",
});

const LoadingInner = styled(CenterBox)({
    height: "100%",
    width: "100%",
    flexDirection: "column",
});

const Loading = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '16px',
    paddingTop: '24px',
    borderRadius: '8px',
    backgroundColor: '#ffffffb3'
})

interface PageLoadingProps extends ReturnType<typeof mapStateToProps> { }

class PageLoading extends Component<PageLoadingProps> {
    render() {
        return (
            <Fade in={this.props.loading_status ? true : false}>
                <LoadingWrapper>
                    <LoadingInner>
                        <Loading>
                            <CircularProgress color="inherit" size={30} />
                            <Typography sx={{ mt: 2, height: 40 }}>
                                ... {typeof this.props.loading_text === "string" ? this.props.loading_text : "درحال پردازش"}
                            </Typography>
                        </Loading>
                    </LoadingInner>
                </LoadingWrapper>
            </Fade>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    loading_text: state.general.loading_text,
    loading_status: state.general.loading_status,
});

export default connect(mapStateToProps)(PageLoading);
