import { Button, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';

import { Box, styled } from "@mui/system";
import CenterBox from "components/CenterBox";
import DarkMode from "components/DarkMode";
import Rtl from "components/Rtl";
import { Seller } from "containers/sellers/types";
import { e2p, numberFormat, setPageLoadingTo, sum } from "helpers/general";
import { toJpeg } from "html-to-image";
import { Add, Clock, DocumentDownload, Profile2User, ShieldTick, TickCircle, Trash, User } from "iconsax-react";
import moment from "jalali-moment";
import React, { Component } from "react";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { setDialogTo } from "redux/slice";
import { AppState } from "redux/store";
import { SetDialogPayload } from "redux/types";
import { ObjectLiteral } from "types/general";
import { AxiosError, AxiosResponse } from "utils/axios";
import { deleteOrdersAsync, settlementOrdersAsync } from "./slice";
import { Order } from "./types";




// const StyledLink = styled(Link)({
//     display: "inline-block",
// });

interface OrdersWorkSpaceProps extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
    data: any[];
    unselectRow: () => void;
    refresh: () => void;
}

class OrdersWorkSpace extends Component<OrdersWorkSpaceProps> {

    deleteOrders = (data: any) => () => {
        if (!this.props.data) return null;
        this.props.setDialogTo({
            title: 'حذف صورتحساب ها ',
            content: () => {
                return <Box>
                    <Typography sx={{}}>آیا از حذف صورتحساب های انتخاب شده به مبلغ {numberFormat(data.total_amount)} ریالءء اطمینان دارید ؟</Typography>
                </Box>
            },
            buttons: [{
                title: 'تایید / حذف',
                color: 'error',
                variant: 'contained',
                onClick: () => {
                    if (!this.props.data) return false;

                    setPageLoadingTo('در حال حذف')

                    this.props.deleteOrder(this.props.data).then((response: AxiosResponse) => {
                        this.props.setDialogTo(false)
                        this.props.refresh()
                        toast.success('رکورد های مورد نظر با موفقیت حذف شدند')
                        setPageLoadingTo(false)
                    }).catch((error: AxiosError) => {
                        this.props.setDialogTo(false)
                        toast.error(error.response?.data.message || "خطا در ارتباط با شبکه")
                        setPageLoadingTo(false)
                    })
                }
            }]
        })
    }

    settlement = (data: any) => () => {
        this.props.setDialogTo({
            title: 'تسویه حساب',
            content: () => {
                return <Box>
                    <Typography>
                        آیا از تسویه فاکتور های انتخاب شده به مبلغ {numberFormat(data.total_amount)} ریالءء اطمینان دارید ؟
                    </Typography>

                    {this.props.seller.type === "manager" ? (
                        <Typography sx={{ mt: 2, fontSize: 14 }}>
                            توجه داشته باشید که در صورت تسویه فاکتور های انتخاب شده، هزینه ارائه سرویس به مبلغ {numberFormat(data.service_amount)} ریالءء به عنوان بدهی برای شما ثبت می گردد
                        </Typography>
                    ) : null}
                </Box>
            },
            buttons: [
                {
                    title: 'تایید / تسویه',
                    color: 'primary',
                    variant: 'contained',
                    onClick: () => {
                        this.props.setDialogTo(false)
                        setPageLoadingTo('درحال تسویه فاکتور های انتخاب شده')

                        this.props.settlementOrders(this.props.seller.id, this.props.data).then((response: AxiosResponse) => {
                            setPageLoadingTo(false)
                            this.props.refresh();
                            toast.success('تسویه با موفقیت انجام شد')
                        }).catch((error: AxiosError) => {
                            setPageLoadingTo(false)
                            toast.error(error.response?.data.message || "خطا در تسویه فاکتور ها")
                        })
                    }
                }
            ]
        })

    }

    getOrderType = (type: Order['type']) => {
        switch (type) {
            case "subscribed": return "اشتراک جدید";
            case "renewal": return "تمدید";
            case "add_package": return "بسته افزایشی";
            case "delete": return "حذف کاربر";
            default: return "";
        }
    }

    showBill = (data: Order[]) => () => {
        let items: { identity: string, type: Order['type'], plan_title: string, fii: number, count: number }[] = [];
        data.forEach(order => {
            const plan: any = order.plan || order.account?.plan;

            const identity = plan?.id + "_" + order.type + "_" + order.amount;
            const findItem = items.find(i => i.identity === identity)
            if (findItem) {
                findItem.count += 1;
            } else {
                if (plan) {
                    items.push({
                        identity: identity,
                        type: order.type,
                        plan_title: plan.type === "plan" ? `${plan.expire} روز / ${plan.traffic} گیگ` : `افزایشی ${plan.traffic} گیگ`,
                        fii: order.amount,
                        count: 1
                    })
                }
            }
        })

        items = items.sort((a, b) => {
            return a.fii > b.fii ? 1 : -1
        })


        this.props.setDialogTo({
            title: 'پیش فاکتور',
            width: 980,
            content: () => {
                const datetime = moment().format('DD MMMM YYYY');
                const seller_name = data[0].seller.full_name;
                return (
                    <Box>
                        <Box id="seller_bill" sx={{ backgroundColor: '#F5F7FB', padding: 2 }}>
                            <CenterBox sx={{ justifyContent: 'space-between', mb: 2, alignItems: 'flex-end' }}>
                                <Box>
                                    <CenterBox sx={{ justifyContent: 'flex-start', mb: 1 }}>
                                        <CenterBox sx={{ paddingRight: 1 }}>
                                            <User size="24" color="#BF9B30" />
                                            <Typography sx={{ fontWeight: 300, paddingLeft: '4px', color: '#606060' }}>فروشنده</Typography>
                                        </CenterBox>
                                        <Typography sx={{ fontWeight: 500, fontSize: 16, color: '#333333' }}>{seller_name}</Typography>
                                    </CenterBox>
                                    <CenterBox sx={{ justifyContent: 'flex-start' }}>
                                        <CenterBox sx={{ paddingRight: 1 }}>
                                            <Clock size="24" color="#6EBCFF" />
                                            <Typography sx={{ fontWeight: 300, paddingLeft: '4px', color: '#606060' }}>تاریخ</Typography>
                                        </CenterBox>
                                        <Typography sx={{ fontWeight: 500, fontSize: 16, color: '#333333' }}>{e2p(datetime)}</Typography>
                                    </CenterBox>
                                </Box>
                                <CenterBox>
                                    <Box sx={{
                                        height: '70px',
                                        width: '70px',
                                        padding: '8px',
                                        backgroundColor: '#edeff3',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '50%',
                                    }}>
                                        {this.props.seller.logo ? (
                                            <img style={{ height: '100%' }} src={this.props.seller.logo} alt="OwlLogoSrc" />
                                        ) : (
                                            <img style={{ height: '100%' }} src={this.props.seller.branch.logo} alt="OwlLogoSrc" />
                                        )}
                                    </Box>
                                </CenterBox>

                                {/* <CenterBox sx={{ width: '33.33%', justifyContent: 'flex-end' }}>
                            <Typography>
                                OWL VPN
                            </Typography>
                        </CenterBox> */}

                            </CenterBox>


                            {/* <Typography sx={{ width: '100%', textAlign: 'center', mt: 2, color: '#494949' }}>مبلغ قابل پرداخت</Typography> */}
                            {/* <Typography sx={{ width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: 32, mt: 0, color: '#494949' }}>{e2p(numberFormat((traffic_amount + subscription_amount)))} ریالءء</Typography> */}

                            <TableContainer component={Paper} sx={{
                                borderRadius: '20px',
                                // padding: 3,
                                // mt: '2px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
                                position: 'relative',
                                zIndex: 2
                            }}>
                                <Table sx={{ minWidth: 700 }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center">ردیف</StyledTableCell>
                                            <StyledTableCell align="center">پلن/بسته</StyledTableCell>
                                            <StyledTableCell align="center">نوع</StyledTableCell>
                                            <StyledTableCell align="center">فی</StyledTableCell>
                                            <StyledTableCell align="center">تعداد</StyledTableCell>
                                            <StyledTableCell align="center">مبلغ قابل پرداخت</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((item, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{e2p(index + 1)}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row" align="center">{e2p(item.plan_title)}</StyledTableCell>
                                                <StyledTableCell component="th" scope="row" align="center">{this.getOrderType(item.type)}</StyledTableCell>
                                                <StyledTableCell align="center">{e2p(numberFormat(item.fii))} ریالءء</StyledTableCell>
                                                <StyledTableCell align="center">{e2p(item.count)} عدد</StyledTableCell>
                                                <StyledTableCell align="center" sx={{ fontWeight: '600 !important' }}>{e2p(numberFormat(item.count * item.fii))} ریالءء</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CenterBox sx={{ flexDirection: 'column' }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#fff',
                                    borderRadius: '0px 0px 20px 20px',
                                    padding: '8px 16px',
                                    paddingTop: '20px',
                                    marginTop: '-4px',
                                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                    width: '60%',
                                    position: 'relative',
                                    zIndex: 1
                                }}>
                                    <CenterBox sx={{ justifyContent: 'space-between', width: '100%', flex: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Profile2User size="24" color="#FF8A65" />
                                            <Typography sx={{ marginLeft: '4px', color: '#606060' }}> جمع تعداد: </Typography>
                                        </Box>
                                        <Typography sx={{ color: '#333333' }}>{e2p(numberFormat(data.length))} عدد</Typography>
                                    </CenterBox>
                                    <CenterBox sx={{ justifyContent: 'space-between', width: '100%', flex: 1, mt: 2 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TickCircle size="24" color="#23A16E" />
                                            <Typography sx={{ marginLeft: '4px', color: '#606060' }}> جمع مبلغ قابل پرداخت: </Typography>
                                        </Box>
                                        <Typography sx={{ fontWeight: '600', fontSize: 24, mt: 0, color: '#23A16E' }}>{e2p(numberFormat(sum(data, 'amount')))}
                                            <span style={{ fontSize: 16, marginRight: 4 }}>ریالءء</span>
                                        </Typography>
                                    </CenterBox>
                                </Box>
                                <CenterBox sx={{ justifyContent: 'space-between', mt: 2 }}>
                                    <Typography sx={{ color: '#858585', fontSize: 13 }}>
                                        {this.props.seller.brand ? this.props.seller.brand : this.props.seller.branch.brand}
                                    </Typography>
                                </CenterBox>
                            </CenterBox>
                        </Box>
                        <Tooltip arrow placement="top" title="دریافت تصویر فاکتور">
                            <IconButton onClick={this.getSellerBillImage({ datetime, seller_name })}><DocumentDownload color="#FF8A65" size={34} /></IconButton>
                        </Tooltip>
                    </Box>
                )
            }
        })
    }

    getSellerBillImage = (data: any) => () => {
        setPageLoadingTo('درحال دانلود')
        const element = document.getElementById('seller_bill');
        if (!element) return null;
        toJpeg(element, { quality: 1, pixelRatio: 3 })
            .then((dataUrl: any) => {
                var link = document.createElement('a');
                link.download = data.seller_name + '_' + data.datetime + '.jpeg';
                link.href = dataUrl;
                link.click();
                setPageLoadingTo(false)
            }).catch(() => {
                toast.error('خطا در دانلود فایل')
                setPageLoadingTo(false)
            });
    }

    calcManagerProfit = (row: any): number => {
        // const managerOrders = row.orders.filter((i: Order) => i.seller.id === row.manager.id)
        const restOrders = row.orders.filter((i: Order) => i.seller.id !== row.manager.id)

        const total_amount = sum(restOrders, 'amount');
        const net_amount = sum(restOrders, 'net_amount');
        const total_profit = total_amount - net_amount;
        let profit = 0;
        switch (row.manager.profit_type) {
            case "percent":
                profit = (total_profit / 100) * (row.manager.profit_value || 0)
                break;
            case "fix":
                profit = restOrders.length * (row.manager.profit_value || 0)
                break;
            default: break;
        }
        return profit;
    }

    calcManagerDebt = (row: any): number => {
        const managerOrders = row.orders.filter((i: Order) => i.seller.id === row.manager.id)
        const restOrders = row.orders.filter((i: Order) => i.seller.id !== row.manager.id)

        const total_amount = sum(restOrders, 'amount');
        const net_amount = sum(restOrders, 'net_amount');
        const total_profit = total_amount - net_amount;

        let profit = 0;
        switch (row.manager.profit_type) {
            case "percent":
                profit = (total_profit / 100) * (row.manager.profit_value || 0)
                break;
            case "fix":
                profit = restOrders.length * (row.manager.profit_value || 0)
                break;
            default: break;
        }
        return (total_amount - profit) + sum(managerOrders, 'amount');
    }

    calculation = (items: Order[]) => {
        const bySellers: ObjectLiteral[] = [];
        const byManager: ObjectLiteral[] = [];

        items.forEach((order) => {
            const exists = bySellers.find(i => i.seller.id === order.seller_id)

            if (!exists) {
                bySellers.push({
                    seller: order.seller,
                    orders: [order],
                });
            } else {
                exists.orders.push(order)
            }

            let manager: Seller | null = null;
            if (order.seller.type === "manager") {
                manager = order.seller;
            } else {
                const parent = order.seller.parent
                if (parent?.type === "manager") {
                    manager = parent;
                }
            }

            if (manager) {
                const mngExists = byManager.find(i => i.manager.id === manager?.id)
                if (!mngExists) {
                    byManager.push({
                        manager,
                        orders: [order],
                    });
                } else {
                    mngExists.orders.push(order)
                }
            }

        })


        return {
            sellers: bySellers,
            managers: byManager,
        }
    }

    showManagerDebtDetails = (row: any) => () => {
        const managerOrders = row.orders.filter((i: Order) => i.seller.id === row.manager.id)
        const restOrders = row.orders.filter((i: Order) => i.seller.id !== row.manager.id)

        const total = numberFormat(sum(restOrders, 'amount'));
        const managerTotal = numberFormat(sum(managerOrders, 'amount'));
        const net_amount = numberFormat(sum(restOrders, 'net_amount'));
        const debt = numberFormat(this.calcManagerDebt(row));
        const manager_profit = numberFormat(this.calcManagerProfit(row));

        this.props.setDialogTo({
            title: <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>نمایش جزئیات بدهی {row.manager.full_name}</Typography>,
            content: () => {
                return <Box>
                    <Row>
                        <Typography>تعداد فاکتور های زیر مجموعه</Typography>
                        <Typography>{restOrders.length} <span>عدد</span></Typography>
                    </Row>

                    <Row>
                        <Typography>مبلغ کل</Typography>
                        <Typography>{total} <span>ریالءء</span></Typography>
                    </Row>
                    <Row>
                        <Typography>هزینه تمام شده</Typography>
                        <Typography>{net_amount} <span>ریالءء</span></Typography>
                    </Row>
                    <Row>
                        <Typography>سود خالص</Typography>
                        <Typography>{numberFormat(sum(row.orders, 'profit_amount'))} <span>ریالءء</span></Typography>
                    </Row>
                    <Row>
                        <Typography>درصد شراکت</Typography>
                        <Typography>{row.manager.profit_value} <span>%</span></Typography>
                    </Row>
                    <Row>
                        <Typography>سود {row.manager.full_name}</Typography>
                        <Typography sx={{ color: '#4f772d' }}>{manager_profit} <span>ریالءء</span></Typography>
                    </Row>
                    <Row>
                        <Typography>تعداد فروش شخصی</Typography>
                        <Typography>{managerOrders.length} <span>عدد</span></Typography>
                    </Row>
                    <Row>
                        <Typography>مبلغ فروش شخصی</Typography>
                        <Typography>{managerTotal} <span>ریالءء</span></Typography>
                    </Row>
                    <Row>
                        <Typography>بدهی</Typography>
                        <Typography sx={{ color: "#ed6c02" }}>{debt} <span>ریالءء</span></Typography>
                    </Row>
                    <Divider sx={{ mt: 1 }} />
                    <Box sx={{ textAlign: 'center', mt: 1 }}>
                        <Typography sx={{ mb: 1 }}>میزان مبلغ بدهی به این صورت محاسبه می شود:</Typography>
                        <Typography>( مبلغ کل - سود منیجر ) + مبلغ فروش شخصی = مبلغ بدهی</Typography>
                        <Typography>( {total} - {manager_profit} ) + {managerTotal} = {debt}</Typography>
                    </Box>
                </Box>
            }
        })
    }

    render() {
        const items = (this.props.orders?.data || []).filter(i => this.props.data.indexOf(i.id) > -1)
        const total_amount = sum(items, 'amount');
        // const net_amount = sum(items, 'net_amount');
        // const total_profit = total_amount - net_amount;
        // const sellers = [...new Set(items.map(i => i.seller.id))]
        // const sellersCount = sellers.length

        // let sellerProfit = 0;
        // switch (this.props.seller.profit_type) {
        //     case "percent":
        //         sellerProfit = (total_profit / 100) * (this.props.seller.profit_value || 0)
        //         break;
        //     case "fix":
        //         sellerProfit = items.length * (this.props.seller.profit_value || 0)
        //         break;
        //     default: break;

        // }

        const calc = this.calculation(items)
        const bySellers = calc.sellers;
        const byManager = calc.managers;

        return (
            <DarkMode>
                <Box sx={{ height: "100%" }}>
                    <Header>
                        <Tooltip arrow title="بستن">
                            <IconButton onClick={this.props.unselectRow}>
                                <Add style={{ transform: 'rotate(45deg)' }} size="38" color="#FF8A65" />
                            </IconButton>
                        </Tooltip>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 25, color: '#FF8A65' }}>صورتحساب</Typography>
                    </Header>
                    <Scrollbars
                        style={{ width: "100%", height: "calc(100% - 148px)" }}
                        renderTrackVertical={(props) => <div {...props} className="track-vertical" />}
                        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                    >
                        <Rtl sx={{ mt: 0 }}>
                            {this.props.seller.type !== "seller" ? (
                                <Box>
                                    <TableContainer component={Paper} sx={{ boxShadow: 'unset' }} >
                                        <Table sx={{}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>فروشنده</TableCell>
                                                    <TableCell width={100} align="center">تعداد</TableCell>
                                                    <TableCell width={100} align="right">مبلغ (ریالءء)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {items.length > 0 ? bySellers.map((row) => (
                                                    <TableRow
                                                        key={row.seller.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.seller.full_name}
                                                        </TableCell>
                                                        <TableCell align="center">{row.orders.length}</TableCell>
                                                        <TableCell align="right" padding="none">
                                                            <Tooltip arrow placement="right" title="نمایش فاکتور" enterDelay={1000} enterNextDelay={5000}>
                                                                <Button sx={{ mr: 1 }} onClick={this.showBill(row.orders)} color="info">{numberFormat(sum(row.orders, 'amount'))}</Button>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                )) : null}
                                            </TableBody>
                                            {items.length < 1 ? (
                                                <caption style={{ width: '100%', textAlign: 'center' }}>هیچ رکوردی انتخاب نشده</caption>
                                            ) : null}
                                        </Table>
                                    </TableContainer>
                                    {/* <Divider /> */}
                                    {byManager.length ? (
                                        <TableContainer component={Paper} sx={{ boxShadow: 'unset', mt: 2 }} >
                                            <Table sx={{}} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>منیجیر</TableCell>
                                                        <TableCell width={100} align="center">بدهی (ریالءء)</TableCell>
                                                        <TableCell width={100} align="right">سود (ریالءء)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {byManager.map(row => (
                                                        <TableRow
                                                            key={row.manager.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.manager.full_name}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Tooltip title="نمایش جزئیات" arrow placement="right">
                                                                    <Button onClick={this.showManagerDebtDetails(row)}>
                                                                        {numberFormat(this.calcManagerDebt(row))}
                                                                    </Button>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography sx={{ color: '#23a16e', fontWeight: 'bold' }}>
                                                                    {numberFormat(this.calcManagerProfit(row))}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>

                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : null}
                                </Box>
                            ) : (
                                <Box sx={{ p: 2 }}>
                                    <Typography sx={{ fontSize: 20, textAlign: 'center', mb: 2 }}>جزئیات صورتحساب ها</Typography>
                                    <BlackRow>
                                        <Typography>تعداد انتخاب شده</Typography>
                                        <Typography>{items.length} <span>عدد</span></Typography>
                                    </BlackRow>
                                    <BlackRow>
                                        <Typography>مجموع مبلغ</Typography>
                                        <Typography>{numberFormat(sum(items, 'amount'))} <span>ریالءء</span></Typography>
                                    </BlackRow>
                                </Box>
                            )}

                        </Rtl>
                    </Scrollbars>
                    <Box sx={{ p: 2 }}>
                        {this.props.seller.type !== "seller" ? (
                            <CenterBox>
                                {this.props.seller.type === "super" ? (
                                    <Button
                                        disabled={this.props.data.length < 1}
                                        onClick={this.deleteOrders({
                                            total_amount: total_amount,
                                        })}
                                        color="error"
                                        sx={{ height: 48, mr: 2 }}
                                        variant="contained"
                                        disableElevation>
                                        <Trash size="28" color="#fff" />
                                    </Button>
                                ) : null}
                                <Button
                                    disabled={this.props.data.length < 1}
                                    onClick={this.settlement({
                                        total_amount: total_amount,
                                        service_amount: 0
                                    })}
                                    color="success"
                                    sx={{ height: 48 }}
                                    variant="contained"
                                    fullWidth
                                    startIcon={
                                        <CenterBox>
                                            <Typography>ریالءء</Typography>
                                            <Typography sx={{ mr: 1, ml: 1, fontSize: 16, fontWeight: 'bold', letterSpacing: 1, pt: '3px', flex: 1 }}>{numberFormat(total_amount)} </Typography>
                                            <ShieldTick size={28} />
                                        </CenterBox>
                                    }
                                    disableElevation>تسویه</Button>

                            </CenterBox>
                        ) : (
                            <Button
                                disabled={this.props.data.length < 1}
                                onClick={this.showBill(items)}
                                color="info"
                                sx={{ height: 48 }}
                                variant="contained"
                                fullWidth
                                disableElevation>نمایش فاکتور</Button>
                        )}
                    </Box>
                </Box>
            </DarkMode>
        );
    }
}


const mapStateToProps = (state: AppState) => ({
    delete_process: state.users.delete_process,
    orders: state.orders.results,
    seller: state.signin.seller
})

const mapDispatchToProps = (dispatch: any) => ({
    deleteOrder: (orders_id: number[]) => dispatch(deleteOrdersAsync(orders_id)),
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),
    settlementOrders: (seller_id: number, data: number[]) => dispatch(settlementOrdersAsync(seller_id, data))
})

const Row = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
    overflow: 'hidden',
    position: 'relative',
    '& p:first-child': {
        fontWeight: 400,
        fontSize: 15,
        color: '#343434',
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 1,
        paddingRight: 8,
    },
    '& p:last-child': {
        fontWeight: 500,
        fontSize: 17,
        letterSpacing: 1,
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 1,
        paddingLeft: 8,
        '& > span': {
            fontSize: 14,
            letterSpacing: 0,
            fontWeight: 300,
            color: '#404040'
        }
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        left: 0,
        borderBottom: '1px dashed #e1e1e1'
    }
})

const BlackRow = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
    overflow: 'hidden',
    position: 'relative',
    '& p:first-child': {
        fontWeight: 400,
        fontSize: 15,
        color: '#e9e9e9',
        backgroundColor: '#21201F',
        position: 'relative',
        zIndex: 1,
        paddingRight: 8,
    },
    '& p:last-child': {
        fontWeight: 500,
        fontSize: 17,
        letterSpacing: 1,
        backgroundColor: '#21201F',
        position: 'relative',
        zIndex: 1,
        paddingLeft: 8,
        '& > span': {
            fontSize: 14,
            letterSpacing: 0,
            fontWeight: 300,
            color: '#efefef'
        }
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        left: 0,
        borderBottom: '1px dashed #e1e1e1'
    }
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: '#bfbfbf',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontWeight: 500,
    },
    color: '#333333',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Header = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
    paddingBottom: 0,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersWorkSpace);