import { Box, CircularProgress, Divider, Grid, Grow, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import {
    ArcElement,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    Tooltip
} from 'chart.js';
import CenterBox from 'components/CenterBox';
import { e2p, numberFormat } from 'helpers/general';
import React, { Component, Fragment } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { setDialogTo } from 'redux/slice';
import { AppState } from 'redux/store';
import { SetDialogPayload } from 'redux/types';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.font.family = "IRANYekanX";

interface IProps extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
    data: any,
    mode: 'daily' | 'monthly'
}

const DCLRow = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    '&:last-child': {
        marginBottom: 0
    },
    "& > div:first-child": {
        display: 'flex',
        alignItems: 'center',
        // justifyC
        '& > div': {
            height: 25,
            width: 25,
            // backgroundColor: 'silver',
            borderRadius: 5,
        },
        "& > p": {
            position: 'relative',
            top: 2,
            paddingRight: 8,
            width: 120,
            fontWeight: 300,
            color: '#303030'
        }
    },
    '& > p:last-child': {
        fontWeight: 700,
        textAlign: 'left',
        width: '40%',
        position: 'relative',
        top: 2,

    }
})

const DCLCount = styled(Typography)({
    width: 80,
    fontSize: 15,
    fontWeight: 500,
    textAlign: 'left',
    position: 'relative',
    top: 2,
})

class MainChart extends Component<IProps> {
    onClickData = (row: any) => () => {
        const sumOfData = row.detail.count.subscribed + row.detail.count.renewal + row.detail.count.add_package;
        this.props.setDialogTo({
            title: (<Typography sx={{ fontSize: 18, fontWeight: 500, textAlign: 'center', borderBottom: '1px solid #e9e9e9', pb: 1 }}>جزئیات عملکرد {e2p(row.label_3)}</Typography>),
            width: sumOfData > 0 ? 700 : 400,
            content: () => {
                const totalCount = row.detail.count.subscribed + row.detail.count.renewal + row.detail.count.add_package;
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={sumOfData > 0 ? 7 : 12} order={{ xs: 2, md: 1 }}>
                            <DCLRow>
                                <Box>
                                    <Box sx={{ backgroundColor: '#2a9d8f' }} />
                                    <Typography>اشتراک جدید:</Typography>
                                </Box>
                                <DCLCount>{e2p(numberFormat(row.detail.count.subscribed))} عدد</DCLCount>
                                <Typography>{e2p(numberFormat(row.detail.amount.subscribed))} ریالءء</Typography>
                            </DCLRow>
                            <DCLRow>
                                <Box>
                                    <Box sx={{ backgroundColor: '#e9c46a' }} />
                                    <Typography>تمدید:</Typography>
                                </Box>
                                <DCLCount>{e2p(numberFormat(row.detail.count.renewal))} عدد</DCLCount>
                                <Typography>{e2p(numberFormat(row.detail.amount.renewal))} ریالءء</Typography>
                            </DCLRow>
                            <DCLRow>
                                <Box>
                                    <Box sx={{ backgroundColor: '#e76f51' }} />
                                    <Typography>بسته افزایشی:</Typography>
                                </Box>
                                <DCLCount>{e2p(numberFormat(row.detail.count.add_package))} عدد</DCLCount>
                                <Typography>{e2p(numberFormat(row.detail.amount.add_package))} ریالءء</Typography>
                            </DCLRow>

                            <DCLRow>
                                <Box>
                                    <Box sx={{ backgroundColor: '#415a77' }} />
                                    <Typography>کل:</Typography>
                                </Box>
                                <DCLCount>{e2p(numberFormat(totalCount))} عدد</DCLCount>
                                <Typography>{e2p(numberFormat(row.detail.amount.sell))} ریالءء</Typography>
                            </DCLRow>

                            {this.props.seller.type !== "seller" ? (
                                <Fragment>
                                    <Divider sx={{ mb: 1 }} />
                                    <DCLRow>
                                        <Box>
                                            <Box sx={{ backgroundColor: '#a3c4f3' }} />
                                            <Typography>هزینه کرد:</Typography>
                                        </Box>
                                        <Typography>{e2p(numberFormat(row.detail.amount.net))} ریالءء</Typography>
                                    </DCLRow>
                                    <DCLRow>
                                        <Box>
                                            <Box sx={{ backgroundColor: '#8eecf5' }} />
                                            <Typography>سود خالص:</Typography>
                                        </Box>
                                        <Typography>{e2p(numberFormat(row.detail.amount.profit))} ریالءء</Typography>
                                    </DCLRow>
                                </Fragment>
                            ) : null}
                            <Divider sx={{ mb: 1 }} />
                            <DCLRow>
                                <Box>
                                    <Box sx={{ backgroundColor: '#98f5e1' }} />
                                    <Typography>تسویه شده:</Typography>
                                </Box>
                                <Typography>{e2p(numberFormat(row.detail.amount.cleared))} ریالءء</Typography>
                            </DCLRow>
                            <DCLRow>
                                <Box>
                                    <Box sx={{ backgroundColor: '#b9fbc0' }} />
                                    <Typography>تسویه نشده:</Typography>
                                </Box>
                                <Typography>{e2p(numberFormat(row.detail.amount.outstanding))} ریالءء</Typography>
                            </DCLRow>
                        </Grid>
                        {sumOfData > 0 ? (
                            <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }} sx={{
                                height: 310, display: 'flex', alignItems: 'center',
                                justifyContent: 'center',
                                '& > canvas': {
                                    height: '290px !important',
                                    width: '290px !important',
                                }
                            }}>
                                <Pie options={this.createOption()} data={{
                                    "labels": [
                                        "اشتراک جدید",
                                        "تمدید",
                                        "بسته افزایشی"
                                    ],
                                    "datasets": [
                                        {
                                            "label": "تعداد اکانت",
                                            "data": [
                                                row.detail.count.subscribed,
                                                row.detail.count.renewal,
                                                row.detail.count.add_package,
                                            ],
                                            "backgroundColor": [
                                                "#2a9d8f",
                                                "#e9c46a",
                                                "#e76f51",
                                            ],
                                            "borderColor": "#ffffff",
                                            "borderWidth": 2,
                                            "hoverBorderColor": "#ffffff"
                                        }
                                    ]
                                }} />
                            </Grid>
                        ) : null}
                    </Grid>
                )
            }
        })
    }

    createOption = () => {
        const options: ChartOptions<'pie'> = {
            responsive: true,
            plugins: {
                tooltip: {
                    // textDirection: 'rtl',
                    titleAlign: 'right',
                    bodyAlign: 'right',

                    titleFont: {
                        family: 'IRANYekanX',
                        size: 16
                    },
                    bodyFont: {
                        family: 'IRANYekanX'
                    }
                },
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                }
            }
        };
        return options;
    }

    render() {
        // if(data.length < 1) return null;


        const biggerSell = [...this.props.data].sort((a, b) => (b?.detail?.amount?.sell || 0) - (a?.detail?.amount?.sell || 0))
        const biggerNet = [...this.props.data].sort((a, b) => (b?.detail?.amount.net || 0) - (a?.detail?.amount.net || 0))

        const sellFull = biggerSell[0]?.detail?.amount?.sell || 0;
        const netFull = biggerNet[0]?.detail?.amount?.net || 0;
        const biggerNumber = sellFull > netFull ? sellFull : netFull;

        return (
            <Box sx={{
                display: 'flex', height: '100%', padding: {
                    xs: '8px',
                    md: '16px'
                }
            }}>

                {this.props.data.length > 0 ? this.props.data.map((item: any, index: number) => {
                    return (
                        <Box sx={{
                            px: {
                                xs: '4px',
                                md: '16px'
                            }, minWidth: `${this.props.data.length}%`
                        }} key={index}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // backgroundColor: 'gray',
                                height: '100%',
                                cursor: 'pointer',
                                outline: '0px solid #F1F1F1',
                                transition: 'unset',
                                borderRadius: '8px',
                                '&:hover': {
                                    // transitionDelay: '100ms',
                                    transition: '300ms',
                                    // backgroundColor: '#F7F7F8',
                                    background: 'linear-gradient(180deg, #EDF7F3, #FFF6F3)',
                                    outline: '12px solid #F7F7F8'
                                }
                            }} onClick={this.onClickData(item)}>
                                <Box sx={{
                                    height: this.props.seller.type !== "seller" ? 'calc(50% - 30px)' : 'calc(100% - 60px)',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    position: 'relative',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        height: '100%',
                                        width: '2px',
                                        backgroundColor: '#75C59B',
                                        right: '50%',
                                        left: '50%',
                                        bottom: 0,
                                    }
                                }}>
                                    <Box sx={{
                                        transition: '400ms', minHeight: '2px', height: `${(item.detail.amount.sell / biggerNumber) * 100}%`, width: '100%',
                                        // backgroundColor: '#75C59B',
                                        background: 'linear-gradient(180deg, #75C59B, #23A16E)',

                                        borderRadius: '8px', display: 'flex', alignItems: 'center'
                                    }} />
                                </Box>
                                <Grow in={true}>
                                    <Box sx={{
                                        userSelect: 'none',
                                        height: '60px',
                                        // display: 'flex', 
                                        // flexDirection: 'column', 
                                        // alignItems: 'center', 
                                        // justifyContent: 'center', 
                                        position: 'relative',
                                        overflow: 'hidden',
                                        padding: 1,
                                        display: {
                                            xs: 'flex',
                                            md: 'block'
                                        },
                                        alignItems: 'center',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            border: '1px solid silver',
                                            right: 20,
                                            left: 20,
                                            top: '50%',
                                            transform: 'rotate(-45deg)',
                                            display: {
                                                xs: 'none',
                                                md: 'inline-block'
                                            }
                                        }
                                    }}>
                                        <Typography sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'inline-block'
                                            }, textAlign: 'right', fontSize: 16, fontWeight: 500
                                        }}>{e2p(item.label_1)}</Typography>
                                        <Typography sx={{ textAlign: 'left', fontSize: 16, fontWeight: 500 }}>{e2p(item.label_2)}</Typography>
                                    </Box>
                                </Grow>
                                <Box sx={{
                                    display: this.props.seller.type === "seller" ? 'none' : 'block',
                                    height: 'calc(50% - 30px)',
                                    position: 'relative',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        height: '100%',
                                        top: 0,
                                        width: '2px',
                                        backgroundColor: '#FFB9A2',
                                        right: '50%',
                                        left: '50%',
                                    }
                                }}>
                                    <Box sx={{
                                        transition: '400ms', minHeight: '2px',
                                        height: `${(item.detail.amount.net / biggerNumber) * 100}%`,
                                        // backgroundColor: '#FFB9A2',
                                        background: 'linear-gradient(180deg, #FF8A65, #FFB9A2)',
                                        borderRadius: '8px', display: 'flex', alignItems: 'flex-start'
                                    }}>
                                        {/* <Typography>
                                            {item.detail.amount.net}
                                        </Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )
                }) : (
                    <CenterBox sx={{ height: '100%', width: '100%' }}>
                        <CircularProgress size={24} sx={{ color: '#242424' }} />
                    </CenterBox>
                )}
            </Box>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),
});

const mapStateToProps = (state: AppState) => ({
    seller: state.signin.seller
});

export default connect(mapStateToProps, mapDispatchToProps)(MainChart);
