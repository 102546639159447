import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";
import { CreateServerData, GetServersData, ServerDetails, ServersResponseData } from "./types";

const serversSlice = createSlice({
    name: "servers",
    initialState: {
        get_process: false,
        results: null as ServersResponseData | null,
        server_details: null as ServerDetails | null,
        downBandwidthItems: [] as number[],
        downBandwidth: {
            current: 0,
            min: 0,
            max: 0,
            avg: 0,
        },
        upBandwidthItems: [] as number[],
        upBandwidth: {
            current: 0,
            min: 0,
            max: 0,
            avg: 0,
        },

        get_server_details_process: false,
        create_process: false,
        delete_process: false,
    },
    reducers: {
        getServersStarted(state) {
            state.get_process = true;
            if (state.results) state.results.data = [];
        },
        getServersSuccess(state, action: PayloadAction<ServersResponseData>) {
            state.results = action.payload;
            state.get_process = false;
        },
        getServersFailed(state, action: PayloadAction<any>) {
            state.get_process = false;
            state.results = null;
        },

        clearServerDetails(state) {
            state.get_server_details_process = false;
            state.server_details = null;

            state.downBandwidth = { current: 0, min: 0, max: 0, avg: 0 };
            state.downBandwidthItems = [];

            state.upBandwidth = { current: 0, min: 0, max: 0, avg: 0 };
            state.upBandwidthItems = [];
            // if (state.results) state.server_details = [];
        },

        getServerDetailStarted(state) {
            state.get_server_details_process = true;
            // if (state.results) state.server_details = [];
        },
        getServerDetailSuccess(state, action: PayloadAction<ServerDetails>) {
            const details = action.payload;

            const oneMBitInBytes = 131072;

            const dCur = details.netIO.down / oneMBitInBytes;
            const dMin = state.downBandwidth.min < 1 || state.downBandwidth.min > dCur ? dCur : state.downBandwidth.min;
            const dMax = state.downBandwidth.max > dCur ? state.downBandwidth.max : dCur;
            const dItems = state.downBandwidthItems.length > 100 ? [...state.downBandwidthItems.slice(1), dCur] : [...state.downBandwidthItems, dCur];
            const dAvg = dItems.reduce((da, db) => da + db, 0) / dItems.length;

            const uCur = details.netIO.up / oneMBitInBytes;
            const uMin = state.upBandwidth.min < 1 || state.upBandwidth.min > uCur ? uCur : state.upBandwidth.min;
            const uMax = state.upBandwidth.max > uCur ? state.upBandwidth.max : uCur;
            const uItems = state.upBandwidthItems.length > 100 ? [...state.upBandwidthItems.slice(1), uCur] : [...state.upBandwidthItems, uCur];
            const uAvg = uItems.reduce((ua, ub) => ua + ub, 0) / uItems.length;

            state.downBandwidth = { current: dCur, min: dMin, max: dMax, avg: dAvg };
            state.downBandwidthItems = dItems;
            state.upBandwidth = { current: uCur, min: uMin, max: uMax, avg: uAvg };
            state.upBandwidthItems = uItems;

            state.server_details = details;
            state.get_server_details_process = false;
        },
        getServerDetailFailed(state) {
            state.get_server_details_process = false;
        },

        createServersStarted(state) {
            state.create_process = true;
        },
        createServersSuccess(state, action: PayloadAction<ServersResponseData>) {
            state.create_process = false;
        },
        createServersFailed(state, action: PayloadAction<any>) {
            state.create_process = false;
        },

        deleteServersStarted(state) {
            state.delete_process = true;
        },
        deleteServersSuccess(state, action: PayloadAction<ServersResponseData>) {
            state.delete_process = false;
        },
        deleteServersFailed(state, action: PayloadAction<any>) {
            state.delete_process = false;
        },
    },
});

// export const {} = serversSlice.actions;

export const getServersAsync =
    (data?: GetServersData, redux: boolean = true) =>
    (dispatch: AppDispatch) => {
        if (redux) dispatch(serversSlice.actions.getServersStarted());
        return new Promise((resolve, reject) => {
            let search = `?limit=${data?.limit || 10}&page=${data?.page || 1}`;
            if (data?.query) search += `&query=${data?.query}`;
            if (data?.id) search += `&id=${data?.id}`;
            axios
                .get(api.servers + search)
                .then((response: AxiosResponse<ServersResponseData>) => {
                    if (redux) dispatch(serversSlice.actions.getServersSuccess(response.data.results));
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    if (redux) dispatch(serversSlice.actions.getServersFailed(error.response?.data));
                    reject(error);
                });
        });
    };

export const getServerDetailsAsync =
    (id?: number, onlySuccessRedux: boolean = true) =>
    (dispatch: AppDispatch) => {
        if (onlySuccessRedux) {
            dispatch(serversSlice.actions.getServerDetailStarted());
        }

        return new Promise((resolve, reject) => {
            axios
                .get(api.servers + `/${id}/details`)
                .then((response: AxiosResponse<ServerDetails>) => {
                    dispatch(serversSlice.actions.getServerDetailSuccess(response.data.results));
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    if (onlySuccessRedux) {
                        dispatch(serversSlice.actions.getServerDetailFailed());
                    }
                    reject(error);
                });
        });
    };

export const createServersAsync = (data?: CreateServerData) => (dispatch: AppDispatch) => {
    dispatch(serversSlice.actions.createServersStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.servers, data)
            .then((response: AxiosResponse) => {
                dispatch(serversSlice.actions.createServersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(serversSlice.actions.createServersFailed(error.response?.data));
                reject(error);
            });
    });
};

export const deleteServersAsync = (server_id: number) => (dispatch: AppDispatch) => {
    dispatch(serversSlice.actions.deleteServersStarted());
    return new Promise((resolve, reject) => {
        axios
            .delete(api.servers + "/" + server_id)
            .then((response: AxiosResponse) => {
                dispatch(serversSlice.actions.deleteServersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(serversSlice.actions.deleteServersFailed(error.response?.data));
                reject(error);
            });
    });
};

export const restartServerXRayCoreAsync = (server_id: number) => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .get(api.servers + "/" + server_id + "/restart/xray")
            .then((response: AxiosResponse) => {
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export const { clearServerDetails } = serversSlice.actions;

export default serversSlice;
