import { Box, CircularProgress, Grow } from '@mui/material'
import React, { Component } from 'react'
import CenterBox from './CenterBox'

export default class SuspenseLoading extends Component {
    render() {
        return (
            <Box sx={{ height: '100%' }}>
                <Grow in={true}>
                    <CenterBox sx={{ height: '100%', flexDirection: 'column' }}>
                        <CircularProgress disableShrink color="inherit" />
                    </CenterBox>
                </Grow>
            </Box>
        )
    }
}
