import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { Seller } from "containers/sellers/types";
import { User } from "containers/users/types";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";
import { SigninErrorData, SigninRequestData, SigninResponseData } from "./types";

const signinSlice = createSlice({
    name: "signin",
    initialState: {
        user: {} as User,
        config: {} as { [key: string]: any },
        seller: {} as Seller,
        check_in_process: false as boolean,
    },
    reducers: {
        checkAuthStarted(state) {
            state.check_in_process = true;
            state.config = {};
        },
        checkAuthSuccess(state, action: PayloadAction<AxiosResponse>) {
            if (action.payload?.data?.results) {
                if (JSON.stringify(state.config) !== JSON.stringify(action.payload.data.results.config)) {
                    state.config = action.payload.data.results.config;
                }

                if (state.user.id !== action.payload.data.results.user.id) {
                    state.user = action.payload.data.results.user;
                }
                if (state.seller.id !== action.payload.data.results.user.seller.id) {
                    state.seller = action.payload.data.results.user.seller;
                }
                if (state.seller.debt && state.seller.debt?.amount !== action.payload.data.results.user.seller.debt.amount) {
                    state.seller.debt = action.payload.data.results.user.seller.debt;
                }
            }
            state.check_in_process = false;
        },
        checkAuthFailed(state) {
            state.check_in_process = false;
        },
    },
});

// export const { } = signinSlice.actions;

export const signinAsync = (data: SigninRequestData) => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .post(api.panel_signin, data)
            .then((response: AxiosResponse<SigninResponseData>) => {
                resolve(response);
            })
            .catch((error: AxiosError<SigninErrorData>) => {
                reject(error);
            });
    });
};

export const logoutAsync = () => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .get(api.logout)
            .then((response: AxiosResponse) => {
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export const checkAuthAsync = () => (dispatch: AppDispatch) => {
    dispatch(signinSlice.actions.checkAuthStarted());

    return new Promise((resolve, reject) => {
        axios
            .get(api.check)
            .then((response: AxiosResponse) => {
                dispatch(signinSlice.actions.checkAuthSuccess(response));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(signinSlice.actions.checkAuthFailed());
                reject(error);
            });
    });
};

export default signinSlice;
