import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import moment from "jalali-moment";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";
import {
    ChangeProtocolData,
    CreateUserData,
    GetUsersData,
    RenewalUserData,
    ResetTrafficData,
    UpdateUserData,
    UpdateXrayConfigData,
    User,
    UsersResponseData,
} from "./types";

const usersSlice = createSlice({
    name: "users",
    initialState: {
        get_process: false,
        results: null as UsersResponseData | null,
        create_process: false,
        update_process: false,
        delete_process: false,
        renewal_process: false,
        add_extra_package_process: false,
        get_bill_process: false,
        settlement_process: false,
    },
    reducers: {
        getUsersStarted(state) {
            state.get_process = true;
            if (state.results) state.results.data = [];
        },
        getUsersSuccess(state, action: PayloadAction<UsersResponseData>) {
            state.results = action.payload;
            state.get_process = false;
        },
        getUsersFailed(state, action: PayloadAction<any>) {
            state.get_process = false;
            state.results = null;
        },

        createUsersStarted(state) {
            state.create_process = true;
        },
        createUsersSuccess(state, action: PayloadAction<UsersResponseData>) {
            state.create_process = false;
        },
        createUsersFailed(state, action: PayloadAction<any>) {
            state.create_process = false;
        },

        deleteUsersStarted(state) {
            state.delete_process = true;
        },
        deleteUsersSuccess(state, action: PayloadAction<UsersResponseData>) {
            state.delete_process = false;
        },
        deleteUsersFailed(state, action: PayloadAction<any>) {
            state.delete_process = false;
        },
        getUserBillStarted(state) {
            state.get_bill_process = true;
        },
        getUserBillSuccess(state, action: PayloadAction<UsersResponseData>) {
            state.get_bill_process = false;
        },
        getUserBillFailed(state, action: PayloadAction<any>) {
            state.get_bill_process = false;
        },
        settlementUserBillStarted(state) {
            state.settlement_process = true;
        },
        settlementUserBillSuccess(state, action: PayloadAction<UsersResponseData>) {
            state.settlement_process = false;
        },
        settlementUserBillFailed(state, action: PayloadAction<any>) {
            state.settlement_process = false;
        },
        resetUserUsageTrafficSuccess(state, action: PayloadAction<any>) {
            if (!state.results) return;

            state.results.data = state.results?.data.map((i) => {
                if (i.id === action.payload.data.results.id) {
                    i.xui.up = 0;
                    i.xui.down = 0;
                    i.xui.enable = true;
                }
                return i;
            });
        },
        changeUserAccountStatusSuccess(state, action: PayloadAction<{ user_id: number; status: boolean }>) {
            if (!state.results) return;

            state.results.data = state.results?.data.map((i) => {
                if (i.id === action.payload.user_id) {
                    i.xui.enable = action.payload.status;
                }
                return i;
            });
        },
        updateUserAsyncStarted(state) {
            state.update_process = true;
        },
        updateUserAsyncSuccess(state, action: PayloadAction<{ user_id: number; data: UpdateUserData }>) {
            if (!state.results) return;
            state.results.data = state.results?.data.map((i) => {
                if (i.id === action.payload.user_id) {
                    i.full_name = action.payload.data.full_name;
                    i.mobile = action.payload.data.mobile;
                    const account = i.accounts[0];
                    account.note = action.payload.data.note;
                    account.interest_rate = action.payload.data.interest_rate || account.interest_rate;
                    account.subscription_rate = action.payload.data.subscription_rate || account.subscription_rate;
                    i.accounts = [account];
                }
                return i;
            });
            state.update_process = false;
        },
        updateUserAsyncFailed(state, action: PayloadAction<AxiosError>) {
            state.update_process = false;
        },

        renewalUserAsyncStarted(state) {
            state.renewal_process = true;
        },
        renewalUserAsyncSuccess(state, action: PayloadAction<User>) {
            if (!state.results) return;
            state.results.data = state.results?.data.map((i) => {
                if (i.id === action.payload.id) {
                    i.accounts = action.payload.accounts;
                    const plan = action.payload.accounts[0]?.plan;
                    const expire = plan?.expire || 0;
                    // if (action.payload.data.reset_traffic) {
                    i.xui.up = 0;
                    i.xui.down = 0;
                    // }

                    i.xui.total = (plan?.traffic || 0) * Math.pow(1024, 3);
                    i.xui.enable = true;
                    if (i.xui.expiryTime) {
                        i.xui.expiryTime = parseInt(moment().set("hour", 12).set("minutes", 0).set("seconds", 0).add("days", expire).unix() + "000");
                    }
                }
                return i;
            });
            state.renewal_process = false;
        },
        renewalUserAsyncFailed(state, action: PayloadAction<AxiosError>) {
            state.renewal_process = false;
        },

        addExtraPackageAsyncStarted(state) {
            state.add_extra_package_process = true;
        },
        addExtraPackageAsyncSuccess(state, action: PayloadAction<User>) {
            if (!state.results) return;
            state.results.data = state.results?.data.map((i) => {
                if (i.id === action.payload.id) {
                    i.accounts = action.payload.accounts;
                    const appliedPkg = action.payload.applied_extra_package;
                    if (appliedPkg) {
                        i.xui.total = i.xui.total + appliedPkg.traffic * Math.pow(1024, 3);
                        i.xui.enable = true;
                    }
                }
                return i;
            });
            state.add_extra_package_process = false;
        },
        addExtraPackageAsyncFailed(state, action: PayloadAction<AxiosError>) {
            state.add_extra_package_process = false;
        },
    },
});

// export const {} = usersSlice.actions;

export const getUsersAsync =
    (data?: GetUsersData, redux: boolean = true) =>
    (dispatch: AppDispatch) => {
        if (redux) dispatch(usersSlice.actions.getUsersStarted());
        return new Promise((resolve, reject) => {
            let search = `?limit=${data?.limit || 10}&page=${data?.page || 1}`;
            if (data?.query) search += `&query=${data?.query}`;
            if (data?.seller_id) search += `&seller_id=${data?.seller_id}`;
            if (data?.plan_id) search += `&plan_id=${data?.plan_id}`;
            if (data?.filter) search += `&filter=${data?.filter}`;

            axios
                .get(api.users + search)
                .then((response: AxiosResponse<UsersResponseData>) => {
                    if (redux) dispatch(usersSlice.actions.getUsersSuccess(response.data.results));
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    if (redux) dispatch(usersSlice.actions.getUsersFailed(error.response?.data));
                    reject(error);
                });
        });
    };

export const createUsersAsync = (data?: CreateUserData) => (dispatch: AppDispatch) => {
    dispatch(usersSlice.actions.createUsersStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.users, data)
            .then((response: AxiosResponse) => {
                dispatch(usersSlice.actions.createUsersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(usersSlice.actions.createUsersFailed(error.response?.data));
                reject(error);
            });
    });
};

export const deleteUsersAsync = (user_id: number) => (dispatch: AppDispatch) => {
    dispatch(usersSlice.actions.deleteUsersStarted());
    return new Promise((resolve, reject) => {
        axios
            .delete(api.users + "/" + user_id)
            .then((response: AxiosResponse) => {
                dispatch(usersSlice.actions.deleteUsersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(usersSlice.actions.deleteUsersFailed(error.response?.data));
                reject(error);
            });
    });
};

export const getUserBillAsync = (user_id: number) => (dispatch: AppDispatch) => {
    dispatch(usersSlice.actions.getUserBillStarted());
    return new Promise((resolve, reject) => {
        axios
            .get(api.users + "/" + user_id + "/bill")
            .then((response: AxiosResponse) => {
                dispatch(usersSlice.actions.getUserBillSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(usersSlice.actions.getUserBillFailed(error.response?.data));
                reject(error);
            });
    });
};

export const settlementUserBillAsync = (user_id: number) => (dispatch: AppDispatch) => {
    dispatch(usersSlice.actions.settlementUserBillStarted());
    return new Promise((resolve, reject) => {
        axios
            .get(api.users + "/bill/" + user_id)
            .then((response: AxiosResponse) => {
                dispatch(usersSlice.actions.settlementUserBillSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(usersSlice.actions.settlementUserBillFailed(error.response?.data));
                reject(error);
            });
    });
};

export const resetUserUsageTrafficAsync = (user_id: number, data: ResetTrafficData) => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .put(api.users + "/" + user_id + "/reset_traffic", data)
            .then((response: AxiosResponse) => {
                dispatch(usersSlice.actions.resetUserUsageTrafficSuccess(response));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export const changeUserAccountStatusAsync = (user_id: number, status: boolean) => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .put(api.users + "/" + user_id + "/change_status", {
                status,
            })
            .then((response: AxiosResponse) => {
                dispatch(
                    usersSlice.actions.changeUserAccountStatusSuccess({
                        user_id,
                        status,
                    })
                );
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export const updateUserAsync = (user_id: number, data: UpdateUserData) => (dispatch: AppDispatch) => {
    dispatch(usersSlice.actions.updateUserAsyncStarted());

    return new Promise((resolve, reject) => {
        axios
            .put(api.users + "/" + user_id, data)
            .then((response: AxiosResponse) => {
                dispatch(usersSlice.actions.updateUserAsyncSuccess({ user_id, data }));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(usersSlice.actions.updateUserAsyncFailed(error));
                reject(error);
            });
    });
};

export const renewalUserAsync = (user_id: number, data: RenewalUserData) => (dispatch: AppDispatch) => {
    dispatch(usersSlice.actions.renewalUserAsyncStarted());

    return new Promise((resolve, reject) => {
        axios
            .put(api.users + "/" + user_id + "/renewal", data)
            .then((response: AxiosResponse<User>) => {
                dispatch(usersSlice.actions.renewalUserAsyncSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(usersSlice.actions.renewalUserAsyncFailed(error));
                reject(error);
            });
    });
};

export const addExtraPackageAsync = (user_id: number, data: RenewalUserData) => (dispatch: AppDispatch) => {
    dispatch(usersSlice.actions.addExtraPackageAsyncStarted());

    return new Promise((resolve, reject) => {
        axios
            .put(api.users + "/" + user_id + "/add_package", data)
            .then((response: AxiosResponse<User>) => {
                dispatch(usersSlice.actions.addExtraPackageAsyncSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(usersSlice.actions.addExtraPackageAsyncFailed(error));
                reject(error);
            });
    });
};

export const changeProtocolAsync = (user_id: number, data: ChangeProtocolData) => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .put(api.users + "/" + user_id + "/change_protocol", data)
            .then((response: AxiosResponse) => {
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export const updateXrayConfigAsync = (user_id: number, data: UpdateXrayConfigData) => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .put(api.users + "/" + user_id + "/xray", data)
            .then((response: AxiosResponse) => {
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export const getTimelineByMobileAsync = (mobile: string) => (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .get(api.users + "/" + mobile + "/timeline")
            .then((response: AxiosResponse) => {
                resolve(response);
            })
            .catch((error: AxiosError) => {
                reject(error);
            });
    });
};

export default usersSlice;
