import { Box, Typography } from '@mui/material'
import { styled } from "@mui/system"

import CenterBox from 'components/CenterBox'
import { CloudCross } from 'iconsax-react'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: '#007bff'
})
export default class PageNotFound extends Component {
    render() {
        return (
            <CenterBox sx={{ height: '100%', flexDirection: 'column' }}>
                <Box sx={{ mb: 4 }}>
                    <CloudCross
                        size={128}
                        color="#dc3545"
                    />
                </Box>
                <CenterBox>
                    <StyledLink to="/">بازگشت به صفحه اصلی</StyledLink>
                    <Typography sx={{ borderRight: '1px solid silver', borderLeft: '1px solid silver', mx: 2, px: 2, fontWeight: 700, fontSize: 24 }}>404</Typography>
                    <Typography>این راه به جایی نمیرسه</Typography>
                </CenterBox>
            </CenterBox>
        )
    }
}
