import { Box, Button, CircularProgress, Divider, Grid, Grow, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
    ArcElement,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    Tooltip
} from 'chart.js';
import CenterBox from 'components/CenterBox';
import Rtl from 'components/Rtl';
import { getOrdersAsync } from 'containers/orders/slice';
import { GetOrdersData } from 'containers/orders/types';
import { getUsersAsync } from 'containers/users/slice';
import { GetUsersData } from 'containers/users/types';
import { e2p } from 'helpers/general';
import withRouter, { Router } from 'helpers/withRouter';
import { MoneyTime, UserAdd, UserRemove } from 'iconsax-react';
import React, { Component, Fragment } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { AxiosError, AxiosResponse } from 'utils/axios';
import MainChart from './MainChart';
import { getDashboardDataV2Async } from './slice';

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.defaults.font.family = "IRANYekanX";

interface IProps extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
    router: Router
}

interface IState {
    chart_mod: 'daily' | 'monthly',
    pie_chart_key: null | number,
    chart_loading: boolean,
    subscribed_count: number,
    disable_count: number,
    disable_count_loading: boolean,
    order_count: number,
    order_count_loading: boolean
}

class DashboardV2 extends Component<IProps, IState> {

    state: IState = {
        chart_mod: 'daily',
        pie_chart_key: null,
        chart_loading: true,
        subscribed_count: 0,
        disable_count: 0,
        disable_count_loading: true,
        order_count: 0,
        order_count_loading: true
    }



    getDisableUsersCount = () => {
        return new Promise((resolve, rej) => {
            this.props.getUsers({
                limit: 1, filter: 'disables', page: 1
            }).then((res: AxiosResponse) => {
                const count = res.data.results.total;
                this.setState({ ...this.state, disable_count: count, disable_count_loading: false }, () => {
                    resolve(true)
                })
            }).catch((err: AxiosError) => {
                rej()
                // setTimeout(() => {
                //     this.getDisableUsersCount();
                // }, 3000)
            })
        })
    }

    getOrdersCount = () => {
        return new Promise((resolve, rej) => {
            this.props.getOrders({
                limit: 1, page: 1
            }).then((res: AxiosResponse) => {
                const count = res.data.results.total;
                this.setState({ ...this.state, order_count: count, order_count_loading: false }, () => {
                    resolve(true)
                })
            }).catch((err: AxiosError) => {
                rej()
                // setTimeout(() => {
                //     this.getOrdersCount();
                // }, 3000)
            })
        })
    }


    getChartData = () => {
        return new Promise((resolve, rej) => {
            this.props.getDashboardDataV2()
                .then((res: any) => {
                    const firstPie = res.data.results.by_plan[0];
                    const subscribed_count = [...res.data.results.monthly].reverse()[0]?.detail?.count?.subscribed || 0
                    this.setState({ ...this.state, pie_chart_key: firstPie.key, chart_loading: false, subscribed_count }, () => {
                        resolve(true)
                    })
                })
                .catch(() => {
                    rej()
                    // setTimeout(() => {
                    //     this.getChartData();
                    // }, 3000)
                })
        })
    }

    async componentDidMount() {
        if (this.props.by_plan.length > 0) {
            this.setState({ ...this.state, pie_chart_key: this.props.by_plan[0]?.key })
        }

        await this.getChartData();
        await this.getOrdersCount();
        await this.getDisableUsersCount();

    }

    onChangeChartPeriod = (_: any, value: any) => {
        if (!value) return null;
        this.setState({ ...this.state, chart_mod: value })
    }

    onChangePieChart = (_: any, value: any) => {
        if (!value) return null;
        this.setState({ ...this.state, pie_chart_key: value })
    }

    createOption = (label: string) => {
        const options: ChartOptions<'pie'> = {
            // onClick: (_: ChartEvent, elements: ActiveElement[]) => {
            //     if (this.state.pie_chart_key) {
            //         const chartData = this.props.chartData.by_plan.find((i: any) => i.key === this.state.pie_chart_key);
            //         const plan_id = chartData.datasets[elements[0].datasetIndex].ids[elements[0].index];
            //         if (plan_id) this.props.router.navigate('/users/plan/' + plan_id);
            //     }
            // },
            responsive: true,
            plugins: {
                tooltip: {
                    // textDirection: 'rtl',
                    titleAlign: 'right',
                    bodyAlign: 'right',

                    titleFont: {
                        family: 'IRANYekanX',
                        size: 16
                    },
                    bodyFont: {
                        family: 'IRANYekanX'
                    }
                },
                legend: {
                    align: 'center',
                    textDirection: 'rtl',
                    labels: {
                        font: {
                            family: 'IRANYekanX',
                            size: 13,
                            lineHeight: 22,
                        },
                        boxWidth: 22,
                        boxHeight: 22,
                    },
                    position: 'right',
                    rtl: true,
                    title: {
                        font: {
                            family: 'IRANYekanX',
                            // lineHeight: 20,
                            size: 60,
                        },

                    }
                },
                title: {
                    display: false,
                    text: label
                }
            }
        };
        return options;
    }

    render() {
        const data = (this.state.chart_mod === "daily" ? this.props.chartData.daily : this.props.chartData.monthly) || [];
        const pieChartData: any = this.props.by_plan.find((i: any) => i.key === this.state.pie_chart_key);

        return (
            <Rtl sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <CountBoxOnly sx={{ background: 'linear-gradient(20deg, #EDF7F3, #fff)' }}>
                            <CountContentBox>
                                {this.state.chart_loading ? (
                                    <CenterBox sx={{ minHeight: 54 }}>
                                        <CircularProgress size={18} sx={{ color: '#23A16E40' }} />
                                    </CenterBox>
                                ) : (
                                    <Grow in={true} timeout={500}>
                                        <Typography sx={{ color: '#23A16E' }}>
                                            {e2p(this.state.subscribed_count)}
                                        </Typography>
                                    </Grow>
                                )}
                                <Typography>
                                    مشترک جدید <span style={{ fontSize: 13, fontWeight: 400, color: '#23A16E' }}>ماه جاری</span>
                                </Typography>
                            </CountContentBox>
                            <UserAdd
                                size={86}
                                color="#23A16E"
                            />
                        </CountBoxOnly>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CountBox sx={{ background: 'linear-gradient(20deg, #FEF1ED, #fff)' }} onClick={() => {
                            this.props.router.navigate('/users', {
                                state: {
                                    filter: 'disables'
                                },
                            })
                        }}>
                            <CountContentBox>
                                {this.state.disable_count_loading ? (
                                    <CenterBox sx={{ minHeight: 54 }}>
                                        <CircularProgress size={18} sx={{ color: '#F24E1E40' }} />
                                    </CenterBox>
                                ) : (
                                    <Grow in={true} timeout={500}>
                                        <Typography sx={{ color: '#F24E1E' }}>
                                            {e2p(this.state.disable_count)}
                                        </Typography>
                                    </Grow>
                                )}
                                <Typography>اکانت غیرفعال</Typography>
                            </CountContentBox>
                            <UserRemove
                                size={86}
                                color="#F24E1E"
                            />
                        </CountBox>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CountBox sx={{ background: 'linear-gradient(20deg, #E4ECF4, #fff)' }} onClick={() => {
                            this.props.router.navigate('/bill')
                        }}>
                            <CountContentBox>
                                {this.state.order_count_loading ? (
                                    <CenterBox sx={{ minHeight: 54 }}>
                                        <CircularProgress size={18} sx={{ color: '#0e6ba840' }} />
                                    </CenterBox>
                                ) : (
                                    <Grow in={true} timeout={500}>
                                        <Typography sx={{ color: '#0e6ba8' }}>
                                            {e2p(this.state.order_count)}
                                        </Typography>
                                    </Grow>
                                )}
                                <Typography>تسویه نشده</Typography>
                            </CountContentBox>
                            <MoneyTime
                                size={86}
                                color="#0e6ba8"
                            />
                        </CountBox>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{
                            position: 'relative',
                            width: '100%',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                            zIndex: 1,
                            height: '68vh'
                        }} dir="ltr">
                            <MainChart data={data} mode={this.state.chart_mod} />
                        </Box>
                        <CenterBox sx={{ position: 'relative', zIndex: 0 }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={this.state.chart_mod}
                                exclusive
                                onChange={this.onChangeChartPeriod}
                                sx={{ width: '80%', maxWidth: 400 }}
                            // aria-label="Platform"
                            >
                                <ToggleButton sx={{
                                    borderRadius: 0,
                                    borderBottomLeftRadius: '16px!important',
                                    transition: '200ms',
                                    backgroundColor: '#fff',
                                    borderColor: '#f0f0f0',
                                    flex: 1,
                                    borderTop: 'unset', "&:hover": {
                                        backgroundColor: '#EDF7F3',
                                    }, "&.Mui-selected": {
                                        backgroundColor: '#FF8A65!important',
                                        borderColor: '#FF8A65',
                                        color: '#fff'
                                    }
                                }} value="daily">روزانه</ToggleButton>
                                <ToggleButton sx={{
                                    borderRadius: 0,
                                    borderBottomRightRadius: '16px!important',
                                    transition: '200ms',
                                    backgroundColor: '#fff',
                                    borderColor: '#f0f0f0',
                                    borderTop: 'unset',
                                    flex: 1,
                                    "&:hover": {
                                        backgroundColor: '#EDF7F3',
                                    }, "&.Mui-selected": {
                                        backgroundColor: '#FF8A65!important',
                                        borderColor: '#FF8A65',
                                        color: '#fff'
                                    }
                                }} value="monthly">ماهانه</ToggleButton>
                            </ToggleButtonGroup>
                        </CenterBox>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{
                            position: 'relative',
                            width: '100%',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                            zIndex: 1,
                            p: 2,
                            userSelect: 'none',
                            height: '63vh',
                            dir: 'ltr'
                        }}>
                            {pieChartData ? (
                                <Fragment>
                                    <Typography sx={{ fontWeight: 400, fontSize: 18 }}>مجموع پلن های {e2p(pieChartData.title)}</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: 38, pt: 1, fontWeight: 600 }}>{e2p(pieChartData.total)}</Typography>
                                        <Typography sx={{ opacity: 0.7, pt: 2, ml: 1 }}>کاربر</Typography>
                                    </Box>
                                    <Divider />
                                    <Pie options={this.createOption('')} data={pieChartData} />
                                </Fragment>
                            ) : (
                                <CenterBox sx={{ height: '100%', width: '100%' }}>
                                    <CircularProgress size={24} sx={{ color: '#242424' }} />
                                </CenterBox>
                            )}


                        </Box>
                        <CenterBox sx={{ position: 'relative', zIndex: 0 }}>
                            <ToggleButtonGroup
                                color="primary"
                                value={this.state.pie_chart_key}
                                exclusive
                                onChange={this.onChangePieChart}
                                sx={{ width: '80%' }}
                            // aria-label="Platform"
                            >
                                {[...this.props.by_plan].sort((a, b) => a.key - b.key).map((byPlanItem: any, key) => {
                                    return (
                                        <ToggleButton key={key} sx={{
                                            flex: 1,
                                            transition: '200ms',
                                            borderTop: 'unset',
                                            backgroundColor: '#fff',
                                            borderColor: '#f0f0f0',
                                            '&:first-child': {
                                                borderRadius: 0,
                                                borderBottomLeftRadius: '16px!important',
                                            },
                                            '&:last-child': {
                                                borderRadius: 0,
                                                borderBottomRightRadius: '16px!important',
                                            },
                                            "&:hover": {
                                                backgroundColor: '#EDF7F3',
                                            }, "&.Mui-selected": {
                                                backgroundColor: '#FF8A65!important',
                                                borderColor: '#FF8A65',
                                                color: '#fff'
                                            }
                                        }} value={byPlanItem.key}>{e2p(byPlanItem.title)}</ToggleButton>
                                    )
                                })}
                            </ToggleButtonGroup>
                        </CenterBox>
                    </Grid>
                </Grid>
            </Rtl>
        )
    }
}

const CountBox = styled(Button)({
    width: '100%',
    color: '#242424',
    height: 113,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderRadius: 8,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
})

const CountBoxOnly = styled(Box)({
    width: '100%',
    color: '#242424',
    height: 113,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderRadius: 8,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
})

const CountContentBox = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '& > p:first-child': {
        fontSize: 36,
        fontWeight: 700
    },
    '& > p:last-child': {
        fontSize: 22,
        fontWeight: 300
    }
})

const mapStateToProps = (state: AppState) => ({
    chartData: state.dashboard.v2Data,
    by_plan: state.dashboard.v2Data.by_plan,
})

const mapDispatchToProps = (dispatch: any) => ({
    getDashboardDataV2: () => dispatch(getDashboardDataV2Async()),
    getUsers: (data?: GetUsersData) => dispatch(getUsersAsync(data, false)),
    getOrders: (data?: GetOrdersData) => dispatch(getOrdersAsync(data, false)),


})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardV2));
