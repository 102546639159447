import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DarkMode from "components/DarkMode";
import Rtl from "components/Rtl";
import { Order } from "containers/orders/types";
import { numberFormat, toJalaliDate } from "helpers/general";
import React, { Component } from "react";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { setDialogTo } from "redux/slice";
import { AppState } from "redux/store";
import { SetDialogPayload } from "redux/types";
import { AxiosError, AxiosResponse } from "utils/axios";
import { reverseSettlementsAsync } from "./slice";
import { Settlement } from "./types";

interface SettlementsWorkSpaceProps extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
    data: Settlement;
    unselectRow: () => void;
    refresh: () => void;
}

class SettlementsWorkSpace extends Component<SettlementsWorkSpaceProps> {

    getSettlementType = (type: Settlement['type']) => {
        switch (type) {
            case "cleared": return "تسویه شده";
            case "draft": return "پیش نویس";
            default: return "";
        }
    }

    getOrderType = (type: Order['type']) => {
        switch (type) {
            case "subscribed": return "اشتراک جدید";
            case "renewal": return "تمدید";
            case "add_package": return "بسته افزایشی";
            case "delete": return "حذف کاربر";
            default: return "";
        }
    }

    reversItem = () => {
        // incomplete
        this.props.setDialogTo({
            title: 'استرداد',
            content: () => (
                <Typography>جدی میفرمایید ؟</Typography>
            ),
            buttons: [
                {
                    title: 'تایید / استرداد',
                    fullWidth: true,
                    color: 'info',
                    variant: 'contained',
                    disableElevation: true,
                    onClick: () => {
                        this.props.setDialogTo(false)

                        this.props.reverseSettlement(this.props.data.id).then((res: AxiosResponse) => {
                            this.props.refresh()
                        }).catch((error: AxiosError) => {
                        })
                    }
                },
                {
                    title: 'انصرف',
                    color: 'error',
                    onClick: () => {
                        this.props.setDialogTo(false)
                    }
                }
            ],
        })
    }

    render() {
        return (
            <DarkMode>
                <Box sx={{ height: "100%" }}>
                    <Scrollbars
                        style={{ width: "100%", height: "calc(100% - 0px)" }}
                        renderTrackVertical={(props) => <div {...props} className="track-vertical" />}
                        renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                    >
                        <Rtl sx={{ mt: 0, p: 0 }}>

                            <TableContainer component={Paper} sx={{ boxShadow: 'unset', mt: 0 }} >
                                <Table sx={{}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={100}>شناسه</TableCell>
                                            <TableCell width={150} align="center">مبلغ (ریالءء)</TableCell>
                                            <TableCell align="center">بابت</TableCell>
                                            <TableCell align="center">تاریخ ایجاد</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.data.orders.map((order: Order, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {order.id}#
                                                </TableCell>
                                                <TableCell align="center">
                                                    {numberFormat(order.amount)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {this.getOrderType(order.type)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {toJalaliDate(order.created_at)}
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </Rtl>
                    </Scrollbars>
                    <Box sx={{ p: 2 }}>
                        {/* {this.props.data.type === "cleared" ? (
                            <Button
                                // disabled={this.props.data.length < 1}
                                onClick={this.reversItem}
                                color="error"
                                sx={{ height: 48 }}
                                variant="contained"
                                fullWidth
                                startIcon={
                                    <CenterBox>
                                        <Typography>ریالءء</Typography>
                                        <Typography sx={{ mr: 1, ml: 1, fontSize: 16, fontWeight: 'bold', letterSpacing: 1, pt: '3px' }}>{numberFormat(this.props.data.amount)} </Typography>
                                        <Refresh2 size={28} />
                                    </CenterBox>
                                }
                                disableElevation>استرداد</Button>
                        ) : null}
                        {this.props.data.type === "draft" ? (
                            <Button
                                // disabled={this.props.data.length < 1}
                                // onClick={this.settlement({
                                //     total_amount: total_amount,
                                //     service_amount: 0
                                // })}
                                color="success"
                                sx={{ height: 48 }}
                                variant="contained"
                                fullWidth
                                startIcon={
                                    <CenterBox>
                                        <Typography>ریالءء</Typography>
                                        <Typography sx={{ mr: 1, ml: 1, fontSize: 16, fontWeight: 'bold', letterSpacing: 1, pt: '3px' }}>{numberFormat(this.props.data.amount)} </Typography>
                                        <ShieldTick size={28} />
                                    </CenterBox>
                                }
                                disableElevation>تسویه فاکتور ها</Button>
                        ) : null} */}

                    </Box>
                </Box>
            </DarkMode>
        );
    }
}


const mapStateToProps = (state: AppState) => ({
    reverse_process: state.settlements.reverse_process,
    settlements: state.settlements.results,
    seller: state.signin.seller
})

const mapDispatchToProps = (dispatch: any) => ({
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),
    reverseSettlement: (id: number) => dispatch(reverseSettlementsAsync(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(SettlementsWorkSpace);