import React from "react";
import { createBrowserRouter } from "react-router-dom";

import OldDashboard from "containers/dashboard";
import Dashboard from "containers/dashboard/index.v2";
import Initialize from "containers/initialize";
import Orders from "containers/orders";
import Settlements from "containers/settlements";
import Packages from "containers/packages";
import PageNotFound from "containers/pageNotFound";
import Plans from "containers/plans";
import Sellers from "containers/sellers";
import Servers from "containers/servers";
import Signin from "containers/signin";
import Users from "containers/users";
import AuthLayout from "layouts/AuthLayout";
import PanelLayout from "layouts/PanelLayout";

const Router = createBrowserRouter([
    {
        path: "/",
        element: <PanelLayout />,
        children: [
            {
                path: "/",
                element: <Initialize />,
            },
            {
                path: "/dashboard",
                element: <Dashboard />,
            },
            {
                path: "/dashboard/old",
                element: <OldDashboard />,
            },
            {
                path: "/users/:seller_id?",
                element: <Users />,
            },
            {
                path: "/users/plan/:plan_id",
                element: <Users />,
            },
            {
                path: "/plans/:plan_id?",
                element: <Plans />,
            },
            {
                path: "/packages/:package_id?",
                element: <Packages />,
            },
            {
                path: "/servers/:server_id?",
                element: <Servers />,
                // loader: () => {
                //     // if (this.props.seller.type !== "super") {
                //     //     window.location.href = "dashboard"
                //     // }
                //     return null;
                // }
            },
            {
                path: "/sellers/:seller_id?",
                element: <Sellers />,
                // loader: () => {
                //     if (this.props.seller.type === "seller") {
                //         window.location.href = "dashboard"
                //     }
                //     return null;
                // }
            },
            {
                path: "/bill/:seller_id?",
                element: <Orders />,
            },
            {
                path: "/bill/plan/:plan_id",
                element: <Orders />,
            },
            {
                path: "/settlements/:order_id?",
                element: <Settlements />,
            },
        ],
    },
    {
        path: "/auth",
        element: <AuthLayout />,
        children: [
            {
                path: "signin",
                element: <Signin />,
            },
        ],
    },
    {
        path: "*",
        element: <PageNotFound />,
    },
]);

export default Router;