import { Box, Fade, Grid, Typography } from '@mui/material'
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid'
import CenterBox from 'components/CenterBox'
import { FitBox } from 'components/Helper'
import PanelDataGrid, { ChangeDataGrid, DataGridParam } from 'components/PanelDataGrid'
import SuspenseLoading from 'components/SuspenseLoading'
import { BlackSection } from 'containers/dashboard'
import withRouter, { Router } from 'helpers/withRouter'
import { Add, Box1, Cloud } from 'iconsax-react'
import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AxiosResponse } from 'utils/axios'
import { getServersAsync } from './slice'
import { GetServersData, Server, ServersResponseData } from './types'

interface IState {
    page: number,
    limit: number,
    selectedRow: Server | null,
    createMode: boolean
}

class Servers extends Component<ServersInterface, IState> {

    state: IState = {
        page: 1,
        limit: 10,
        selectedRow: null,
        createMode: false
    }

    private columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'شناسه',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 80,
            minWidth: 80,
        },
        {
            field: 'title',
            headerName: 'عنوان',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 140,
        },
        {
            field: 'suffix',
            headerName: 'پسوند',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 70,
            maxWidth: 70,
        },
        {
            field: 'ip',
            headerName: 'IP',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 120,
            maxWidth: 120,
        },
        {
            field: 'host',
            headerName: 'آدرس',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 120,
            maxWidth: 120,
        },
        {
            field: 'capacity',
            headerName: 'ظرفیت',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 90,
            maxWidth: 90,
            renderCell: (event: GridRenderCellParams<any, Server>) => {
                return <Box>
                    {event.row.capacity} / {event.row.accounts_count}
                </Box>
            }
        },
    ];

    async componentDidMount() {
        this.onChangeDataGrip()
    }

    componentDidUpdate(prevProps: Readonly<ServersInterface>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.router.params.server_id !== this.props.router.params.server_id) {
            this.onChangeDataGrip()
        }
    }

    selectRow = (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        const id = selectionModel[0];
        const item = this.props.servers?.data.find(i => i.id === id)
        this.setState({ ...this.state, selectedRow: item || null })
    }

    unselectRow = () => {
        this.setState({ ...this.state, selectedRow: null })
    }

    onChangeDataGrip = (data: ChangeDataGrid = { page: 1, limit: 10, query: null }) => {
        return new Promise((resolve, reject) => {
            this.props.getServers({
                page: data.page,
                limit: data.limit,
                query: data.query,
                id: this.props.router.params.server_id || null
            }).then((response: AxiosResponse<ServersResponseData>) => {
                if (this.props.router.params.server_id) {
                    this.setState({ ...this.state, selectedRow: response.data.results.data[0] })
                } else {
                    this.setState({ ...this.state, selectedRow: null })
                }
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

    }

    getParams = () => {
        let params: DataGridParam[] = [];
        if (this.props.router.params.server_id) {
            params.push({
                label: `سرور انتخاب شده: #${this.props.router.params.server_id}`,
                onDelete: async () => {
                    await this.props.router.navigate('/servers')

                    this.onChangeDataGrip()
                }
            })
        }
        params.push({
            label: <CenterBox> <Add size="18" color="#28a745" /> ایجاد سرور جدید</CenterBox>,
            // onDelete: () => {},
            onClick: async () => {
                this.setState({ ...this.state, createMode: true })
            }
        })
        return params;
    }

    render() {
        const CreateWorkspace = React.lazy(() => import('./createWorkspace'))
        const Workspace = React.lazy(() => import('./workspace'))

        return (
            <Grid container sx={{ height: { lg: '100%', xs: 'calc(100% - 48px)' } }}>
                <Grid item lg={5} xs={12}>
                    <BlackSection sx={{
                        display: { xs: this.state.createMode || this.state.selectedRow ? 'block' : 'none', lg: 'block' },
                        position: { xs: 'absolute', lg: 'relative' },
                        right: 0, left: 0, bottom: 0, top: 0, zIndex: 100
                    }}>
                        {this.state.createMode ? (
                            <Suspense fallback={<SuspenseLoading />}>
                                <Fade in={true} timeout={500}>
                                    <FitBox>
                                        <CreateWorkspace refresh={this.onChangeDataGrip} close={() => this.setState({ ...this.state, createMode: false })} />
                                    </FitBox>
                                </Fade>
                            </Suspense>
                        ) : this.state.selectedRow ? (
                            <Suspense fallback={<SuspenseLoading />}>
                                <Fade in={true} timeout={500}>
                                    <FitBox>
                                        <Workspace refresh={this.onChangeDataGrip} data={this.state.selectedRow} unselectRow={this.unselectRow} />
                                    </FitBox>
                                </Fade>
                            </Suspense>
                        ) : (
                            <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Box1 size={140} color="#fff" fontWeight={200} />
                                <Typography dir="rtl" mt={3}>میز کار خالی است !</Typography>
                            </Box>
                        )}
                    </BlackSection>
                </Grid>
                <Grid item lg={7} xs={12} sx={{ backgroundColor: '#f7f7f8', height: '100%' }}>
                    <PanelDataGrid
                        sx={{ padding: { xs: '4px', lg: '16px' }, paddingTop: '16px !important' }}
                        label="سرور ها"
                        subtitle=''
                        icon={Cloud}
                        columns={this.columns}
                        rows={this.props.servers?.data}
                        loading={this.props.get_process}
                        last_page={this.props.servers?.last_page}
                        total_rows={this.props.servers?.total}
                        onChange={this.onChangeDataGrip}
                        onSelectRow={this.selectRow}
                        params={this.getParams()}
                        searchable
                    />
                </Grid>
            </Grid>
        )
    }
}

interface ServersInterface {
    getServers: (data?: GetServersData) => Promise<any>,
    servers: ServersResponseData | null,
    get_process: boolean,
    router: Router<{ server_id?: number }>
}

const mapDispatchToProps = (dispatch: any) => ({
    getServers: (data?: GetServersData) => dispatch(getServersAsync(data)),
})

const mapStateToProps = (state: AppState) => ({
    servers: state.servers.results,
    get_process: state.servers.get_process
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Servers))