import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";
import { GetOrdersData, OrdersResponseData } from "./types";

const ordersSlice = createSlice({
    name: "orders",
    initialState: {
        get_process: false,
        results: null as OrdersResponseData | null,
        settlement_process: false,
        delete_process: false,
    },
    reducers: {
        getOrdersStarted(state) {
            state.get_process = true;
            if (state.results) state.results.data = [];
        },
        getOrdersSuccess(state, action: PayloadAction<OrdersResponseData>) {
            state.results = action.payload;
            state.get_process = false;
        },
        getOrdersFailed(state, action: PayloadAction<any>) {
            state.get_process = false;
            state.results = null;
        },

        settlementStarted(state) {
            state.settlement_process = true;
        },
        settlementSuccess(state, action: PayloadAction<OrdersResponseData>) {
            state.settlement_process = false;
        },
        settlementFailed(state, action: PayloadAction<any>) {
            state.settlement_process = false;
        },

        deleteOrdersStarted(state) {
            state.delete_process = true;
        },
        deleteOrdersSuccess(state, action: PayloadAction<OrdersResponseData>) {
            state.delete_process = false;
        },
        deleteOrdersFailed(state, action: PayloadAction<any>) {
            state.delete_process = false;
        },
    },
});

// export const {} = ordersSlice.actions;

export const getOrdersAsync =
    (data?: GetOrdersData, redux: boolean = true) =>
    (dispatch: AppDispatch) => {
        if (redux) dispatch(ordersSlice.actions.getOrdersStarted());
        return new Promise((resolve, reject) => {
            let search = `?limit=${data?.limit || 10}&page=${data?.page || 1}`;
            if (data?.query) search += `&query=${data?.query}`;
            if (data?.seller_id) search += `&seller_id=${data?.seller_id}`;
            if (data?.plan_id) search += `&plan_id=${data?.plan_id}`;
            axios
                .get(api.orders + search)
                .then((response: AxiosResponse<OrdersResponseData>) => {
                    if (redux) dispatch(ordersSlice.actions.getOrdersSuccess(response.data.results));
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    if (redux) dispatch(ordersSlice.actions.getOrdersFailed(error.response?.data));
                    reject(error);
                });
        });
    };

export const settlementOrdersAsync = (seller_id: number, data?: number[]) => (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.settlementStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.orders + "/settlement", {
                orders_id: data,
                seller_id: seller_id,
            })
            .then((response: AxiosResponse) => {
                dispatch(ordersSlice.actions.settlementSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(ordersSlice.actions.settlementFailed(error.response?.data));
                reject(error);
            });
    });
};

export const deleteOrdersAsync = (orders_id: number[]) => (dispatch: AppDispatch) => {
    dispatch(ordersSlice.actions.deleteOrdersStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.orders + "/delete", orders_id)
            .then((response: AxiosResponse) => {
                dispatch(ordersSlice.actions.deleteOrdersSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(ordersSlice.actions.deleteOrdersFailed(error.response?.data));
                reject(error);
            });
    });
};

export default ordersSlice;
