import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { AppDispatch } from "redux/store";
import axios, { AxiosError, AxiosResponse } from "utils/axios";
import { CreatePlanData, GetPlansData, Plan, PlansResponseData, UpdatePlanData } from "./types";

const plansSlice = createSlice({
    name: "plans",
    initialState: {
        get_process: false,
        results: null as PlansResponseData | null,
        create_process: false,
        update_process: false,
        delete_process: false,
    },
    reducers: {
        getPlansStarted(state) {
            state.get_process = true;
            if (state.results) state.results.data = [];
        },
        getPlansSuccess(state, action: PayloadAction<PlansResponseData>) {
            state.results = action.payload;
            state.get_process = false;
        },
        getPlansFailed(state, action: PayloadAction<any>) {
            state.get_process = false;
            state.results = null;
        },

        createPlansStarted(state) {
            state.create_process = true;
        },
        createPlansSuccess(state, action: PayloadAction<PlansResponseData>) {
            state.create_process = false;
        },
        createPlansFailed(state, action: PayloadAction<any>) {
            state.create_process = false;
        },

        updatePlansStarted(state) {
            state.update_process = true;
        },
        updatePlansSuccess(state, action: PayloadAction<Plan>) {
            state.update_process = false;
            if (state.results) {
                state.results.data = state.results.data.map((item) => {
                    if (item.id === action.payload.id) {
                        item = action.payload;
                    }
                    return item;
                });
            }
        },
        updatePlansFailed(state, action: PayloadAction<any>) {
            state.update_process = false;
        },

        deletePlansStarted(state) {
            state.delete_process = true;
        },
        deletePlansSuccess(state, action: PayloadAction<PlansResponseData>) {
            state.delete_process = false;
        },
        deletePlansFailed(state, action: PayloadAction<any>) {
            state.delete_process = false;
        },
    },
});

// export const {} = plansSlice.actions;

export const getPlansAsync =
    (data?: GetPlansData, redux: boolean = true) =>
    (dispatch: AppDispatch) => {
        if (redux) dispatch(plansSlice.actions.getPlansStarted());
        return new Promise((resolve, reject) => {
            let search = `?type=plan&limit=${data?.limit || 10}&page=${data?.page || 1}`;
            if (data?.query) search += `&query=${data?.query}`;
            if (data?.id) search += `&id=${data?.id}`;
            if (data?.sort) search += `&sort=${data?.sort}`;
            if (data?.is_seller) search += `&is_seller=${data?.is_seller}`;
            axios
                .get(api.plans + search)
                .then((response: AxiosResponse<PlansResponseData>) => {
                    if (redux) dispatch(plansSlice.actions.getPlansSuccess(response.data.results));
                    resolve(response);
                })
                .catch((error: AxiosError) => {
                    if (redux) dispatch(plansSlice.actions.getPlansFailed(error.response?.data));
                    reject(error);
                });
        });
    };

export const createPlansAsync = (data?: CreatePlanData) => (dispatch: AppDispatch) => {
    dispatch(plansSlice.actions.createPlansStarted());
    return new Promise((resolve, reject) => {
        axios
            .post(api.plans, data)
            .then((response: AxiosResponse) => {
                dispatch(plansSlice.actions.createPlansSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(plansSlice.actions.createPlansFailed(error.response?.data));
                reject(error);
            });
    });
};

export const updatePlansAsync = (id: number, data?: UpdatePlanData) => (dispatch: AppDispatch) => {
    dispatch(plansSlice.actions.updatePlansStarted());
    return new Promise((resolve, reject) => {
        axios
            .put(api.plans + "/" + id, data)
            .then((response: AxiosResponse<Plan>) => {
                dispatch(plansSlice.actions.updatePlansSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(plansSlice.actions.updatePlansFailed(error.response?.data));
                reject(error);
            });
    });
};

export const deletePlansAsync = (plan_id: number) => (dispatch: AppDispatch) => {
    dispatch(plansSlice.actions.deletePlansStarted());
    return new Promise((resolve, reject) => {
        axios
            .delete(api.plans + "/" + plan_id)
            .then((response: AxiosResponse) => {
                dispatch(plansSlice.actions.deletePlansSuccess(response.data.results));
                resolve(response);
            })
            .catch((error: AxiosError) => {
                dispatch(plansSlice.actions.deletePlansFailed(error.response?.data));
                reject(error);
            });
    });
};

export default plansSlice;
