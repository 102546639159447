import { Box, Button, Grid, Typography } from '@mui/material'
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid'
import PanelDataGrid, { ChangeDataGrid, DataGridParam } from 'components/PanelDataGrid'
import { BlackSection } from 'containers/dashboard'
import Timeline from 'containers/users/timeline'
import { numberFormat, toJalaliDate } from 'helpers/general'
import withRouter, { Router } from 'helpers/withRouter'
import { Box1, MoneyRecive } from 'iconsax-react'
import moment from 'jalali-moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setDialogTo } from 'redux/slice'
import { AppState } from 'redux/store'
import { SetDialogPayload } from 'redux/types'
import { AxiosError, AxiosResponse } from 'utils/axios'
import { getOrdersAsync } from './slice'
import { GetOrdersData, Order, OrdersResponseData } from './types'
import OrdersWorkSpace from './workspace'
moment.locale('fa');

interface IState {
    page: number,
    limit: number,
    selectedRows: any[],
    createMode: boolean
}

interface OrdersInterface extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
    router: Router<{ seller_id?: number, plan_id?: number }>
}

class Orders extends Component<OrdersInterface, IState> {

    state: IState = {
        page: 1,
        limit: 10,
        selectedRows: [],
        createMode: false
    }

    private columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'شناسه',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 80,
            minWidth: 80
        },
        {
            field: 'amount',
            headerName: 'مبلغ',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 120,
            minWidth: 120,
            renderCell: (event: GridRenderCellParams<any, Order>) => {
                return <Typography>{numberFormat(event.row.amount)} ریالءء</Typography>
            }
        },
        {
            field: 'seller.full_name',
            headerName: 'فروشنده',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 140,
            renderCell: (event: GridRenderCellParams<any, Order>) => {
                return <Typography>{event.row.seller.full_name}</Typography>
            }
        },
        {
            field: 'account.user.mobile',
            headerName: 'کاربر',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 120,
            renderCell: (event: GridRenderCellParams<any, Order>) => {
                return <Button onClick={this.showHistory(event.row)} sx={{ color: event.row.account.deleted_at !== null ? "red" : undefined }}>{event.row.account?.user?.mobile}</Button>
            }
        },
        {
            field: 'type',
            headerName: 'بابت',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 120,
            minWidth: 120,
            renderCell: (event: GridRenderCellParams<any, Order>) => {
                switch (event.row.type) {
                    case "subscribed":
                        return <Typography>اشتراک جدید</Typography>
                    case "renewal":
                        return <Typography>تمدید</Typography>
                    case "add_package":
                        return <Typography>بسته افزایشی</Typography>
                    case "delete":
                        return <Typography>حذف مصرف دار</Typography>
                    default: return <Typography>{event.row.type}</Typography>
                }
            }
        },
        {
            field: 'created_at',
            headerName: 'تاریخ ایجاد',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 160,
            minWidth: 160,
            renderCell: (event: GridRenderCellParams<any, Order>) => {
                return <Typography>{toJalaliDate(event.row.created_at, 'HH:mm YYYY-MM-DD')}</Typography>
            }
        },
    ];

    async componentDidMount() {
        this.onChangeDataGrip()
    }

    UNSAFE_componentWillMount(): void {

        if (this.props.seller.type === "seller") {
            this.columns = this.columns.filter((column: GridColDef) => {
                return column.field !== "seller.full_name"
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<OrdersInterface>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.router.params.seller_id !== this.props.router.params.seller_id) {
            this.onChangeDataGrip()
        }
    }

    showHistory = (order: Order) => (event: any) => {
        event.stopPropagation()
        this.props.setDialogTo({
            title: 'تاریخچه',
            content: () => <Timeline mobile={order.account.user.mobile} />,
        })
    }

    selectRow = (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        this.setState({ ...this.state, selectedRows: selectionModel })
    }

    unselectRow = () => {
        this.setState({ ...this.state, selectedRows: [] }, () => {
            this.onChangeDataGrip()
        })
    }

    onChangeDataGrip = (data: ChangeDataGrid = { page: 1, limit: 10, query: null, filter: null }) => {
        return new Promise((resolve, reject) => {
            this.props.getOrders({
                page: data.page,
                limit: data.limit,
                query: data.query,
                seller_id: this.props.router.params.seller_id || null,
                plan_id: this.props.router.params.plan_id || null,
            }).then((response: AxiosResponse<OrdersResponseData>) => {
                resolve(response)
            }).catch((error: AxiosError) => {
                reject(error)
            })
        })
    }

    getParams = () => {
        let params: DataGridParam[] = [];
        if (this.props.router.params.seller_id) {
            const sellerName = this.props.orders?.data[0]?.seller?.full_name;
            params.push({
                label: `فروشنده: #${sellerName || this.props.router.params.seller_id}`,
                onDelete: async () => {
                    await this.props.router.navigate('/bill')

                    this.onChangeDataGrip()
                }
            })
        }

        if (this.props.router.params.plan_id) {
            const planTitle = this.props.orders?.data[0]?.plan?.title;
            params.push({
                label: `پلن: #${planTitle || this.props.router.params.plan_id}`,
                onDelete: async () => {
                    await this.props.router.navigate('/bill')

                    this.onChangeDataGrip()
                }
            })
        }

        return params;
    }

    render() {

        return (
            <Grid container sx={{ height: { lg: '100%', xs: 'calc(100% - 48px)' } }}>
                <Grid item lg={3.5} xs={12}>
                    <BlackSection sx={{
                        display: { xs: this.state.createMode || this.state.selectedRows.length ? 'block' : 'none', lg: 'block' },
                        position: { xs: 'absolute', lg: 'relative' },
                        right: 0, left: 0, bottom: 0, top: 0, zIndex: 100
                    }}>
                        {this.state.selectedRows ? (
                            <OrdersWorkSpace refresh={this.onChangeDataGrip} data={this.state.selectedRows} unselectRow={this.unselectRow} />
                        ) : (
                            <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <Box1 size={140} color="#fff" fontWeight={200} />
                                <Typography dir="rtl" mt={3}>میز کار خالی است !</Typography>
                            </Box>
                        )}
                    </BlackSection>
                </Grid>
                <Grid item lg={8.5} xs={12} sx={{ backgroundColor: '#f7f7f8', height: '100%' }}>
                    <PanelDataGrid
                        sx={{ padding: { xs: '4px', lg: '16px' }, paddingTop: '16px !important' }}
                        label="صورتحساب ها"
                        subtitle=''
                        icon={MoneyRecive}
                        columns={this.columns}
                        rows={this.props.orders?.data}
                        loading={this.props.get_process}
                        last_page={this.props.orders?.last_page}
                        total_rows={this.props.orders?.total}
                        onChange={this.onChangeDataGrip}
                        onSelectRow={this.selectRow}
                        params={this.getParams()}
                        searchable
                        dateGridProps={{
                            checkboxSelection: true,
                            disableSelectionOnClick: true
                        }}
                        hasAllLimit={true}
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getOrders: (data?: GetOrdersData) => dispatch(getOrdersAsync(data)),
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),

})

const mapStateToProps = (state: AppState) => ({
    orders: state.orders.results,
    get_process: state.orders.get_process,
    seller: state.signin.seller
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders))