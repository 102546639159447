import { Box, Grid } from '@mui/material'
import { styled } from "@mui/system"

import WallpaperSrc from "assets/images/auth_wallpaper.jpg"
import withRouter, { Router } from 'helpers/withRouter'
import React, { Component, Fragment } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
const Wallpaper = styled(Box)({
    backgroundImage: `url(${WallpaperSrc})`,
    height: '100%',
    width: '100%',
    backgroundSize: 'cover'
})

interface AuthLayoutProps {
    router: Router
}
class AuthLayout extends Component<AuthLayoutProps> {
    render() {
        return (
            <Fragment>
                {this.props.router.location.pathname === "/auth" ? (
                    <Navigate to={'/auth/signin'} replace={true} />
                ) : null}
                <Grid container sx={{ height: '100%' }}>
                    <Grid item xs={12} lg={6}>
                        <Wallpaper />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

export default withRouter(AuthLayout);
