import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setDialogTo } from "redux/slice";
import { AppState } from "redux/store";
import { SetDialogButtons, SetDialogOptions, SetDialogPayload } from "redux/types";
import Rtl from "./Rtl";

class Dialog extends Component<DialogProps> {
    handleClose = () => {
        this.props.setDialogTo(false);
    };

    render() {
        return (
            <Rtl>
                <MuiDialog open={this.props.open} onClose={this.handleClose} PaperProps={{ sx: { width: { xs: '98%', lg: this.props.options?.width || 500 }, height: this.props.options?.height || 'auto', maxWidth: 'unset', margin: { xs: '0px !important', lg: '32px' }, direction: "ltr" } }}>
                    <DialogTitle>{this.props.options?.title}</DialogTitle>
                    <DialogContent sx={{ padding: { xs: '8px', lg: '20px 24px' } }}>
                        <DialogContentText>{this.props.options?.description}</DialogContentText>
                        <Fragment>{this.props.options?.content ? this.props.options?.content() : null}</Fragment>
                    </DialogContent>
                    {this.props.options?.buttons?.length ? (
                        <DialogActions>
                            {this.props.options?.buttons?.map((buttonProps: SetDialogButtons, index) => {
                                const { title, ...props } = buttonProps;
                                return (
                                    <Button key={index} {...props}>
                                        {title}
                                    </Button>
                                );
                            })}
                        </DialogActions>
                    ) : null}
                </MuiDialog>
            </Rtl>
        );
    }
}
interface DialogProps {
    setDialogTo: (payload: SetDialogPayload) => void;
    open: boolean;
    options: SetDialogOptions | null;
}

const mapDispatchToProps = (dispatch: any) => ({
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),
});

const mapStateToProps = (state: AppState) => ({
    open: state.general.dialog.open,
    options: state.general.dialog.options,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
