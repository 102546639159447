import { Box, CircularProgress, Fade, Grid, Grow, Switch, Tooltip, Typography } from '@mui/material'
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid'
import CenterBox from 'components/CenterBox'
import { FitBox } from 'components/Helper'
import PanelDataGrid, { ChangeDataGrid, DataGridParam } from 'components/PanelDataGrid'
import SuspenseLoading from 'components/SuspenseLoading'
import { BlackSection } from 'containers/dashboard'
import { getPackagesAsync } from 'containers/packages/slice'
import { Package } from 'containers/packages/types'
import { getPlansAsync } from 'containers/plans/slice'
import { GetPlansData, Plan } from 'containers/plans/types'
import { getSellersAsync } from 'containers/sellers/slice'
import { GetSellersData, Seller } from 'containers/sellers/types'
import { getServersAsync } from 'containers/servers/slice'
import { GetServersData, Server } from 'containers/servers/types'
import { clone, humanFileSize, setPageLoadingTo } from 'helpers/general'
import withRouter, { Router } from 'helpers/withRouter'
import { Add, ArrangeVertical, Box1, Calendar, CloseSquare, DollarSquare, Timer, User as UserIcon } from 'iconsax-react'
import moment from 'jalali-moment'
import React, { Component, Fragment, Suspense } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { setDialogTo } from 'redux/slice'
import { AppState } from 'redux/store'
import { SetDialogPayload } from 'redux/types'
import { AxiosError, AxiosResponse } from 'utils/axios'
import { changeUserAccountStatusAsync, getTimelineByMobileAsync, getUsersAsync } from './slice'
import Timeline from './timeline'
import { GetUsersData, User, UsersResponseData } from './types'
moment.locale('fa');

interface IState {
    page: number,
    limit: number,
    filter: string | null
    selectedRow: User | null,
    createMode: boolean,

    requirements_loading: boolean,
    servers: Server[],
    plans: Plan[],
    sellers: Seller[],
    packages: Package[]
}

interface UsersInterface extends ReturnType<typeof mapDispatchToProps>, ReturnType<typeof mapStateToProps> {
    router: Router<{ seller_id?: number, plan_id?: number }>
}

class Users extends Component<UsersInterface, IState> {

    state: IState = {
        page: 1,
        limit: 10,
        filter: null,
        selectedRow: null,
        createMode: false,

        requirements_loading: false,
        servers: [],
        plans: [],
        sellers: [],
        packages: [],
    }

    private columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'شناسه',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 80,
            minWidth: 80,
        },
        {
            field: 'mobile',
            headerName: 'موبایل',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 110,
            minWidth: 110,
        },
        {
            field: 'full_name',
            headerName: 'نام',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 140,
            renderCell: event => {
                return <Typography sx={{ fontWeight: 400 }}>{event.row.full_name}</Typography>
            }
        },
        {
            field: 'xui_expire',
            headerName: 'انقضا',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 160,
            minWidth: 160,
            renderCell: (event: GridRenderCellParams<any, User>) => {
                if (!event.row.xui) return "خارج از دسترس"
                if (event.row.xui.expiryTime === 0) {
                    return <Typography color="skyblue">سررسید</Typography>
                }

                if (Date.now() > event.row.xui.expiryTime) {
                    return <Box sx={{ width: '100%', direction: 'rtl', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{moment(event.row.xui.expiryTime).format('YYYY/MM/DD')}</span>
                        <Typography color={'red'}>منقضی</Typography>
                    </Box>
                }

                const expire_raw = (moment(event.row.xui.expiryTime).startOf('day').unix() - moment().startOf('day').unix()) / 60 / 60 / 24;
                const expire_day = Math.ceil(expire_raw);

                if (expire_day === 1) {
                    return <Box sx={{ width: '100%', direction: 'rtl', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{moment(event.row.xui.expiryTime).format('YYYY/MM/DD')}</span>
                        <Typography color="green">{`فردا ${moment(event.row.xui.expiryTime).format('HH:mm')}`}</Typography>
                    </Box>
                }

                if (expire_day === 0) {
                    return <Box sx={{ width: '100%', direction: 'rtl', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{moment(event.row.xui.expiryTime).format('YYYY/MM/DD')}</span>
                        <Typography color="blueviolet">{`امروز ${moment(event.row.xui.expiryTime).format('HH:mm')}`}</Typography>
                    </Box>
                }



                return <Box sx={{ width: '100%', direction: 'rtl', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{moment(event.row.xui.expiryTime).format('YYYY/MM/DD')}</span>
                    <span style={{ direction: 'rtl' }}>{`${expire_day} روز`}</span>
                </Box>
            },
        },
        {
            field: 'xui_traffic',
            headerName: 'ترافیک ⇅',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 200,
            maxWidth: 200,
            renderCell: (event: GridRenderCellParams<any, User>) => {
                if (!event.row.xui) return "خارج از دسترس";
                let percent: any = false;
                const xui = event.row.xui;
                if (xui.total > 0) {
                    const remain = xui.total - (xui.up + xui.down);
                    const used = xui.total - remain;
                    percent = Math.round((used * 100) / xui.total);
                }

                const hasTraffic = event.row.xui.total > 0 ? event.row.xui.up + event.row.xui.down <= event.row.xui.total : true;
                return <Box sx={{ width: 'auto', direction: 'rtl', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{
                        backgroundColor: hasTraffic ? '#e6f7ff' : '#fff1f0',
                        color: hasTraffic ? '#1890ff' : '#FF084A',
                        padding: '2px 6px',
                        lineHeight: '17px',
                        paddingTop: '5px',
                        borderRadius: '4px',
                        border: hasTraffic ? '1px solid #91d5ff' : '1px solid #FF084A',
                        ml: 1,
                    }}>
                        {percent !== false ? (
                            <Fragment>
                                {percent}% / {" "}
                            </Fragment>
                        ) : null}
                        {humanFileSize(event.row.xui.up + event.row.xui.down)}</Box>
                    {event.row.xui.total > 0 ? (
                        <Box sx={{
                            backgroundColor: '#e6fffb',
                            color: '#13c2c2',
                            padding: '2px 6px',
                            lineHeight: '17px',
                            paddingTop: '5px',
                            borderRadius: '4px',
                            border: '1px solid #87e8de'
                        }}>{humanFileSize(event.row.xui.total)}</Box>
                    ) : null}
                </Box>
            }
        },
        {
            field: 'server',
            headerName: 'سرور',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 80,
            minWidth: 80,

            renderCell: (event: GridRenderCellParams<any, User>) => {
                return <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {event.row.accounts[0].server.suffix}
                </Box>
            }
        },
        {
            field: 'seller',
            headerName: 'فروشنده',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 120,
            maxWidth: 140,

            renderCell: (event: GridRenderCellParams<any, User>) => {
                return <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {event.row.accounts[0].seller?.full_name}
                </Box>
            }
        },
        {
            field: 'status',
            headerName: 'وضعیت',
            sortable: false,
            disableColumnMenu: true,
            maxWidth: 90,
            minWidth: 90,

            renderCell: (event: GridRenderCellParams<any, User>) => {
                if (!event.row.xui) return "خارج از دسترس"
                return <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Switch checked={event.row.xui.enable} onChange={this.changeAccountStatus(event.row)} />
                </Box>
            }
        },
        // {
        //     field: 'created_at',
        //     headerName: 'ایجاد شده در',
        //     sortable: false,
        //     disableColumnMenu: true,
        //     flex: 1,
        //     maxWidth: 200,
        //     valueGetter: event => {
        //         return toJalaliDate(event.row.created_at)
        //     }
        // },
    ];

    UNSAFE_componentWillMount(): void {
        if (this.props.seller.type === "seller") {
            this.columns = this.columns.filter((column: GridColDef) => {
                return column.field !== "seller"
            })
        }
    }

    async componentDidMount() {
        this.onChangeDataGrip()

        this.setState({ ...this.state, requirements_loading: true, filter: this.props.router.location.state?.filter || null })

        const servers_response = await this.props.getServers({ limit: 1000 })
        const plans_response = await this.props.getPlans({ limit: 1000, sort: 'view_priority,desc' })
        const packages_response = await this.props.getPackages({ limit: 1000, sort: 'view_priority,desc' })
        const sellers_response = await this.props.getSellers({ limit: 1000 })

        this.setState({
            ...this.state,
            servers: clone(servers_response.data.results.data),
            plans: clone(plans_response.data.results.data),
            packages: clone(packages_response.data.results.data),
            sellers: clone(sellers_response.data.results.data),
            requirements_loading: false
        })

    }

    componentDidUpdate(prevProps: Readonly<UsersInterface>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.router.params.seller_id !== this.props.router.params.seller_id) {
            this.onChangeDataGrip()
        }

        if (this.state.selectedRow && this.props.users) {
            const newSelectedRow = this.props.users.data.find((user: User) => {
                return user.id === this.state.selectedRow?.id;
            })
            if (newSelectedRow && JSON.stringify(newSelectedRow) !== JSON.stringify(this.state.selectedRow)) {
                this.setState({ ...this.state, selectedRow: newSelectedRow })
            }

        }
    }

    changeAccountStatus = (user: User) => (_: any, status: boolean) => {
        setPageLoadingTo(status ? "درحال فعالسازی حساب" : 'درحال غیر فعالسازی حساب')
        this.props.changeUserAccountStatusAsync(user.id, status).then((response: AxiosResponse) => {
            setPageLoadingTo(false)
        }).catch((error: AxiosError) => {
            toast.error('خطا در تغییر وضعیت حساب / مجدد تلاش کنید')
            setPageLoadingTo(false)
        })

    }

    selectRow = (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        const id = selectionModel[0];
        const item = this.props.users?.data.find(i => i.id === id)
        if (!item) return null;

        if (item?.xui) {
            if (this.state.selectedRow?.id !== item?.id) {
                this.setState({ ...this.state, selectedRow: null, createMode: false }, () => {
                    this.setState({ ...this.state, selectedRow: item, createMode: false })
                })
            }
        } else {
            toast.warn(<Box>
                <Typography>اطلاعات کاربر در دسترس نیست !</Typography>
                <Typography sx={{ fontSize: 12 }}>ممکن است شما به سرور پشت این کاربر دسترسی نداشته باشید</Typography>
            </Box>)
        }
    }

    unselectRow = () => {
        this.setState({ ...this.state, selectedRow: null, createMode: false })
    }

    onChangeDataGrip = (data: ChangeDataGrid = { page: 1, limit: 10, query: null, filter: null }) => {
        if (!data.filter) { data.filter = this.state.filter; }
        this.setState({ ...this.state, selectedRow: null, createMode: false })
        return new Promise((resolve, reject) => {
            this.props.getUsers({
                page: data.page,
                limit: data.limit,
                query: data.query,
                seller_id: this.props.router.params.seller_id || null,
                plan_id: this.props.router.params.plan_id || null,
                filter: this.props.router.location?.state?.filter || data.filter
            }).then((response: AxiosResponse<UsersResponseData>) => {
                resolve(response)
            }).catch((error: AxiosError) => {
                toast.error('خطا در ارتباط با سرور / مجدد تلاش کنید')
                reject(error)
            })
        })

    }

    findTimelineByMobile = () => {
        this.props.setDialogTo({
            title: 'تاریخچه',
            content: () => <Timeline />,
        })
    }

    getParams = () => {
        let params: DataGridParam[] = [];
        if (this.props.router.params.seller_id) {
            const sellerName = this.props.users?.data[0]?.accounts[0]?.seller?.full_name;
            params.push({
                label: `فروشنده: ${sellerName || "#" + this.props.router.params.seller_id}`,
                onDelete: async () => {
                    await this.props.router.navigate('/users')
                    this.onChangeDataGrip()
                }
            })
        }

        if (this.props.router.params.plan_id) {
            const planName = this.props.users?.data[0]?.accounts[0]?.plan?.title;
            params.push({
                label: `پلن: ${planName || "#" + this.props.router.params.plan_id}`,
                onDelete: async () => {
                    await this.props.router.navigate('/users')
                    this.onChangeDataGrip()
                }
            })
        }

        params.push({
            label: <CenterBox> {this.state.requirements_loading ? <CircularProgress size={10} color="inherit" sx={{ mr: 1 }} /> : <Add size="18" color={this.state.createMode ? '#fff' : '#28a745'} />} ایجاد اکانت جدید</CenterBox>,
            activeColor: '#28a745',
            isActive: this.state.createMode,
            disabled: this.state.requirements_loading,
            onClick: async () => {
                const current: boolean = this.state.createMode;

                this.setState({ ...this.state, selectedRow: null, createMode: false }, () => {
                    this.setState({ ...this.state, createMode: !current, selectedRow: null })
                })
            }
        })

        params.push({
            label: <CenterBox><ArrangeVertical size="18" color={this.state.filter === "up_to_80" ? "#fff" : "#FF8A65"} style={{ marginRight: 6 }} />بیشتر از ۸۰ درصد</CenterBox>,
            isActive: this.state.filter === 'up_to_80',
            activeColor: "#FF8A65",
            onClick: async () => {
                await this.setState({ ...this.state, filter: this.state.filter === 'up_to_80' ? null : 'up_to_80' }, () => {
                    this.onChangeDataGrip()
                })
            }
        })
        params.push({
            label: <CenterBox><Calendar size="18" color={this.state.filter === "expiring" ? "#fff" : "#BF9B30"} style={{ marginRight: 6 }} />درحال انقضا</CenterBox>,
            isActive: this.state.filter === 'expiring',
            activeColor: "#BF9B30",
            onClick: async () => {
                await this.setState({ ...this.state, filter: this.state.filter === 'expiring' ? null : 'expiring' }, () => {
                    this.onChangeDataGrip()
                })
            }
        })
        params.push({
            label: <CenterBox><CloseSquare size="18" color={this.state.filter === "disables" ? "#fff" : "#FF084A"} style={{ marginRight: 6 }} />غیرفعال ها</CenterBox>,
            isActive: this.state.filter === 'disables',
            activeColor: "#FF084A",
            onClick: async () => {
                await this.setState({ ...this.state, filter: this.state.filter === 'disables' ? null : 'disables' }, () => {
                    this.onChangeDataGrip()
                })
            }
        })
        if (this.props.seller.type === "super") {
            params.push({
                label: <CenterBox><DollarSquare size="18" color={this.state.filter === "need_to_pay" ? "#fff" : "#23A16E"} style={{ marginRight: 6 }} />سررسید</CenterBox>,
                isActive: this.state.filter === 'need_to_pay',
                activeColor: "#23A16E",
                onClick: async () => {
                    await this.setState({ ...this.state, filter: this.state.filter === 'need_to_pay' ? null : 'need_to_pay' }, () => {
                        this.onChangeDataGrip()
                    })
                }
            })
        }
        return params;
    }

    render() {
        const CreateWorkspace = React.lazy(() => import('./createWorkspace'))
        const Workspace = React.lazy(() => import('./workspace'))

        return (
            <Grid container sx={{ height: { lg: '100%', xs: 'calc(100% - 48px)' } }}>
                <Grid item lg={3.5} xs={12}>
                    <BlackSection sx={{
                        display: { xs: this.state.createMode || this.state.selectedRow ? 'block' : 'none', lg: 'block' },
                        position: { xs: 'absolute', lg: 'relative' },
                        right: 0, left: 0, bottom: 0, top: 0, zIndex: 100
                    }}>
                        {this.state.createMode ? (
                            <Suspense fallback={<SuspenseLoading />}>
                                <Fade in={true} timeout={500}>
                                    <FitBox>
                                        <CreateWorkspace refresh={this.onChangeDataGrip} close={() => this.setState({ ...this.state, createMode: false })}
                                            plans={this.state.plans} sellers={this.state.sellers} servers={this.state.servers}
                                        />
                                    </FitBox>
                                </Fade>
                            </Suspense>
                        ) : this.state.selectedRow ? (
                            <Suspense fallback={<SuspenseLoading />}>
                                <Fade in={true} timeout={500}>
                                    <FitBox>
                                        <Workspace refresh={this.onChangeDataGrip} data={this.state.selectedRow} unselectRow={this.unselectRow} plans={this.state.plans} packages={this.state.packages} />
                                    </FitBox>
                                </Fade>
                            </Suspense>
                        ) : (
                            <Grow in={true} timeout={800}>
                                <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Box1 size={140} color="#fff" fontWeight={200} />
                                    <Typography dir="rtl" mt={3}>میز کار خالی است !</Typography>
                                </Box>
                            </Grow>
                        )}
                    </BlackSection>
                </Grid>
                <Grid item lg={8.5} xs={12} sx={{ backgroundColor: '#f7f7f8', height: '100%' }}>
                    {/* <Button onClick={this.findTimelineByMobile}>
                        show time line
                    </Button> */}
                    <PanelDataGrid
                        sx={{ padding: { xs: '4px', lg: '16px' }, paddingTop: '16px !important' }}
                        label="کاربران"
                        subtitle=''
                        icon={UserIcon}
                        columns={this.columns}
                        rows={this.props.users?.data}
                        loading={this.props.get_process}
                        last_page={this.props.users?.last_page}
                        total_rows={this.props.users?.total}
                        onChange={this.onChangeDataGrip}
                        onSelectRow={this.selectRow}
                        params={this.getParams()}
                        extraButtons={[
                            {
                                icon: <Tooltip title="تاریخچه" arrow placement="right"><Timer size={24} color="#219ebc" /></Tooltip>,
                                color: 'info',
                                size: 'small',
                                sx: { ml: 2 },
                                onClick: this.findTimelineByMobile
                            }
                        ]}
                        searchable
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getUsers: (data?: GetUsersData) => dispatch(getUsersAsync(data)),
    changeUserAccountStatusAsync: (user_id: number, status: boolean) => dispatch(changeUserAccountStatusAsync(user_id, status)),
    getServers: (data?: GetServersData) => dispatch(getServersAsync(data, false)),
    getPlans: (data?: GetPlansData) => dispatch(getPlansAsync(data, false)),
    getPackages: (data?: GetPlansData) => dispatch(getPackagesAsync(data, false)),
    getSellers: (data?: GetSellersData) => dispatch(getSellersAsync(data, false)),
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),
    getTimelineByMobile: (mobile: string) => dispatch(getTimelineByMobileAsync(mobile)),
})

const mapStateToProps = (state: AppState) => ({
    users: state.users.results,
    get_process: state.users.get_process,
    seller: state.signin.seller
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users))