import { Avatar, Box, Button, Divider, IconButton, Skeleton, SwipeableDrawer, Tooltip, Typography } from '@mui/material'
import { styled } from "@mui/system"
import menu from "constants/menu"
import { checkAuthAsync, logoutAsync } from 'containers/signin/slice'
import { numberFormat, setPageLoadingTo } from 'helpers/general'
import withRouter, { Router } from 'helpers/withRouter'
import { HambergerMenu, Logout, Refresh, User as UserIcon } from 'iconsax-react'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setDialogTo } from 'redux/slice'
import { AppState } from 'redux/store'
import { SetDialogPayload } from 'redux/types'
import packageJson from '../../package.json'
import CenterBox from './CenterBox'
import Rtl from './Rtl'

const SidebarWrapper = styled(Box)({
    // padding: '24px',
    backgroundColor: '#fff',
    height: '100%',
    // borderLeft: '1px solid #F3F3F3',
    position: 'relative'
})

// const StyledSidebarLogo = styled(SidebarLogo)({
//     width: '100%'
// })

const NavLinksWrapper = styled(Box)({
    // marginTop: 16
})

const StyledNavLink = styled(NavLink)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textDecoration: 'unset',
    height: 56,
    padding: '0 16px',
    marginBottom: 0,
    transition: '0.2s',
    '&:hover': {
        backgroundColor: '#FF8A654a',
    }
})

const StyledNavButton = styled(Button)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textDecoration: 'unset',
    height: 56,
    padding: '0 16px',
    marginBottom: 0,
    transition: '0.2s',
    borderRadius: 0,
    '&:hover': {
        backgroundColor: '#FF8A654a',
    }
})

const NavLinkLabel = styled(Typography)({
    color: '#21201F',
    paddingRight: 16,
    fontSize: '17px'
})

const UserSection = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // borderTop: '1px solid #F3F3F3',
    borderBottom: '1px solid #F3F3F3',
    // paddingTop: 16,
    padding: 16,
})

const Version = styled(Box)({
    position: 'absolute',
    bottom: '8px',
    right: 0,
    left: 0,
    textAlign: 'center',
    fontSize: 14,
    opacity: 0.6
})

interface IState {
    isDesktop: boolean,
    drawerIsOpen: boolean
}

interface SidebarProps extends ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps> {
    router: Router
}

class Sidebar extends Component<SidebarProps, IState> {

    state: IState = {
        isDesktop: false,
        drawerIsOpen: false,
    }

    componentDidMount(): void {
        this.setState({ ...this.state, isDesktop: window.innerWidth > 1199 })
    }

    logout = (e: any) => {
        this.props.setDialogTo({
            title: 'خروج',
            description: 'از حساب کاربری خارج می شوید ؟',
            buttons: [
                {
                    title: 'تایید / خروج از حساب کاربری',
                    color: 'error',
                    variant: 'contained',
                    fullWidth: true,
                    onClick: () => {
                        setPageLoadingTo('درحال خروج')
                        this.props.setDialogTo(false);

                        this.props.logout().then(() => {
                            setPageLoadingTo(false)
                            localStorage.removeItem('accessToken')
                            this.props.router.navigate('/auth/signin')
                        }).catch(() => {
                            toast.error('خروج با خطا مواجه شد !')
                            setPageLoadingTo(false)
                        })
                    }
                },
                {
                    title: 'خیر',
                    onClick: () => {
                        this.props.setDialogTo(false)
                    }
                }
            ]
        })
        // e.stopPropagation()
        // localStorage.removeItem('accessToken')
    }

    setDrawerOpen = (value: boolean) => () => {
        this.setState({ ...this.state, drawerIsOpen: value })
    }

    checkIsActive = (item: any) => {
        const sp_pathname = this.props.router.location.pathname.split('/').reverse();
        let pathname = sp_pathname[0];
        if (!isNaN(parseInt(pathname))) pathname = sp_pathname[1];
        let route = item.route.split('/').reverse()[0];
        return pathname === route;
    }

    refreshDebt = () => {
        this.props.checkAuth()
    }

    render() {
        const seller = this.props.seller;
        const menus = menu[seller?.type] || []
        return this.state.isDesktop ? (
            <SidebarWrapper>
                <UserSection>

                    <Rtl sx={{ ml: 1, width: '100%' }}>
                        <Typography sx={{ mb: '2px' }}>{this.props.user?.full_name}</Typography>
                        <Typography sx={{ fontSize: 14, color: '#505050' }}>{this.props.user?.mobile}</Typography>

                        {this.props.check_in_process ? (
                            <CenterBox sx={{ width: '100%', justifyContent: 'space-between' }}>
                                <Skeleton width={100} height={26} sx={{ bgcolor: '#f7f7f8' }} />
                                <IconButton size='small' disabled><Refresh color="#FF8A65" size={16} /></IconButton>
                            </CenterBox>
                        ) : seller.debt ? (
                            <CenterBox sx={{ width: '100%', justifyContent: 'space-between' }}>
                                <Tooltip arrow placement='left' title="میزان بدهی">
                                    <Typography sx={{ fontSize: 14, color: '#505050' }}>{numberFormat(seller.debt?.amount)} ریالءء</Typography>
                                </Tooltip>
                                <IconButton size='small' onClick={this.refreshDebt}><Refresh color="#FF8A65" size={16} /></IconButton>
                            </CenterBox>
                        ) : seller.unpaid_orders_amount ? (
                            <CenterBox sx={{ width: '100%', justifyContent: 'space-between' }}>
                                <Tooltip arrow placement='left' title="تسویه نشده">
                                    <Typography sx={{ fontSize: 14, color: '#505050' }}>{numberFormat(seller.unpaid_orders_amount)} ریالءء</Typography>
                                </Tooltip>
                                <IconButton size='small' onClick={this.refreshDebt}><Refresh color="#FF8A65" size={16} /></IconButton>
                            </CenterBox>
                        ) : null}
                    </Rtl>
                    <Box>
                        <Avatar sx={{ backgroundColor: '#f7f7f8', height: 64, width: 64 }}>
                            {seller.logo ? (
                                <img style={{ height: '70%' }} src={seller.logo} alt={seller.branch.title} />
                            ) : seller.branch.logo ? (
                                <img style={{ height: '70%' }} src={seller.branch.logo} alt={seller.branch.title} />
                            ) : (
                                <UserIcon color="#242424" />
                            )}
                        </Avatar>
                    </Box>
                </UserSection>

                <NavLinksWrapper>
                    {menus.map((item, key) => {
                        const isActive = this.checkIsActive(item)
                        return (
                            <StyledNavLink key={key} to={item.route} style={isActive ? { backgroundColor: '#FF8A65' } : undefined} end>
                                <NavLinkLabel>{item.label}</NavLinkLabel>
                                <item.icon variant='Bulk' color={isActive ? "#21201F" : "#b34726"} size={24} />
                            </StyledNavLink>
                        )
                    })}
                    <Divider color="#F3F3F3" sx={{ borderColor: '#F3F3F3' }} />
                    <StyledNavButton disableRipple onClick={this.logout} sx={{ mt: 2 }}>
                        <NavLinkLabel>خروج</NavLinkLabel>
                        <Logout color="#21201F" variant='Bulk' size={24} />
                    </StyledNavButton>
                </NavLinksWrapper>

                <Version>
                    v{packageJson.version}
                </Version>
            </SidebarWrapper>
        ) : (
            <React.Fragment>
                <Rtl sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    height: '48px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    mb: 2
                }}>
                    <CenterBox>
                        <IconButton onClick={this.setDrawerOpen(true)}>
                            <HambergerMenu size="32" color="#242424" />
                        </IconButton>
                        <Typography sx={{ ml: 1 }}>{this.props.user?.full_name}</Typography>
                    </CenterBox>

                </Rtl>

                <SwipeableDrawer
                    anchor={"right"}
                    open={this.state.drawerIsOpen}
                    onClose={this.setDrawerOpen(false)}
                    onOpen={this.setDrawerOpen(true)}
                >
                    <Box sx={{ minWidth: 250 }}>
                        <UserSection>

                            <Rtl sx={{ ml: 1, width: '100%' }}>
                                <Typography sx={{ mb: '2px' }}>{this.props.user?.full_name}</Typography>
                                <Typography sx={{ fontSize: 14, color: '#505050' }}>{this.props.user?.mobile}</Typography>

                                {this.props.check_in_process ? (
                                    <CenterBox sx={{ width: '100%', justifyContent: 'space-between' }}>
                                        <Skeleton width={100} height={26} sx={{ bgcolor: '#f7f7f8' }} />
                                        <IconButton size='small' disabled><Refresh color="#FF8A65" size={16} /></IconButton>
                                    </CenterBox>
                                ) : seller.debt ? (
                                    <CenterBox sx={{ width: '100%', justifyContent: 'space-between' }}>
                                        <Tooltip arrow placement='left' title="میزان بدهی">
                                            <Typography sx={{ fontSize: 14, color: '#505050' }}>{numberFormat(seller.debt?.amount)} ریالءء</Typography>
                                        </Tooltip>
                                        <IconButton size='small' onClick={this.refreshDebt}><Refresh color="#FF8A65" size={16} /></IconButton>
                                    </CenterBox>
                                ) : seller.unpaid_orders_amount ? (
                                    <CenterBox sx={{ width: '100%', justifyContent: 'space-between' }}>
                                        <Tooltip arrow placement='left' title="تسویه نشده">
                                            <Typography sx={{ fontSize: 14, color: '#505050' }}>{numberFormat(seller.unpaid_orders_amount)} ریالءء</Typography>
                                        </Tooltip>
                                        <IconButton size='small' onClick={this.refreshDebt}><Refresh color="#FF8A65" size={16} /></IconButton>
                                    </CenterBox>
                                ) : null}
                            </Rtl>
                            <Box>
                                <Avatar sx={{ backgroundColor: '#f7f7f8', height: 64, width: 64 }}>
                                    {seller.logo ? (
                                        <img style={{ height: '70%' }} src={seller.logo} alt={seller.branch.title} />
                                    ) : seller.branch.logo ? (
                                        <img style={{ height: '70%' }} src={seller.branch.logo} alt={seller.branch.title} />
                                    ) : (
                                        <UserIcon color="#242424" />
                                    )}
                                </Avatar>
                            </Box>
                        </UserSection>
                        {menus.map((item, key) => {
                            const isActive = this.checkIsActive(item)
                            return (
                                <StyledNavLink key={key} to={item.route} onClick={this.setDrawerOpen(false)} style={isActive ? { backgroundColor: '#FF8A65' } : undefined} end>
                                    <Fragment>
                                        <NavLinkLabel sx={{ color: isActive ? "#303030" : "#505050" }}>{item.label}</NavLinkLabel>
                                        <item.icon color={isActive ? "#21201F" : "#b34726"} variant="Bulk" size={24} />
                                    </Fragment>
                                </StyledNavLink>
                            )
                        })}
                        <Divider color="#F3F3F3" sx={{ borderColor: '#F3F3F3' }} />
                        <StyledNavButton onClick={this.logout} sx={{ mt: 1 }}>
                            <NavLinkLabel>خروج</NavLinkLabel>
                            <Logout variant="Bulk" color="#21201F" size={24} />
                        </StyledNavButton>

                    </Box>

                    <Version>
                        v{packageJson.version}
                    </Version>
                </SwipeableDrawer>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    setDialogTo: (payload: SetDialogPayload) => dispatch(setDialogTo(payload)),
    logout: () => dispatch(logoutAsync()),
    checkAuth: () => dispatch(checkAuthAsync())

})
const mapStateToProps = (state: AppState) => ({
    user: state.signin.user,
    seller: state.signin.seller,
    check_in_process: state.signin.check_in_process
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
