import baseAxios, { AxiosError as BaseAxiosError, AxiosResponse as BaseAxiosResponse } from "axios";
import Router from "constants/router";
const axios = baseAxios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": "fa",
    },
});

axios.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem("accessToken");
        if (token) config.headers["Authorization"] = "Bearer " + token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error: BaseAxiosError) {
        if (error.response?.status === 401) {
            Router.navigate("/auth/signin");
        } else {
            return Promise.reject(error);
        }
    }
);

type BaseAxiosResponseData<T> = {
    message: string;
    key: string;
    status_code: number;
    results: T;
};
export interface AxiosResponse<T = any> extends BaseAxiosResponse<BaseAxiosResponseData<T>> {}

type AxiosErrors = {
    field: string;
    key: string;
    message: string;
};

type BaseAxiosErrorData<T> = {
    message: string;
    key: string;
    status_code: number;
    error_type: string;
    errors?: AxiosErrors[];
    results?: T;
};

export interface AxiosError<T = any> extends BaseAxiosError<BaseAxiosErrorData<T>> {}

export default axios;
